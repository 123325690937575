import React, { Component } from "react";
import "./CreateDealDocument.css";
import { FormGenerator } from "@kehillahglobal/ui";
import ApiCallHandler from "shared/js/ApiCallHandler";
import URLS from "shared/js/urls";
import { connect } from "react-redux";
import {
  toggleToastAction,
  editMutationAction,
} from "store/actions/actions";
import { bindActionCreators } from "redux";
import { isEmpty, parseBool } from '../../../../../shared/js/utils';

class CreateDealDocument extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showToast: false,
    };
  }
  updateReduxWithResponseData = (editMode, res) => {
    let { currentDeal } = this.props;
    let documents = currentDeal?.documents;
    if (!editMode) {
      documents = isEmpty(documents) ? [res] : [res, ...documents];
    } else {
      documents = documents.filter((item) => item?.id !== res[0]?.id);
      documents?.unshift(res[0]);
    }
    currentDeal.documents = documents;
    this.props.editRecord(this.props.deals, currentDeal);
  };

  handleCreate = async (data, editData, reset) => {
    let res;

    let toSend = {
      ...data,
      deal_id: this.props.currentDeal.id,
      document: data?.document?.data?.file?.data,
      is_published: parseBool(data?.is_published) ? "True" : "False",
    };

    if (!data?.document?.data?.file) {
      delete toSend?.document;
    }

    if (!editData?.id) {
      res = await ApiCallHandler.send(
        URLS.CREATE_DEALS_DOCUMENTS,
        "POST",
        toSend
      );
    } else {
      const dataToSend = {
        ...toSend,
        id: editData?.id,
        title: data.title,
      };
      res = await ApiCallHandler.send(
        URLS.UPDATE_DEALS_DOCUMENTS,
        "POST",
        dataToSend
      );
    }

    if (res && res.success) {
      this.updateReduxWithResponseData(editData?.id, res?.data);
      this.props.toggleToast({
        show: true,
        message: !editData?.id
          ? "Deal Document created successfully"
          : "Deal Document Updated Successfully",
        type: "success",
      });
      await this.props.editRecord(this.props.deals, res.data);
      this.props.close();
      reset();
    } else {
      this.props.close();
      this.props.toggleToast({
        showToast: true,
        message: "An error occurred. Try again",
        type: "failure",
      });
    }
  };

  getFieldValue = (field) => {
    let { doc } = this.props;
    let value = !doc?.id ? "" : this.state.currentData[field];
    return value;
  };

  render() {
    let { doc: editData } = this.props;
    let Fields = FormGenerator.Fields
    let formRenderData = [
      {
        fieldType: Fields.INPUT,
        label: "Enter document title",
        name: "title",
        placeholder: "Eg. Partner Contract Agreement",
        required: true,
        value: editData?.id ? editData?.title : "",
      },
      {
        fieldType: Fields.CHECKBOX,
        label:
          "Check this box if you want to make this document visible to your partners",
        name: "is_published",
        required: false,
        removeCheckboxLabel: true,
        helpText:
          "By checking this box, you will make this document visible to your partners",
        checked: editData?.id ? editData?.is_published : false,
      },
      {
        fieldType: Fields.FILE,
        label: "Document",
        name: "document",
        placeholder: "Upload Document",
        required: false,
        value: editData?.id ? editData?.document : "",
      },
    ];

    const displayFormArray = formRenderData;

    return (
      <div>
        <div id="form-container">
          <FormGenerator
            elevation={0}
            fields={displayFormArray}
            onSubmit={(data, reset) => this.handleCreate(data, editData, reset)}
            formSubmitBtnText={!editData?.id ? "Create" : "Save Changes"}
            subtitle=""
            title=""
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownerProps) => {
  const currentDeal = state.deals.filter(x => x.id === ownerProps?.currentDeal)[0]
  return {
    deals: state.deals,
    currentDeal: currentDeal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleToast: toggleToastAction,
      editRecord: (current_data, new_data) => dispatch(editMutationAction("DEAL", current_data, new_data)),
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateDealDocument);
