import React from "react";

export default function ModalContent({content}) {
  return (
    <div
      style={{padding: "10px",
      }}
    >
      <p>{content}</p>
    </div>
  );
}
