import React, { Component } from "react";
import InvestorsWrapper from "./partners/PartnersWrapper";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { editMutationAction, toggleToastAction } from "../../../store/actions/actions";
import { bindActionCreators } from "redux";
import Processing from "../../../shared/components/processing/Processing";
import SingleDeal from "./deal-details/SingleDeal";
import BackButton from "shared/components/back-btn/BackButton";
import AllAssets from "./deal-assets/listings/AllAssets";
import DealsDocuments from "./documents/list/DealsDocuments";
import {
  DEAL_ASSETS_TAB_ID,
  DEAL_DOCUMENTS_TAB_ID,
  DEAL_OVERVIEW_TAB_ID,
  DEAL_PARTNERS_TAB_ID,
  DEAL_SETTINGS_TAB_ID,
  DRAFT
} from "./DealsTabIDs";
import DealSettings from "./settings/DealSettings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import ApiCallHandler from "shared/js/ApiCallHandler";
import URLS from "shared/js/urls";

import { TabView } from "@kehillahglobal/ui";
class DraftedDealsWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showToast: false,
      changer: localStorage.draftTabId
        ? localStorage?.draftTabId
        : DRAFT + DEAL_OVERVIEW_TAB_ID,
      isLoading: false,
      dealID: "",
      deal: null,
      loadingText: "",
    };
  }

  componentDidMount() {
    let { id } = this.props?.match?.params;
    if (id) {
      this.setState({ dealID: id });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.dealID) {
      let opp = props.deals.find((inv) => inv.id === state.dealID);
      if (opp) {
        return {
          deal: opp,
        };
      }
    }
    return null;
  }

  TAB_DATA = [
    {
      name: "Details",
      id: DRAFT + DEAL_OVERVIEW_TAB_ID,
      component: (
        <SingleDeal
          switchTabs={(changer) => {
            this.state.changer(changer);
          }}
        />
      ),
    },
    {
      name: "Assets",
      id: DRAFT + DEAL_ASSETS_TAB_ID,
      component: (
        <AllAssets
          switchTabs={(changer) => {
            this.state.changer(changer);
          }}
        />
      ),
    },

    {
      name: "Documents",
      id: DRAFT + DEAL_DOCUMENTS_TAB_ID,
      component: (
        <DealsDocuments
          switchTabs={(changer) => {
            this.state.changer(changer);
          }}
        />
      ),
    },
    {
      name: "Partners",
      id: DRAFT + DEAL_PARTNERS_TAB_ID,
      component: (
        <InvestorsWrapper
          switchTabs={(changer) => {
            this.state.changer(changer);
          }}
        />
      ),
    },
    {
      name: "Settings",
      id: DRAFT + DEAL_SETTINGS_TAB_ID,
      component: (
        <DealSettings
          switchTabs={(changer) => {
            this.state.changer(changer);
          }}
        />
      ),
    },
  ];
  handleSelectImage = () => {
    let { deal } = this.state;
    let { deals } = this.props;
    let toUpload = document.getElementById("deal-image-upload");
    toUpload.click();

    toUpload.onchange = async (e) => {
      this.setState({ isLoading: true, loadingText: "Uploading image..." });
      let res = await ApiCallHandler.send(URLS.UPDATE_DEALS, "POST", {
        image: e.target.files[0],
        id: deal?.id,
      });
      if (res.success) {
        this.props.updateDeals(deals, res?.data[0]);
      }
      this.setState({ isLoading: false, loadingText: "" });
    };
  };

  render() {
    let { deal } = this.state;
    return deal?.id ? (
      <>
        <div style={{ paddingTop: 40, marginBottom: 40 }}>
          <div style={{ width: "90%", margin: "auto" }}>
            <BackButton />
            {deal?.image ? (
              <div className="deal-name-and-stats-container">
                <div className="deal-image-container">
                  <div style={{ position: "relative", height: "max-content" }}>
                    <img src={deal?.image} alt="" />
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      id="deal-image-upload"
                    />
                    <div id="change-ent-logo" onClick={this.handleSelectImage}>
                      <FontAwesomeIcon icon={faCamera} color="white" />
                    </div>
                  </div>

                  <div className="change-deal-image-container">
                    <h1>
                      <p>{deal?.name}</p>
                    </h1>
                  </div>
                </div>
              </div>
            ) : (
              <div className="deal-image-container">
                <h1>
                  <p>{deal?.name}</p>
                </h1>
              </div>
            )}
            <TabView
              data={this.TAB_DATA}
              contentAreaStyle={{ paddingTop: 30 }}
              defaultTab={this.state.changer}
              onMount={(changer) => this.setState({ changer })}
            />
          </div>

          {this.state.isLoading && <Processing text={this.state.loadingText} />}
        </div>
      </>
    ) : (
      <Processing text={"Loading data....."} />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    deals: state.deals,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      showToast: toggleToastAction,
      updateDeals: (current_data, new_data) =>
        dispatch(editMutationAction("DEAL", current_data, new_data)),
    },
    dispatch
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DraftedDealsWrapper)
);
