import React from "react";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";

export default function OverviewChart(props) {
  const stats = useSelector((state) => state.dealStats);

  const data = {
    labels: [
      "Setup Cost",
      "Revenue",
      "Expenses",
      "Distributed",
    ],
    datasets: [
      {
        label: "Overview",
        backgroundColor: ["#2471A3", "#1E8449", "#A93226", "#B9770E"],
        data: [
          props?.deal?.setup_cost,
          stats?.total_revenue,
          stats?.total_expenses,
          stats?.total_distributions,
        ],
        barPercentage: 0.5,
      },
    ],
  };
  const options = {
    indexAxis: "y",
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Overview Chart",
      },
    },
  };
  return (
    <div>
      <Bar data={data} options={options} />
    </div>
  );
}
