import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toggleModalAction } from "store/actions/actions";
import {TabView} from "@kehillahglobal/ui";
import EnterpriseUsers from "./users/EnterpriseUsers";
import EnterpriseUsersInvitationsCreateAndList from "./users/EnterpriseUsersInvitationsCreateAndList";

class EnterpriseUsersWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = { changer: "enterprise-users" };
  }

  TABS = [
    {
      name: "Users",
      id: "enterprise-users",
      component: (
        <EnterpriseUsers
          edit={true}
          switchTabs={(changer) => this.state.changer(changer)}
        />
      ),
    },
    {
      name: "Invitations",
      id: "user-invitations",
      component: (
        <EnterpriseUsersInvitationsCreateAndList
          switchTabs={(changer) => this.state.changer(changer)}
        />
      ),
    },
  ];
  render() {
    return (
      <div style={{ width: "90%", margin: "auto", marginTop: "2rem" }}>
        <TabView
          data={this.TABS}
          contentAreaStyle={{ paddingTop: 30 }}
          onMount={(changer) => this.setState({ changer })}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    modalProps: state.modalProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleModal: toggleModalAction,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnterpriseUsersWrapper);
