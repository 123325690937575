import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  toggleSidePane,
  setAssetCreateOrEditTypeAction,
  setEditSetterAction,
  toggleModalAction,
  editMutationAction,
  toggleToastAction,
} from "store/actions/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Title from "components/title/Title";
import ApiCallHandler from "shared/js/ApiCallHandler";
import URLS from "shared/js/urls";
import { FormGenerator, NoItems, Table, } from "@kehillahglobal/ui";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router";
import { getDealStatsAction } from "../../../../store/actions/actions";
import { convertNumberToShortForm, getActiveEnterprise } from "shared/js/utils";
import { getCountryInfo, isEmpty } from "../../../../shared/js/utils";
import { PARTNER_TYPES, DISTRIBUTION_STATUSES } from "../../../../shared/js/typeConstants";
import Processing from "../../../../shared/components/processing/Processing";
import ShowActions from "shared/components/showActions/ShowActions";
import Note from "shared/components/note/Note";
import { Descriptions } from "documentation/Descriptions";
import { dateValidator, numberValidator } from "shared/js/fieldValidators";
import ModalContent from "shared/components/modal-content/ModalContent";

class DealsPayout extends Component {
  constructor(props) {
    super(props);
    this.onIncomeSubmit = this.onIncomeSubmit.bind(this);
    this.state = {
      loading: false,
      id: "",
      showActions: false,
      deal:{}
    };
  }
  componentDidMount() {
    let { id } = this.props.match.params;
    if (id) {
      let deal = this.props.deals.find((deal) => deal.id === id);
      this.setState({ deal });
    }
  }

  static getDerivedStateFromProps(props) {
    let { id } = props.match.params;
    if (id) {
      let deal = props.deals.find((deal) => deal.id === id);
      return {
        deal,
      };
    }
    return;
  }
  toggleShowMoreOptions = (item) => {
    if (!item?.id) {
      this.setState({
        showActions: false,
        id: "",
      });
    } else {
      this.setState({
        showActions: true,
        id: item?.id,
      });
    }
  };

  getConfigSummary() {
    let { deal } = this.state;

    const expenseArray = deal?.expense_config;
    const incomeArray = deal?.income_config;

    if (
      expenseArray == null ||
      incomeArray == null ||
      expenseArray.length < 1 ||
      incomeArray.length < 1
    ) {
      return 0;
    }

    const frequencyValues = {
      DAYS: 1 / 7,
      WEEKS: 1,
      MONTHS: 4,
      YEARS: 52.6,
    };
    let expensesSum = 0;
    expenseArray.forEach((val) => {
      const amountInWeeks =
        val.amount / (frequencyValues[val.frequency_type] * val.frequency);
      expensesSum += amountInWeeks;
    });
    expensesSum = Math.round(expensesSum);

    let incomeSum = 0;
    incomeArray.forEach((val) => {
      const amountInWeeks =
        val.amount / (frequencyValues[val.frequency_type] * val.frequency);
      incomeSum += amountInWeeks;
    });

    if (expensesSum === 0 || incomeSum === 0) {
      return 0;
    }

    return expensesSum * deal?.income?.length;
  }

  currentBalance = () => {
    let { oppStats } = this.props;
    let available = oppStats?.total_income - oppStats?.total_distributions;
    return available;
  };

  async createIncome(data) {
    const json = await ApiCallHandler.send(
      URLS.CREATE_PAYOUT,
      ApiCallHandler.POST,
      data
    );
    return json;
  }

  async handleCreateResponse(json, reset) {
    if (json?.success) {
      await this.props.editRecord(this.props.deals, json.data);
      let stats = await this.props.getOppStats(this.state?.deal?.id);
      this.props.updateStats(stats?.payload);
      this.props.setEditPayload({});
      this.setState({ loading: false });
      this.props.toggleToast({
        show: true,
        message: "Created Successfully",
        type: "success",
      });
      this.props.toggleModal({ show: false });
      return reset();
    }

    if (json?.success === false) {
      this.setState({ loading: false });
      return this.props.toggleToast({
        show: true,
        message: json?.error,
        type: "failure",
      });
    }

    this.props.toggleToast({
      show: true,
      message: "Sorry something happened, couldn't create!",
      type: "failure",
    });
  }

  async onIncomeSubmit(data, reset) {
    this.setState({ loading: true });
    if (!data) return;
    const currentBalance = this.currentBalance();
    data = {
      sender_id: this.state?.deal?.enterprise.id,
      deal_id: this.state?.deal?.id,
      amount: parseInt(data.amount),
      confirmed: "False",
      ...data,
    };
    if (data.amount > currentBalance) {
      alert(`You Cant Payout More Than ${currentBalance}`);
      reset();
      this.props.toggleModal({ show: false });
    } else {
      this.props.toggleModal({ show: false });
      let json = {};
      json = await this.createIncome(data);
      this.handleCreateResponse(json, reset);
    }
  }

  showCreateForm = () => {
    let fieldsArray = [
      {
        fieldType: FormGenerator.Fields.INPUT,
        label: "Amount",
        name: "amount",
        placeholder: "Amount Here...",
        value: this.currentBalance() > 0 ? this.currentBalance() : "0",
        disabled: true,
        required: true,
        validator: (value) => numberValidator(value),
      },
      {
        fieldType: FormGenerator.Fields.DATE,
        label: "When should this payout be made? ",
        name: "next_payout_date",
        required: true,
        validator: (value) => dateValidator(value),
      },
      {
        fieldType: FormGenerator.Fields.TEXTAREA,
        label: "Notes",
        name: "notes",
        placeholder: "Short Notes Here...",
      },
    ];
    let { currentEnterprise } = this.props;
    let { currency } = getCountryInfo(currentEnterprise?.currency);

    return (
      <div>
        <div id="max-payout-container">
          <p>Max Payout </p>
          <span style={{ textAlign: "center" }}>
            {currency?.code}{" "}
            {convertNumberToShortForm(
              this.currentBalance() > 0 ? this.currentBalance() : 0
            )}
          </span>
        </div>
        <FormGenerator
          onSubmit={this.onIncomeSubmit}
          elevation={0}
          subtitle=""
          title=""
          formSubmitBtnText="Create"
          fields={fieldsArray}
        />
        <br />
        <br />
      </div>
    );
  };

  createBulkDistributions = async (payout) => {
    let distributions = [];
    const { partnerships, id, shares } = this.state?.deal;
    if (isEmpty(partnerships)) {
      return this.props.toggleToast({
        show: true,
        message: "Please You can't trigger a distribution without any partners",
        type: "failure",
      });
    }
    this.setState({ loading: true });

    const amount = parseFloat(payout.amount) / parseFloat(shares);

    new Promise((resolve, reject) => {
      partnerships?.forEach(async (partner, index) => {
        const full_amount = amount * parseFloat(partner.shares);
        const transaction_charges = 0;
        const amount_to_receive = full_amount - transaction_charges;

        const prepareData = {
          payout_id: payout.id,
          deal_id: id,
          partner_id: partner.id,
          user_recipient_id:
            partner?.type === PARTNER_TYPES.ENTERPRISE
              ? null
              : partner?.user?.id,
          payment_status: DISTRIBUTION_STATUSES.CREATED,
          full_amount: full_amount,
          transaction_charges: transaction_charges,
          amount_to_receive: amount_to_receive,
        };
        try {
          const json = await ApiCallHandler.send(
            URLS.CREATE_NEW_DEAL_DISTRIBUTION,
            ApiCallHandler.POST,
            prepareData
          );
          if (json.success) {
            distributions.push(json.data);
          }
        } catch (error) {}
        resolve();
      });
    }).then(async () => {
      let { deal } = this.state;
      payout.distributions = distributions;

      let filtered = deal.payouts.filter((x) => x?.id !== payout?.id);
      filtered.push(payout);

      deal.payouts = filtered;
      this.props.editRecord(this.props.deals, deal);
      this.setState({ loading: false });
    });
  };

  viewAllDistributionsUnderASinglePayout = (item) => {
    let deal = this.state?.deal?.id;
    this.props.history.push(
      `/enterprise/${getActiveEnterprise()?.id}/deals/${
        deal
      }/payout-distributions/${item.id}`
    );
  };

  toggleDeletePayoutModal = (payout) => {
    if (payout.distributions.length > 0) {
      return this.props.toggleToast({
        show: true,
        message: "You can't delete a payout that has distributions",
        type: "failure",
      });
    }

    this.props.toggleModal({
      show: true,
      props: {
        title: `Delete Payout`,
        children: (
          <ModalContent content={"Would you like to delete this record ?"} />
        ),
        themeColor: "maroon",
        cancel: true,
        okay: {
          text: "Yes",
          function: () => {
            this.handleDeletePayout(payout);
          },
        },
      },
    });
  };

  handleDeletePayout = (payout) => {
    ApiCallHandler.send(URLS.ARCHIVE_PAYOUT, "POST", { id: payout.id }).then(
      (res) => {
        if (res?.success) {
          let { deal } = this.state;
          let newPayouts = deal?.payouts.filter(
            (x) => x?.id !== payout?.id
          );
          deal.payouts = newPayouts;
          this.props.editRecord(this.props.deals, deal);
          this.props.toggleModal({ show: false });
          this.props.toggleToast({
            show: true,
            message: "Payout deleted successfully",
            type: "success",
          });
        }
      }
    );
  };

  renderDistributions = (item) => {
    if (item.distributions && item.distributions.length > 0) {
      return (
        <ShowActions
          onDelete={this.toggleDeletePayoutModal}
          onView={this.viewAllDistributionsUnderASinglePayout}
          item={item}
          id={this.state.id}
          toggleShowMoreOptions={this.toggleShowMoreOptions}
          show={this.state.showActions}
        />
      );
    } else {
      return (
        <div
          id="view-distribution-btn-container"
          className="elevate-float"
          onClick={() => this.createBulkDistributions(item)}
          style={{ cursor: "pointer", width: "max-content" }}
        >
          <p>Generate Payout List</p>
        </div>
      );
    }
  };

  prepareTableData = (currentData) => {
    let { currentEnterprise } = this.props;
    let { currency } = getCountryInfo(currentEnterprise?.currency);
    let tableData = [];
    currentData.forEach((item, index) => {
      tableData.push([
        <p>{index + 1}</p>,
        <p>{item?.next_payout_date}</p>,
        <p>
          {currency?.code} {item?.amount}
        </p>,
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {this.renderDistributions(item)}
        </div>,
      ]);
    });
    return tableData;
  };

  showAllIncomeConfig = () => {
    let { deal } = this.state;
    deal = deal?.payouts;
    return (
      <div>
        <div style={{ borderRadius: 10 }}>
          <br />
          {deal && deal.length ? (
            <div>
              <Table
                columns={["#", "Payout Date", "Amount", "distribution Actions"]}
                data={this.prepareTableData(deal)}
                hoverAnimation={false}
              />
            </div>
          ) : (
            <NoItems text="Oops! 🙊 its looks like you do not have payouts yet. Click on 'Add New Payout' to create." />
          )}
        </div>
      </div>
    );
  };

  render() {
    const currentBalance = this.currentBalance();
    return (
      <div>
        <Note note={Descriptions.PAYOUT} />
        <br />
        {!isEmpty(currentBalance) || currentBalance !== 0 ? (
          <Title
            className="text-toggler"
            onClick={() =>
              this.props.toggleModal({
                show: true,
                props: {
                  title: `CREATE PAYOUT`,
                  children: this.showCreateForm(),
                  themeColor: "var(--app-theme)",
                },
              })
            }
          >
            Add New Payout <FontAwesomeIcon icon={faPlus} />
          </Title>
        ) : null}
        <br />
        {this.showAllIncomeConfig()}
        <br />
        <br />
        <br />
        {this.state.loading ? <Processing text={"Creating payouts"} /> : null}
      </div>
    );
  }
}

const mapStateToProps = (state, ownerProps) => {
  let currentEnterprise = state.enterprises?.find(
    (item) => item?.id === getActiveEnterprise()?.id
  );
  return {
    deals: state.deals,
    oppStats: state.dealStats,
    currentEnterprise,
    editStatus:
      state.editStatusPayload && state.editStatusPayload.opportunityEditStatus
        ? state.editStatusPayload.opportunityEditStatus
        : false,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleSidePane,
      setAssetFormStatus: (val) =>
        dispatch(setAssetCreateOrEditTypeAction(val)),
      setEditPayload: (val) => dispatch(setEditSetterAction(val)),
      toggleModal: toggleModalAction,
      editRecord: (current_data, new_data) =>
        dispatch(editMutationAction("DEAL", current_data, new_data)),
      toggleToast: toggleToastAction,
      getOppStats: getDealStatsAction
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DealsPayout)
);
