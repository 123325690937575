import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Title from 'components/title/Title';
import { Descriptions } from 'documentation/Descriptions';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Note from 'shared/components/note/Note';
import ModalContent from 'shared/components/modal-content/ModalContent';
import ShowActions from 'shared/components/showActions/ShowActions';
import ApiCallHandler from 'shared/js/ApiCallHandler';
import URLS from 'shared/js/urls';
import { getFileType, getMeaningfulDate } from 'shared/js/utils';
import { editMutationAction, toggleModalAction, toggleToastAction } from 'store/actions/actions';
import {Table, NoItems} from '@kehillahglobal/ui'
import AssetDocumentCreation from './AssetDocumentCreation';


class ListDealAssetDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
      showActions: false,
      id: "",
      uploadingImg: false,
    };
  }

  toggleShowMoreOptions = (item) => {
    if (!item?.id) {
      this.setState({
        showActions: false,
        id: "",
      });
    } else {
      this.setState({
        showActions: true,
        id: item?.id,
      });
    }
  };

  handleAddDocument = (item = null) => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `${item?.id ? "Update" : "Add New "} document`,
        children: (
          <AssetDocumentCreation
            close={this.close}
            doc={item}
            assetID={this.props?.match?.params?.id}
            dealID={this.props?.match?.params?.dealID}
          />
        ),
        themeColor: "var(--app-theme)",
        cancel: false,
        size: "large",
      },
    });
  };

  close = () => {
    this.props.toggleModal({
      show: false,
    });
  };


  deleteItemFromRedux = (id) => {
    let { deals } = this.props;
    let { currentDeal, currentAsset } = this.props;
    let updatedDocs = currentAsset?.documents?.filter(
      (item) => item?.id !== id
    );
    currentAsset.documents = updatedDocs;
    let updatedAssets = currentDeal?.assets?.filter(
      (item) => item?.id !== currentAsset?.id
    );
    updatedAssets.push(currentAsset);
    currentDeal.assets = updatedAssets;
    this.props.updateItemInredux(deals, currentDeal);
  };
  handleDeleteDocument = async (item) => {
    let res = await ApiCallHandler.send(
      URLS.ARCHIVE_DEAL_ASSET_DOCUMENT,
      "POST",
      {
        id: item?.id,
      }
    );

    if (res && res.success) {
      this.deleteItemFromRedux(item?.id);
      this.props.toggleToast({
        show: true,
        message: "Deal Asset Document deleted successfully",
        type: "success",
      });
    } else {
      this.props.toggleToast({
        showToast: true,
        message: "An error occurred. Try again",
        type: "failure",
      });
    }
  };
  handleToggleDeleteModal = (item) => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `Delete "${item?.name}"`,
        children: (
          <ModalContent content={"Would you like to delete this record ?"} />
        ),
        themeColor: "maroon",
        cancel: true,
        okay: {
          text: "Yes",
          function: () => {
            this.handleDeleteDocument(item);
          },
        },
      },
    });
  };

  handleOpenDocOrImage = ({ document: doc }) => {
    window.open(doc, "_blank");
  };

  prepareTableData = (doc) => {
    let tableData = (doc || []).map((item) => {
      return [
        <p>{getMeaningfulDate(item?.date)}</p>,
        <p onClick={() => this.handleOpenDocOrImage(item)} id="doc-name-txt">
          {item?.name}
        </p>,
        <p id="doc-type-container">
          {getFileType(item?.document).toUpperCase()}
        </p>,
        <p>{item?.visibility ? "Public" : "Private"}</p>,
        <div>
          <ShowActions
            onEdit={this.handleAddDocument}
            onDelete={this.handleToggleDeleteModal}
            onView={this.handleOpenDocOrImage}
            item={item}
            id={this.state.id}
            toggleShowMoreOptions={this.toggleShowMoreOptions}
            show={this.state.showActions}
          />
        </div>,
      ];
    });
    return tableData;
  };
  render() {
    let { currentAsset: asset } = this.props;
    return (
      <div id="asset-doc-container">
        <Note note={Descriptions.ASSET_DOCUMENT} />
        <div style={{ marginBottom: "1.5rem" }}>
          <Title
            className="text-toggler"
            onClick={() => this.handleAddDocument()}
          >
            <FontAwesomeIcon icon={faPlus} /> Add Document
          </Title>
        </div>
        {asset?.documents?.length > 0 ? (
          <div>
            <Table
              columns={["Created At", "Name", "Type", "Status", "Actions"]}
              data={this.prepareTableData(asset?.documents)}
              hoverAnimation={false}
            />
          </div>
        ) : (
          <NoItems text="This asset has no documents yet!" />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    deals: state.deals,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleToast: toggleToastAction,
      toggleModal: toggleModalAction,
      updateItemInredux: (current_data, new_data) =>
        dispatch(editMutationAction("DEAL", current_data, new_data)),
    },
    dispatch
  );
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListDealAssetDocuments))