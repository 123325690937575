import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { connect } from "react-redux";
import ApiCallHandler from "../../../../../../shared/js/ApiCallHandler";
import URLS from "../../../../../../shared/js/urls";
import {
  toggleToastAction,
  toggleModalAction,
  editMutationAction,
} from "../../../../../../store/actions/actions";
import { bindActionCreators } from "redux";
import Title from "../../../../../../components/title/Title";
import "../DealAssetDetails.css";
import Processing from "shared/components/processing/Processing";
import { withRouter } from "react-router-dom";
class DealAssetDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
      deal: {},
      asset: {},
    };
  }

  static getDerivedStateFromProps(props, state) {
    let { dealID, id } = props?.match?.params;
    let deal = {};
    let asset = {};
    if (dealID) {
      deal = props.deals.find((deal) => deal.id === dealID);
      if (deal) {
        asset = deal.assets.find((asset) => asset.id === id);
      }
      return {
        deal,
        asset,
      };
    }
    return null;
  }

  updateAssetInRedux = (asset) => {
    let { deals } = this.props;
    let { deal } = this.state;
    let filteredAssets = deal?.assets?.filter((item) => item?.id !== asset?.id);
    filteredAssets.push(asset);
    deal.assets = filteredAssets;
    this.props.updateItemInredux(deals, deal);
    this.setState({ uploading: false });
  };

  handleSelectImage = () => {
    let fileInput = document.getElementById("asset-img-select");
    fileInput.click();
    const { asset } = this.state;
    fileInput.onchange = async (e) => {
      this.setState({ uploading: true });

      let res = await ApiCallHandler.send(
        URLS.UPDATE_DEAL_ASSET_IMAGE,
        "POST",
        {
          image: e.target.files[0],
          id: asset?.id,
        }
      );


      if (res?.success) {
        this.updateAssetInRedux(res.data);
      } else {
        this.setState({ uploading: false });
        this.props.toggleToast({
          show: true,
          message: "An error occurred. Try again",
          type: "failure",
        });
      }
    };
  };

  close = () => {
    this.props.toggleModal({
      show: false,
    });
  };

  render() {
    const { asset } = this.state;
    return (
      <div id="asset-detail-wrapper">
        <div id="img-and-title-container">
          <div style={{ position: "relative" }}>
            <img src={asset?.image} alt="logo" id="asset-img" />
            <input
              type="file"
              style={{ display: "none" }}
              id="asset-img-select"
            />
            <div id="change-asset-image" onClick={this.handleSelectImage}>
              <FontAwesomeIcon icon={faCamera} color="white" />
            </div>
          </div>

          <div id="asset-info-txts-container">
            <div>
              <h2>
                {asset?.name}
                {/* <span>({asset?.asset_type?.name})</span> */}
              </h2>
              <p className="asset-id-type">
                {asset?.identification_number_name}
              </p>
              <p className="asset-id-number">{asset?.identification_number}</p>
            </div>
          </div>
        </div>
        {/* ============== About section ================================ */}

        <div id="asset-detail-container">
          <Title>About Asset</Title>
          <p>{asset?.description || "Something about your asset.."}</p>
        </div>
        {/* ============== Manager section ================================ */}
        {this.state.uploading && <Processing text={"Uploading..."} />}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    deals: state.deals,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleToast: toggleToastAction,
      toggleModal: toggleModalAction,
      updateItemInredux: (current_data, new_data) =>
        dispatch(editMutationAction("DEAL", current_data, new_data)),
    },
    dispatch
  );
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DealAssetDetail)
);
