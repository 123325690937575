import React, { Component } from "react";
import  {FormGenerator} from '@kehillahglobal/ui'
import ApiCallHandler from "shared/js/ApiCallHandler";
import URLS from "shared/js/urls";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { editMutationAction, toggleToastAction } from "store/actions/actions";
import { parseBool } from "shared/js/utils";


class AssetDocumentCreation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  updateReduxState = (isEdit, res) => {
    let { currentDeal, deals, updateRedux, currentAsset:asset } = this.props;
    let updatedAssets;
    if (!isEdit) {
      let docs = asset?.documents?.length
        ? [...asset?.documents, res?.data]
        : [res.data];
      asset.documents = docs;
      updatedAssets = currentDeal?.assets?.filter(
        (item) => item?.id !== asset?.id
      );
    } else {
      let updatedDocs = asset.documents?.filter(
        (item) => item?.id !== res.data[0]?.id
      );
      updatedDocs.push(res.data[0]);
      asset.documents = updatedDocs;
      updatedAssets = currentDeal?.assets?.filter(
        (item) => item?.id !== asset?.id
      );
    }
    updatedAssets.push(asset);
    currentDeal.assets = updatedAssets;
    updateRedux(deals, currentDeal);
  };

  handleAddDocument = async (data, reset) => {
    let { assetID, doc } = this.props;
    let res;
    const { document: file, name, visibility } = data;
    const toSend = {
      deal_asset_id: assetID,
      name: name,
      document: file?.data?.file?.data,
      visibility: parseBool(visibility)? "True" : "False",
    };

    if (!file?.data?.file?.data) {
      delete toSend?.document;
    }

    if (doc?.id) {
      toSend.id = doc.id;
      res = await ApiCallHandler.send(
        URLS.UPDATE_DEAL_ASSET_DOCUMENT,
        "POST",
        toSend
      );
    } else {
      res = await ApiCallHandler.send(
        URLS.CREATE_DEAL_ASSET_DOCUMENT,
        "POST",
        toSend
      );
    }
    if (res && res.success) {
      this.updateReduxState(doc?.id, res);
      this.props.toggleToast({
        show: true,
        message: "Deal asset document operation successful",
        type: "success",
      });
      this.props.close();
      reset();
    } else {
      this.props.toggleToast({
        show: true,
        message: "An error occurred. Try again",
        type: "failure",
      });
    }
  };

  render() {
    let { doc } = this.props;
    let Fields = FormGenerator.Fields
    const formObjects = [
      {
        fieldType: Fields.INPUT,
        label: "Enter document name",
        name: "name",
        placeholder: "Document name  (eg. license, receipt)",
        required: true,
        value: doc?.id ? doc?.name : "",
      },
      {
        fieldType: Fields.CHECKBOX,
        label:
          "Check this box if you want to make this document visible to your partners",
        name: "visibility",
        required: false,
        removeCheckboxLabel: true,
        helpText:
          "By checking this box, you will make this document visible to your partners",
        checked: doc?.id ? doc?.visibility : false,
      },
      {
        fieldType: Fields.FILE,
        label: "Upload Document",
        placeholder: "Select a file",
        name: "document",
        required: true,
        value: doc?.id ? doc?.document : "",
      },
    ];
    return (
      <div>
        <div>
          <FormGenerator
            elevation={0}
            fields={formObjects}
            onSubmit={this.handleAddDocument}
            formSubmitBtnText="Add document"
            subtitle=""
            title=""
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownerProps) => {
  const currentDeal = state.deals.filter(
    (x) => x.id === ownerProps?.dealID
  )[0];
  return {
    deals: state.deals,
    currentDeal,
    currentAsset: currentDeal?.assets?.filter((x) => x?.id === ownerProps?.assetID)[0],
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleToast: toggleToastAction,
      updateRedux: (current_data, new_data) =>
        dispatch(editMutationAction("DEAL", current_data, new_data)),
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetDocumentCreation);
