import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { FormGenerator } from "@kehillahglobal/ui";
import { BUSINESS_TYPE, COMPANY_SIZE_RANGES, INDUSTRIES } from "shared/js/typeConstants";
import ApiCallHandler from '../../../shared/js/ApiCallHandler';
import URLS from '../../../shared/js/urls';
import { editMutationAction } from '../../../store/actions/actions';
import { getActiveEnterprise } from "shared/js/utils";

class EnterpriseInfo extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }
  componentDidMount() {
    let { onMount } = this.props;
    if (onMount) {
      onMount(this.saveDataToDB);
    }
  }



  saveDataToDB = async() => {
    let {enterprise, enterprises} = this.props;
    const data = this.formRef.current?.onClickFromOutside();
    let res;
    if(data){
      data.id =enterprise?.id
      res = await ApiCallHandler.send(URLS.UPDATE_ENTERPRISES, "POST", data)
      if(res && res?.success){
        this.props.editRecord(enterprises, res?.data[0])
      }
    }



  };

  render() {
  
  let { enterprise } = this.props;
     const formData = [
       {
         fieldType: FormGenerator.Fields.INPUT,
         label: "Enterprise Name",
         name: "name",
         placeholder: "Enterprise name...",
         value: enterprise?.id ? enterprise?.name : "",
       },
       {
         fieldType: FormGenerator.Fields.DROPDOWN,
         label: "Staff Size",
         name: "staff_size",
         type: "full",
         data: COMPANY_SIZE_RANGES,
         placeholder: "Select Staff Size",
         value: enterprise?.id ? enterprise?.staff_size : "",
       },
       {
         fieldType: FormGenerator.Fields.DROPDOWN,
         label: "Industry",
         name: "industry",
         type: "full",
         data: INDUSTRIES,
         placeholder: "Select Industry",
         value: enterprise?.id ? enterprise?.industry : "",
       },
       {
         fieldType: FormGenerator.Fields.DROPDOWN,
         label: "Business Type",
         name: "business_type",
         type: "full",
         data: BUSINESS_TYPE,
         placeholder: "Select Business Type",
         value: enterprise?.id ? enterprise?.business_type : "",
       },
       
     ];
    return (
      <div>
        <FormGenerator
          elevation={0}
          subtitle=""
          title=""
          formSubmitBtnText={"Save Changes"}
          fields={formData}
          hideSubmitBtn={true}
          ref={this.formRef}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
      const enterpriseLoc = getActiveEnterprise();
  return {
    enterprise:state.enterprises?.filter(
      (item) => item.id === enterpriseLoc.id
    )[0],
    enterprises:state.enterprises,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      editRecord: (current_data, new_data) =>
        dispatch(
          editMutationAction("ENTERPRISE_MUTATION", current_data, new_data)
        ),
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EnterpriseInfo);
