import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  setAssetCreateOrEditTypeAction,
  toggleModalAction,
} from "store/actions/actions";
import Title from "components/title/Title";
import {Table, NoItems} from "@kehillahglobal/ui";
import ApiCallHandler from "shared/js/ApiCallHandler";
import URLS from "shared/js/urls";
import { withRouter } from "react-router-dom";
import ShowActions from "../../../../../shared/components/showActions/ShowActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import CreateAsset from "../create/CreateAsset";
import { editMutationAction } from "../../../../../store/actions/actions";
import { getActiveEnterprise } from "shared/js/utils";
import Note from "shared/components/note/Note";
import {Descriptions} from '../../../../../documentation/Descriptions';
import ModalContent from "shared/components/modal-content/ModalContent";

class AllAssets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      showActions: false,
      dealID: "",
      currentDeal: {},
    };
  }

  handleShowDetail = (item) => {
    this.props.history.push(`/enterprise/${getActiveEnterprise()?.id}/deals/${this.state.dealID}/asset/` + item.id);
  };

  componentDidMount() {
    let id = this.props?.match?.params?.id;
    if (id) {
      let currentDeal = this.props.deals.find((deal) => deal?.id === id);
      this.setState({ dealID: id, currentDeal });
    }
  }

  static getDerivedStateFromProps(props, state) {
    let id = props?.match?.params?.id;
    if (id) {
      let currentDeal = props.deals.find((deal) => deal?.id === id);
      if (currentDeal) {
        return {
          dealID: id,
          currentDeal,
        };
      }
    }
  }

  toggleShowMoreOptions = (item) => {
    if (!item?.id) {
      this.setState({
        showActions: false,
        id: "",
      });
    } else {
      this.setState({
        showActions: true,
        id: item?.id,
      });
    }
  };

  prepareTableData = (assets) => {

    let tableData = (assets || []).map((item) => {
      return [
        <p onClick={() => this.handleShowDetail(item)}>
          {item?.name || "Not Set"}
        </p>,
        <p onClick={() => this.handleShowDetail(item)}>
          {item?.cost || "Not Set"}
        </p>,
        <p onClick={() => this.handleShowDetail(item)}>
          {item?.asset_type?.name || "Not Set"}
        </p>,
        <p onClick={() => this.handleShowDetail(item)}>
          {item?.identification_number || "Not Set"}
        </p>,
        <p>{item?.identification_number_name || "Not Set"}</p>,
        <p></p>,
        <ShowActions
          onEdit={this.handleToggleAddOrEditModal}
          onDelete={this.handleDeleteAsset}
          onView={this.handleShowDetail}
          item={item}
          id={this.state.id}
          toggleShowMoreOptions={this.toggleShowMoreOptions}
          show={this.state.showActions}
        />,
      ];
    });
    return tableData;
  };
  handleToggleAddOrEditModal = (item = {}) => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `${item?.id ? "Update" : "Create"} Deal Asset`,
        children: (
          <CreateAsset
            item={item}
            close={this.closeModal}
            dealID={this.state.dealID}
          />
        ),
        themeColor: "var(--app-theme)",
        size: "large",
      },
    });
  };
  closeModal = () => {
    this.props.toggleModal({ show: false });
  };

  showAllAssets = () => {
    let {
      currentDeal: { assets },
    } = this.state;


    return (
      <div>
        <Note note={Descriptions.ASSET}/>
        <div>
          <Title
            className="text-toggler"
            onClick={this.handleToggleAddOrEditModal}
          >
            <FontAwesomeIcon icon={faPlus} /> Add New Asset
          </Title>
        </div>
        <div style={{ marginTop: 10 }}>
          {assets && assets.length ? (
            <Table
              columns={[
                "Name",
                "Cost",
                " Asset Type",
                "ID Number",
                "ID NAME",
                "\t",
                "actions",
              ]}
              data={this.prepareTableData(assets)}
              hoverAnimation={false}
            />
          ) : (
            <NoItems text="No Assets yet" />
          )}
        </div>
      </div>
    );
  };

  deleteItemfromRedux = (id) => {
    let { deals } = this.props;
    let { currentDeal } = this.state;
    let filteredAsset = currentDeal?.assets?.filter((item) => item.id !== id);
    currentDeal.assets = filteredAsset;
    this.props.updateItemInredux(deals, currentDeal);
  };

  handleDeleteAsset = (item) => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `Delete "${item?.name}"`,
        children: (
          <ModalContent content={"Would you like to delete this record ?"} />
        ),
        themeColor: "maroon",
        cancel: true,
        okay: {
          text: "Yes",
          function: () => {
            ApiCallHandler.send(URLS.ARCHIVE_ASSETS, "POST", {
              id: item?.id,
            }).then(() => {
              this.deleteItemfromRedux(item?.id);
            });
          },
        },
      },
    });
  };

  render() {
    return <div>{this.showAllAssets()}</div>;
  }
}

const mapStateToProps = (state, ownerProps) => {
  return {
    deals: state.deals,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setAssetFormStatus: (val) =>
        dispatch(setAssetCreateOrEditTypeAction(val)),
      toggleModal: toggleModalAction,
      updateItemInredux: (current_data, new_data) =>
        dispatch(editMutationAction("DEAL", current_data, new_data)),
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AllAssets)
);
