import React, {Component} from "react";
import CreateAsset from "./CreateAsset";
import AllAssets from "../listings/AllAssets";
import CreateAssetType from "../asset type/CreateAssetType";
import CreateAssetIncomeType from "../income type/CreateAssetIncomeType";
import CreateAssetExpenseType from "../expense type/CreateAssetExpenseType";
import { TabView } from "@kehillahglobal/ui";


export default class CreateAndListWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = { showToast: false, changer: null, mode: "create"};
  }

  TAB_DATA = [
    {
      name: "Assets",
      id: "list-all-assets",
      component: <AllAssets switchTabs={(changer) =>{this.state.changer(changer)}} />,
    },
    {
      name: "Create Asset",
      id: "create-and-edit-asset",
      component: <CreateAsset switchTabs={(changer) =>{this.state.changer(changer)}}  />,
    },
    {
      name: "Asset Types",
      id: "create-asset-type",
      component: <CreateAssetType />,
    },
    {
      name: "Asset Income Typse",
      id: "create-income-type",
      component: <CreateAssetIncomeType switchTabs={(changer) =>{this.state.changer(changer)}} />,
    },
    {
      name: "Asset Expense Types",
      id: "create-expense-type",
      component: <CreateAssetExpenseType />,
    }
  ];

  render() {

    return (
      <div id="asset-setup-and-config-container" style={{ paddingTop: 40 }}>
        <div style={{ width: "90%", margin: "auto" }}>
          <TabView
            data={this.TAB_DATA}
            contentAreaStyle={{ paddingTop: 30 }}
            defaultTab={this.state.changer}
            onMount={(changer) => this.setState({ changer })}
          />
        </div>
      </div>
    );

  }
}
