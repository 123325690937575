import { combineReducers } from "redux";
import {
  toggleSidePaneReducer,
  toggleToastReducer,
  toggleModalReducer,
  toggleLoadingBlanketReducer,
  setCurrentEnterpriseReducer,
  loadDealsReducer,
  setEditSetterReducer,
  setCurrentEnterpriseDashboardDetailsReducer,
  toggleImagePreviewReducer,
  getEnterpriseUserInvitationsReducer,
  getEnterprisesReducer,
  getAllCountryEnterpriseRequirementsReducer,
  getAllPartnersReducer,
  getExchangeRatesReducer,
  loadFormDataReducer,
  getDealStatsReducer,
  getAllTagsReducer,
  getLoginUserReducer
} from "./all";

const motherReducer = combineReducers({
  sidePane: toggleSidePaneReducer,
  toastProps: toggleToastReducer,
  modalProps: toggleModalReducer,
  blanketProps: toggleLoadingBlanketReducer,
  currentEnterprise: setCurrentEnterpriseReducer,
  deals: loadDealsReducer,
  editStatusPayload: setEditSetterReducer,
  enterpriseDashboardContents: setCurrentEnterpriseDashboardDetailsReducer,
  imagePreviewProps: toggleImagePreviewReducer,
  enterpriseUserInvitations: getEnterpriseUserInvitationsReducer,
  countryEnterpriseRequirements: getAllCountryEnterpriseRequirementsReducer,
  enterprisePartners: getAllPartnersReducer,
  enterprises: getEnterprisesReducer,
  exchangeRates: getExchangeRatesReducer,
  formData:loadFormDataReducer,
  dealStats:getDealStatsReducer,
  tags: getAllTagsReducer,
  loggedInUser: getLoginUserReducer
});

export default motherReducer;
