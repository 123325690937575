export const IMAGE_TYPES = ["jpeg", "jpg", "png"];
export const COMPANY_SIZE_RANGES = [
  "1-10",
  "11-50",
  "51-200",
  "201-500",
  "501-1000",
  "1001-5000",
  "5001-10000",
  "10001-50000",
  "50001-100000",
  "100001-500000",
  "500001-1000000",
  "1000000+",
];

export const INDUSTRIES = [
  "Agriculture",
  "Automotive",
  "Banking",
  "Chemicals",
  "Construction",
  "Education",
  "Electronics",
  "Energy",
  "Entertainment",
  "Financial Services",
  "Food & Beverage",
  "Government",
  "Healthcare",
  "Hospitality",
  "Insurance",
  "Manufacturing",
  "Media",
  "Pharmaceuticals",
  "Real Estate",
  "Retail",
  "Technology",
  "Telecommunications",
  "Transportation",
  "Utilities",
  "Other",
];


export const BUSINESS_TYPE= ["Sole Proprietorship", "Partnership", "Corporation", "Limited Liability Company"];

export const COUNTRIES = [
  "Ghana",
  "USA",
  "UK",
  "Canada",
  "Nigeria",
  "South Africa",
  "India",
  "China",
  "Russia",
  "Japan",
  "Mexico",
  "Australia",
  "Brazil",
  "France",
  "Germany",
  "Italy",
  "Spain",
  "Turkey",
  "Switzerland",
  "Poland",
  "Belgium",
  "Czech Republic",
  "Denmark",
  "Finland",
  "Greece",
  "Hungary",
  "Ireland",
  "Luxembourg",
  "Norway",
  "Portugal",
  "Romania",
  "Slovakia",
  "Slovenia",
  "Sweden",
  "United Kingdom",
  "United States",
  "Other",
];

export const CURRENCIES = [{
  code: "USD",
  name: "US Dollar",
  symbol: "$",
},
{
  code: "EUR",
  name: "Euro",
  symbol: "€",
},
{
  code: "GBP",
  name: "British Pound",
  symbol: "£",

},
{
  code: "CAD",
  name: "Canadian Dollar",
  symbol: "$",

},
{
code:'GHS',
name:'Ghanaian Cedi',
symbol:'₵',
},

{
  code: "NGN",
  name: "Nigerian Naira",
  symbol: "₦",
},


];

export const BASIC = "basic" || "BASIC ADMIN";


export const BASIC_ADMIN = "BASIC ADMIN";
export const SUPER_ADMIN = "SUPER ADMIN";

export const  ROLES = [BASIC_ADMIN, SUPER_ADMIN];


export const CURRENCIES_CODES = CURRENCIES.map(currency => currency.code);

export const DAYS_OF_WEEK = ["MONDAY","TUESDAY", "WEDNESDAY", 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY']

export const PUSH = "PUSH";
export const REMOVE = "REMOVE";


export const VERIFICATION_STATUSES = {
  PENDING: "PENDING",
  VERIFIED: "VERIFIED",
  REJECTED: "REJECTED",
  SUBMITTED:'SUBMITTED',
}


export const PARTNER_TYPES = {
  ENTERPRISE: "ENTERPRISE",
  INDIVIDUAL: "INDIVIDUAL",
  TEAM: "TEAM",
}

export const ACCOUNT_TYPES = {
  BANK: "BANK",
  MOBILE: "MOBILE",
}
export const DISTRIBUTION_STATUSES = {
  COMPLETED: "COMPLETED",
  CREATED: "CREATED",
  PAYMENT_MADE: "PAYMENT_MADE",
  PAYMENT_RECEIVED: "PAYMENT_RECEIVED",
};

export const DEAL_STATUS = {
  DRAFT:"DRAFT",
  LIVE:"LIVE",
  COMPLETED:'COMPLETED',
}