import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {FormGenerator} from "@kehillahglobal/ui";
import ApiCallHandler from "../../../../../shared/js/ApiCallHandler";
import URLS from "../../../../../shared/js/urls";
import { getActiveEnterprise, parseJSON } from "../../../../../shared/js/utils";
import { editMutationAction } from "../../../../../store/actions/actions";
import { emailValidator, phoneNumberValidator } from "shared/js/fieldValidators";

class CreateAndEditAssetManager extends Component {
  onSubmit = (data, reset, toEdit) => {
    const { asset, enterprise } = this.props;
    data.asset_id = asset?.id;
    data.deal_id = asset?.deal;
    data.enterprise_id = enterprise?.id;
    data.info = JSON.stringify({
      status: data?.status || "PROBATION",
      more_info: data?.more_info || "Add extra information",
    });
    this.sendDataToBackend(data, reset, toEdit);
  };

  updateRedux = (toEdit, data) => {
    const { asset, currentDeal, updateItemInredux, deals } = this.props;
    let finalDeal = currentDeal;
    if (!toEdit?.id) {
      let updatedManagers =
        asset?.managers?.length || asset?.managers !== null
          ? [...asset?.managers, data]
          : [data];
      asset.managers = updatedManagers;
      let newAssets = finalDeal?.assets?.filter(
        (item) => item.id !== asset?.id
      );
      newAssets.push(asset);
      finalDeal.assets = newAssets;
    } else {
      toEdit.user = data;
      let updatedManager = toEdit;
      let updatedManagers = asset?.managers?.filter(
        (item) => item?.id !== toEdit?.id
      );
      updatedManagers.push(updatedManager);
      asset.managers = updatedManagers;

      let newAssets = finalDeal?.assets?.filter(
        (item) => item.id !== asset?.id
      );
      newAssets.push(asset);
      finalDeal.assets = newAssets;
    }
    updateItemInredux(deals, finalDeal);
  };

  sendDataToBackend = (data, reset, toEdit) => {
    if (!toEdit?.id) {
      data.role_name = "ASSET MANAGER";
      ApiCallHandler.send(URLS.CREATE_DEAL_ASSET_MANAGER, "POST", data).then(
        (res) => {
          if (res?.success) {
            this.updateRedux(toEdit, res?.data);
            reset();
            this.props.close();
          }
        }
      );
    } else {
      data.id = toEdit?.id;
      ApiCallHandler.send(URLS.UPDATE_USERS, "POST", {
        id: toEdit?.user?.id,
        first_name: data?.first_name,
        last_name: data?.last_name,
        email: data?.email,
        phone_number: data?.phone_number,
        info: data?.info,
      }).then((res) => {
        if (res?.success) {
          this.updateRedux(toEdit, res?.data[0]);
          reset();
          this.props.close();
        }
      });
    }
  };

  render() {
    let { item: manager } = this.props;
    let Fields = FormGenerator.Fields
    return (
      <div>
        <div id="form-container" style={{ marginBottom: 100 }}>
          <FormGenerator
            elevation={0}
            fields={[
              {
                fieldType: Fields.INPUT,
                label: "First Name",
                name: "first_name",
                placeholder: "First Name",
                required: true,
                value: manager?.id ? manager?.user?.first_name : "",
              },
              {
                fieldType: Fields.INPUT,
                label: "Last Name",
                name: "last_name",
                placeholder: "Last Name",
                required: true,
                value: manager?.id ? manager?.user?.last_name : "",
              },
              {
                fieldType: Fields.INPUT,
                label: "Email",
                name: "email",
                placeholder: "Email",
                required: true,
                value: manager?.id ? manager?.user?.email : "",
                validator:(email)=>emailValidator(email)
              },
              {
                fieldType: Fields.INPUT,
                label: "Phone number",
                name: "phone_number",
                placeholder: "Phone Number",
                required: true,
                value: manager?.id ? manager?.user?.phone_number : "",
                validator:(phone)=>phoneNumberValidator(phone)
              },
              {
                data: ["PROBATION", "WORKING", "TERMINATED"],
                fieldType: Fields.DROPDOWN,
                label: "Contract Status",
                name: "status",
                placeholder: "contract status",
                required: false,
                type: "full",
                value: manager?.id
                  ? parseJSON(manager?.user?.info)?.status
                  : "",
              },
              {
                fieldType: Fields.RICHTEXT,
                label: "Adding Information",
                name: "more_info",
                placeholder:
                  "More information about manager(links, images anything).",

                value: manager?.id
                  ? parseJSON(manager?.user?.info)?.more_info
                  : "",
              },
            ]}
            onSubmit={(data, reset) => this.onSubmit(data, reset, manager)}
            formSubmitBtnText={
              !this.props.item?.id ? "Create Manager" : "Save Changes"
            }
            subtitle=""
            title=""
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownerProps) => {
  const localEnterprise = getActiveEnterprise();
  const enterprise = state.enterprises?.find(
    (enterprise) => enterprise?.id === localEnterprise?.id
  );
  return {
    enterprise,
    deals: state.deals,
    currentDeal: state.deals.filter((x) => x.id === ownerProps?.dealID)[0],
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateItemInredux: (current_data, new_data) =>
        dispatch(editMutationAction("DEAL", current_data, new_data)),
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateAndEditAssetManager);
