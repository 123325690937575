let COLORS = {}

COLORS.DANGER_PRIMARY = "#CB4335";
COLORS.INCOME_PRIMARY = "#28a745";
COLORS.EXPENSE_PRIMARY = "#CD6155";
COLORS.DISTRIBUTION_PRIMARY = "#566573";
COLORS.REVENUE_PRIMARY = "#2E86C1";
COLORS.PARTNERS_PRIMARY = "#7D3C98";
COLORS.DEAL_PRIMARY = "#E67D21";

COLORS.DANGER_SECONDARY ="maroon";
COLORS.INCOME_SECONDARY = "#1E8449";
COLORS.PARTNERS_SECONDARY = "#5B2C6F";
COLORS.DEAL_SECONDARY = "#D35400";


export default COLORS;