import React, { Component } from "react";
import "./Enterprise.css";
import logo from "../../shared/imgs/enterprise_p.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faGlobe, faMailBulk, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import ApiCallHandler from "../../shared/js/ApiCallHandler";
import URLS from "../../shared/js/urls";
import { bindActionCreators } from "redux";
import { withRouter } from 'react-router';
import {
  editMutationAction,
  toggleToastAction,
  toggleModalAction
} from "../../store/actions/actions";
import { faFacebook, faInstagram, faLinkedin, faTwitterSquare } from "@fortawesome/free-brands-svg-icons";
import Verified from '../../shared/components/verified/Verified';
import CreateEnterprise from "./create enterprise/CreateEnterprise";
import { getActiveEnterprise, isEmpty } from "shared/js/utils";
import {Spinner,} from '@kehillahglobal/ui'

class EnterpriseSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logo: "",
      uploading: false,
    };
  }

  handleSelectImage = () => {
    let fileInput = document.getElementById("ent-logo-select");
    fileInput.click();

    const ent = getActiveEnterprise();

    const { enterprisesFromRedux: enterprises } = this.props;
    fileInput.onchange = async (e) => {
      this.setState({ uploading: true });
      let res = await ApiCallHandler.send(
        URLS.UPDATE_ENTERPRISES_LOGO,
        "POST",
        {
          logo: e.target.files[0],
          enterprise_id: ent?.id,
        }
      );
      if (res && res.success) {
        this.props.editRecord(enterprises, res?.data);
        this.setState({ uploading: false });
      } else {
        this.setState({ uploading: false });
        this.props.toggleToast({
          showToast: true,
          message: "An error occurred. Try again",
          type: "failure",
        });
      }
    };
  };

  gotoSite = (link)=>{
    if (!isEmpty(link) && !link.startsWith("http")){
      link =  "http://" + link
    }
    window.open(link, "_blank");
  }
  showEditEnterpriseModal = (item = {}) => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `Update Enterprise Info`,
        children: <CreateEnterprise editData={item} close={this.close} />,
        themeColor: "var(--app-theme)",
        size: "large",
      },
    });
  };

  close = () => {
    this.props.toggleModal({
      show: false,
    });
  };
  render() {
    const enterpriseLoc =getActiveEnterprise();

    let enterprise = this.props?.enterprisesFromRedux?.filter(
      (item) => item.id === enterpriseLoc.id
    )[0];
    const user = this.props.user

    if (!enterprise) {
      return (
        <div className="no-item-data-container">
          <Spinner />
        </div>
      );
    } else {
      return (
        <div id="enterprise-setting-wrapper">
          <div id="img-and-title-container">
            <div style={{ position: "relative", height:'max-content' }}>
              <img src={enterprise?.logo || logo} alt="logo" id="ent-logo" />
              {this.state.uploading ? (
                <div className="loading-overlay-container">
                  <Spinner className="my-spinner" />
                </div>
              ) : null}
              <input
                type="file"
                style={{ display: "none" }}
                id="ent-logo-select"
              />
              <div id="change-ent-logo" onClick={this.handleSelectImage}>
                <FontAwesomeIcon icon={faCamera} color="white" />
              </div>
            </div>

            <div id="txts-container">
              <div>
                <h2>
                  {enterprise?.name.toUpperCase()}
                  {enterprise?.is_verified ? (
                    <Verified bg={"green"} height={"24px"} width={"24px"} />
                  ) : null}
                </h2>
                <span className="email-and-domain">
                  {user?.email || "enterprise@gmail.com"}
                </span>
                <br />
                <br />

                <div
                  id="update-verification-text-container"
                  onClick={() => this.showEditEnterpriseModal()}
                >
                  <p>Edit Enterprise Details</p>
                </div>
              </div>
            </div>
          </div>

          <div id="bio-section-container">
            <h3>ABOUT</h3>
            <div
              id="bio-text-container"
              dangerouslySetInnerHTML={{
                __html:
                  enterprise?.bio ||
                  `You have not added any bio. Click "Edit Enterprise Details" to add.`,
              }}
            ></div>
          </div>
          {enterprise?.contacts?.secondary_email ||
          enterprise?.contacts?.primary_email ||
          enterprise?.contacts?.secondary_phone ||
          enterprise?.contacts?.primary_phone ? (
            <div id="bio-section-container">
              <h3>Contact Information</h3>

              <div id="contact-info-container">
                {enterprise?.contacts?.primary_phone && (
                  <div id="contact-info-item">
                    <FontAwesomeIcon icon={faPhoneAlt} />
                    <span> {enterprise?.contacts?.primary_phone}</span>
                  </div>
                )}
                {enterprise?.contacts?.secondary_phone && (
                  <div id="contact-info-item">
                    <FontAwesomeIcon icon={faPhoneAlt} />
                    <span> {enterprise?.contacts?.secondary_phone}</span>
                  </div>
                )}
                {enterprise?.contacts?.primary_email && (
                  <div id="contact-info-item">
                    <FontAwesomeIcon icon={faMailBulk} />
                    <span> {enterprise?.contacts?.primary_email}</span>
                  </div>
                )}

                {enterprise?.contacts?.secondary_email && (
                  <div id="contact-info-item">
                    <FontAwesomeIcon icon={faMailBulk} />
                    <span> {enterprise?.contacts?.secondary_email}</span>
                  </div>
                )}
              </div>
            </div>
          ) : null}

          {enterprise?.contacts && (
            <div id="bio-section-container" style={{ width: "60%" }}>
              {(enterprise?.contacts?.website ||enterprise?.contacts?.linkedin ||
                enterprise?.contacts?.facebook || enterprise?.contacts?.twitter
                 ) && (<h3>Social Media Links</h3>)}

              <div id="social-info-container">
                {enterprise?.contacts?.website && (
                  <div id="contact-info-item">
                    <FontAwesomeIcon icon={faGlobe} />{" "}
                    <span
                      onClick={() =>
                        this.gotoSite(enterprise?.contacts?.website)
                      }
                    >
                      Website
                    </span>
                  </div>
                )}
                {enterprise?.contacts?.facebook && (
                  <div id="contact-info-item">
                    <FontAwesomeIcon icon={faFacebook} />{" "}
                    <span
                      onClick={() =>
                        this.gotoSite(enterprise?.contacts?.facebook)
                      }
                    >
                      Facebook
                    </span>
                  </div>
                )}
                {enterprise?.contacts?.linkedin && (
                  <div id="contact-info-item">
                    <FontAwesomeIcon icon={faLinkedin} />{" "}
                    <span
                      onClick={() =>
                        this.gotoSite(enterprise?.contacts?.linkedin)
                      }
                    >
                      LinkedIn
                    </span>
                  </div>
                )}
                {enterprise?.contacts?.instagram && (
                  <div id="contact-info-item">
                    <FontAwesomeIcon icon={faInstagram} />{" "}
                    <span
                      onClick={() =>
                        this.gotoSite(enterprise?.contacts?.instagram)
                      }
                    >
                      Instagram
                    </span>
                  </div>
                )}
                {enterprise?.contacts?.twitter && (
                  <div id="contact-info-item">
                    <FontAwesomeIcon icon={faTwitterSquare} />{" "}
                    <span
                      onClick={() =>
                        this.gotoSite(enterprise?.contacts?.twitter)
                      }
                    >
                      Twitter
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    enterprisesFromRedux: state.enterprises,
    user:state.loggedInUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleToast: toggleToastAction,
      editRecord: (current_data, new_data) =>
        dispatch(
          editMutationAction("ENTERPRISE_MUTATION", current_data, new_data)
        ),
      toggleModal: toggleModalAction,
    },
    dispatch
  );
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EnterpriseSettings));
