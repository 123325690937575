import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import './PartnerDetail.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe, faMailBulk } from '@fortawesome/free-solid-svg-icons';
import Verified from '../../../shared/components/verified/Verified';
import { faFacebookSquare, faInstagramSquare, faLinkedin, faTwitterSquare } from "@fortawesome/free-brands-svg-icons";
import { Table, NoItems } from "@kehillahglobal/ui";
import { convertNumberToShortForm, parseJSON } from "shared/js/utils";


class PartnerDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partnerships: [],
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props?.partnerships?.length) {
      let id = props?.match?.params?.id;
      let partnerships = props?.partnerships?.filter(
        (partner) => partner?.user?.id === id || partner?.enterprise?.id === id
      );
      return {
        partnerships,
      };
    }
    return null;
  }

  showProfileInfo = () => {
    let { partnerships } = this.state;
    let partner = partnerships[0];
    return (
      <div id="profile-info-container">
        <div id="img-info-container">
          <div id="profile-img-container" style={{ position: "relative" }}>
            <img src={partner?.user?.image} id="profile-img" alt="  " />
          </div>
          <div id="info-container">
            <h3>
              {partner?.user?.first_name} {partner?.user?.last_name}
              {partner?.user?.is_verified ? (
                <Verified bg={"green"} height={"25px"} width={"25px"} />
              ) : null}
            </h3>
            <p>
              <FontAwesomeIcon icon={faMailBulk} /> {partner?.user?.email || ""}
            </p>
            {this.renderSocialMedia(partner?.user?.contacts)}
          </div>
        </div>
      </div>
    );
  };

  renderInterests = (items) => {
    if (!items) return;
    const interests = parseJSON(items);
    return (interests || []).map((interest, index) => {
      return (
        <div
          key={index.toString()}
          style={{ marginBottom: 8 }}
          className="tag-container"
        >
          <div className="tag-item elevate-float" style={{ marginRight: 8 }}>
            {interest}
          </div>
        </div>
      );
    });
  };

  openLink = (link) => {
    window.open(link, "_blank");
  };
  renderSocialMedia = (contacts) => {
    if (contacts !== null) {
      return (
        <>
          <div id="social-media-container">
            {contacts?.website && (
              <FontAwesomeIcon
                icon={faGlobe}
                onClick={() => this.openLink(contacts?.website)}
              />
            )}
            {contacts?.twiter && (
              <FontAwesomeIcon
                icon={faTwitterSquare}
                onClick={() => this.openLink(contacts?.twitter)}
              />
            )}
            {contacts?.facebook && (
              <FontAwesomeIcon
                icon={faFacebookSquare}
                onClick={() => this.openLink(contacts?.facebook)}
              />
            )}
            {contacts?.instagram && (
              <FontAwesomeIcon
                icon={faInstagramSquare}
                onClick={() => this.openLink(contacts?.instagram)}
              />
            )}
            {contacts?.linkedin && (
              <FontAwesomeIcon
                icon={faLinkedin}
                onClick={() => this.openLink(contacts?.linkedin)}
              />
            )}
          </div>
        </>
      );
    }
  };
  getPartnerPercentage = (item) => {
    let { deal } = item;
    let shares = item?.shares;
    let totalShares = deal.shares || 1;
    return parseFloat((shares / totalShares) * 100).toFixed(2);
  };
  prepareTableData = () => {
    let { partnerships } = this.state;

    return partnerships.map((partnership, index) => {
      return [
        <p>{index + 1}</p>,
        <p>{partnership?.deal?.name}</p>,
        <p>{partnership?.shares}</p>,
        <p>
          {convertNumberToShortForm(
            partnership?.shares * partnership?.deal?.price_per_share
          )}
        </p>,
        <p>{this.getPartnerPercentage(partnership)} %</p>,
        <p>{partnership?.deal?.status}</p>,
      ];
    });
  };

  renderTable = () => {
    let { partnerships } = this.state;
    return (
      <>
        {partnerships?.length > 0 ? (
          <Table
            columns={["#", "Deal", "Shares", "Amount", "Percentage", "status"]}
            data={this.prepareTableData()}
            hoverAnimation={false}
          />
        ) : (
          <div>
            <NoItems text="No Partners" />
          </div>
        )}
      </>
    );
  };

  render() {
    let { partnerships } = this.state;
    let partner = partnerships[0];
    return (
      <div style={{ width: "90%", margin: "auto", marginTop: "1rem" }}>
        {this.showProfileInfo()}

        <div style={{ marginTop: "2rem" }}>
          <div id="biography-container">
            <h3>About</h3>

            <div
              dangerouslySetInnerHTML={{
                __html: partner?.user?.bio
                  ? partner?.user?.bio
                  : "No biography added",
              }}
            ></div>
          </div>
        </div>

        {partner?.user?.interests !== null ? (
          <div style={{ marginTop: "2rem" }}>
            <div id="biography-container">
              <h3>Interests</h3>

              {this.renderInterests(partner?.user?.interests?.interests)}
            </div>
          </div>
        ) : null}

        <div style={{ marginTop: "2rem" }}>
          <div id="partner-deals-title">
            <h3>Deals</h3>

            {this.renderTable()}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    partnerships: state.enterprisePartners,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerDetail);
