import React, { Component } from "react";
import ApiCallHandler from "shared/js/ApiCallHandler";
import URLS from "shared/js/urls";
import {FormGenerator} from "@kehillahglobal/ui"
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  editMutationAction,
  toggleModalAction,
  toggleToastAction,
} from "store/actions/actions";
import { isEmpty, hasEmptyFields, getActiveEnterprise, getRandomStringKey } from '../../../../shared/js/utils';
import { createDraftWithParentFormData } from "shared/js/globalReduxInteractions";
import { updateTypeDataInRedux, findAndReturnDraft } from '../../../../shared/js/globalReduxInteractions';
import { getDealStatsAction } from '../../../../store/actions/actions';
import { numberValidator } from "shared/js/fieldValidators";

class CreateDealRevenue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentData: {
        income_type: "",
        amount: "",
        payment_mode: "",
        transaction_date: "",
        reference_id: "",
        receipt: "",
        notes: "",
      },
      formData: {},
      key: Math.random(),
    };
  }

  componentDidMount() {
    let { editData } = this.props;
    if (editData) {
      this.setState({ currentData: editData });
    }
  }
  static getDerivedStateFromProps(props, state) {
    if (props.editData) {
      return {
        currentData: hasEmptyFields(state.currentData)
          ? { ...props.editData, ...{} }
          : { ...props.editData, ...state.currentData },
      };
    }
    return null;
  }
  handleCreate = async (data, editData, reset) => {
    let { currentDeal } = this.props;

    let res;
    let toSend = {
      ...data,
      amount: parseFloat(data.amount),
      income_type_id: data && data.income_type ? data.income_type.id : "",
      deal_id: currentDeal.id,
    };
    if(toSend?.income_type){
      delete toSend.income_type
    }
    if (data?.receipt?.data?.file?.data) {
      toSend.receipt = data.receipt.data.file.data;
    } else {
      delete toSend.receipt;
    }

    if (!editData?.id) {
      let ent = getActiveEnterprise();
      res = await ApiCallHandler.send(URLS.CREATE_DEAL_INCOME, "POST", {
        ...toSend,
        enterprise_id: ent?.id,
      });
    } else {
      let newToSend = { ...toSend, id: editData?.id };
      res = await ApiCallHandler.send(
        URLS.EDIT_DEAL_INCOME,
        "POST",
        newToSend
      );
    }

    if (res && res.success) {
      this.updateReduxStore(editData?.id, res);
      let stats = await this.props.getOppStats(currentDeal.id);
      this.props.updateStats(stats?.payload);
      this.props.toggleToast({
        show: true,
        message: !editData?.id
          ? "Income created successfully"
          : "Income Updated successfully",
        type: "success",
      });
      reset();
      this.props.toggleModal({ show: false });
    } else {
      this.props.toggleToast({
        showToast: true,
        message: "An error occurred. Try again",
        type: "failure",
      });
      this.props.toggleModal({ show: false });
    }
  };

  updateReduxStore = (is_edit, res) => {
    let { currentDeal } = this.props;
    let newIncomes;

    if (!is_edit) {
      newIncomes = isEmpty(currentDeal?.income)
        ? [res?.data]
        : [res?.data,...currentDeal?.income];
    } else {
      const index = currentDeal?.income?.map((object) => object.id).indexOf(res?.data[0]?.id);



      newIncomes = currentDeal?.income?.filter(
        (item) => item?.id !== res?.data[0].id
      );
      newIncomes?.splice(index,0, res?.data[0]);
    }
    currentDeal.income = newIncomes;

    this.props.updateItemInredux(this.props.deals, currentDeal);
  };

  close = () => {
    this.props.toggleModal({ show: false });
  };
  updateFields = (res) => {
    let { currentData } = this.state;
    let data = currentData;
    let draft = findAndReturnDraft(res?.draftId) || {};
    data = { ...data, ...draft };
    data.income_type = res?.data;
    this.setState({ currentData: data, key: getRandomStringKey() });
  };

  render() {
    let { incomeTypes, editData } = this.props;
    let getFieldValue = (field) => {
      let value = this.state.currentData[field] || "";
      return value;
    };
    const Fields = FormGenerator?.Fields
    return (
      <div key={this.state.key}>
        <FormGenerator
          elevation={0}
          fields={[
            {
              data: incomeTypes || [],
              fieldType: Fields.DROPDOWN,
              label: "Choose The Income Type",
              name: "income_type",
              placeholder: "Select Income Type",
              required: true,
              type: "full",
              value: getFieldValue("income_type"),
              labelExtractor: (item) => item.name,
              valueExtractor: (item) => item.id,
              child: {
                fields: [
                  {
                    fieldType: Fields.INPUT,
                    label: "Income Type Name",
                    name: "name",
                    placeholder: "Enter Income Type Name",
                    required: true,
                    value: "",
                  },
                ],
                metaData: {
                  endpoint: URLS.CREATE_NEW_DEAL_INCOME_TYPE,
                  additionalData: {
                    deal_id: this.props.currentDeal?.id,
                  },
                  onSuccess: (res) => {
                    updateTypeDataInRedux(
                      res?.data,
                      "income_types",
                      this.props.currentDeal?.id
                    );
                    this.updateFields(res);
                  },
                  createDraft: (id) =>
                    createDraftWithParentFormData(this.state.formData, id),
                },
              },
            },
            {
              fieldType: Fields.INPUT,
              label: "Set Amount",
              name: "amount",
              placeholder: "How Much Was The Income ? Eg. 400",
              required: true,
              value: getFieldValue("amount"),
              validator: (value) => numberValidator(value),
            },
            {
              data: ["MOBILE", "CASH", "BANK"],
              fieldType: Fields.DROPDOWN,
              label: "Payment Mode",
              name: "payment_mode",
              type: "full",
              placeholder: "Select Payment Mode",
              required: true,
              value: getFieldValue("payment_mode"),
            },
            {
              fieldType: Fields.DATE,
              label: "Transaction date",
              name: "transaction_date",
              placeholder: "Select Date",
              required: true,
              value: getFieldValue("transaction_date")?.split("T")[0],
        
            },
            {
              fieldType: Fields.INPUT,
              label: "Enter Ref. Id",
              name: "reference_id",
              placeholder: "Enter Ref. Id",
              required: false,
              value: getFieldValue("reference_id"),
            },
            {
              fieldType: Fields.FILE,
              label: "Receipt",
              name: "receipt",
              placeholder: "Upload Receipt",
              required: false,
              value: getFieldValue("receipt"),
            },
            {
              fieldType: Fields.TEXTAREA,
              label: "Notes",
              name: "notes",
              placeholder: "Short Notes Here...",
              value: getFieldValue("notes"),
            },
          ]}
          onSubmit={(data, reset) => this.handleCreate(data, editData, reset)}
          formSubmitBtnText={!editData?.id ? "create income" : "Save Changes"}
          subtitle=""
          title=""
          onStateChange={(data) => this.setState({ formData: data })}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownerProps) => {
  const currentDeal = state?.deals?.filter(
    (x) => x.id === ownerProps?.currentDeal
  )[0];
  return {
    incomeTypes: currentDeal?.income_types,
    currentDeal,
    deals: state.deals,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleToast: toggleToastAction,
      toggleModal: toggleModalAction,
      getOppStats:getDealStatsAction,
      updateItemInredux: (current_data, new_data) =>
        dispatch(editMutationAction("DEAL", current_data, new_data)),
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateDealRevenue);
