import React, { Component } from "react";
import URLS from "../../shared/js/urls";
import Header from "../../components/header/Header";
import loginSVG from "../../shared/imgs/loginSVG.svg";
import "./Landing.css";
import { withRouter } from "react-router-dom";

class Landing extends Component {
  // componentDidMount() {
  //   let encoded = document?.cookie("token");
  //   if (encoded) {
  //     this.props.history.push("/dashboard");
  //   }
  // }
  render() {
    return (
      <div
        style={{
          height: "100vh",
          width: "100%",
          background: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textTransform: "uppercase",
          fontWeight: "bold",
          flexDirection: "column",
        }}
      >
        <center>
          <Header title="ADMIN PORTAL" subtitle=" " />
        </center>

        <center
          style={{
            marginTop: "20px",
          }}
        >
          <img
            src={loginSVG}
            alt="login"
            style={{
              width: "60%",
            }}
          />
        </center>

        <div
          className="login-btn-container elevate-float"
          onClick={() => (window.location.href = URLS.AUTH_URL)}
        >
          <p>Login</p>
        </div>
      </div>
    );
  }
}

export default withRouter(Landing);
