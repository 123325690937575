import store from "../../store/store";
import { isEmpty } from "./utils";
import {
  editMutationAction,
  loadFormDataAction,
} from "../../store/actions/actions";
import { PUSH } from "./typeConstants";

export const updateTypeDataInRedux = (res, name, dealID='') => {
  let deals = store.getState().deals;
  let currentDeal = deals.filter((x) => x.id === dealID)[0];
  let types = currentDeal[name];
  types = isEmpty(types) ? [res] : [res, ...types];
  currentDeal[name] = types;
  store.dispatch(
    editMutationAction("DEAL", deals, currentDeal)
  );
};

export const createDraftWithParentFormData = (formData, idName) => {
  store.dispatch(
    loadFormDataAction({
      idName: idName,
      type: PUSH,
      draft: {
        ...formData,
      },
    })
  );

  return idName;
};

export const findAndReturnDraft = (idName) => {
  if (!idName) return null;
  let formData = store.getState().formData;
  let draft = formData.filter((x) => x.idName === idName)[0];
  return draft;
};
