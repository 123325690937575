import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  toggleModalAction,
  setEditSetterAction,
  editMutationAction,
} from "store/actions/actions";
import Title from "components/title/Title";
import {Table, NoItems} from "@kehillahglobal/ui";
import ApiCallHandler from "shared/js/ApiCallHandler";
import URLS from "shared/js/urls";
import { withRouter } from "react-router-dom";
import ShowActions from '../../../../../shared/components/showActions/ShowActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { getMeaningfulDate, getFileType } from '../../../../../shared/js/utils';
import CreateDealDocument from '../create/CreateDealDocument';
import {Descriptions} from '../../../../../documentation/Descriptions';
import Note from "shared/components/note/Note";
import ModalContent from "shared/components/modal-content/ModalContent";

class DealsDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showActions: false,
      id: "",
      deal:{}
    };
  }

  componentDidMount(){
    let {id} = this.props.match.params;
    if(id){
      let deal = this.props.deals.find(deal => deal.id === id);
      this.setState({deal});
    }
  }

  static getDerivedStateFromProps(props) {
      let { id } = props.match.params;
      if (id) {
        let deal = props.deals.find((deal) => deal.id === id);
        return{
          deal
        }
      }
      return;
  }
  handleShowDetail = (item) => {
    window.open(item?.document, "_blank");
  };

  deleteItemFromRedux = (id) => {
    const { deals, updateRedux } = this.props;
    let {deal} = this.state;
    let newDocuments = deal?.documents?.filter(
      (item) => item?.id !== id
    );
    deal.documents = newDocuments;
    updateRedux(deals, deal);
  };

  handleDeleteDocument = (item) => {
    ApiCallHandler.send(URLS.ARCHIVE_DEALS_DOCUMENTS, "POST", {
      id: item?.id,
    }).then(() => {
      this.deleteItemFromRedux(item?.id);
    });
  };

  handleShowDeleteModal = (item) => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `Delete "${item?.title}"`,
        children: (
          <ModalContent content={"Would you like to delete this record ?"} />
        ),
        themeColor: "maroon",
        cancel: true,
        okay: {
          text: "Yes",
          function: () => this.handleDeleteDocument(item),
        },
      },
    });
  };
  toggleShowMoreOptions = (item) => {
    if (!item?.id) {
      this.setState({
        showActions: false,
        id: "",
      });
    } else {
      this.setState({
        showActions: true,
        id: item?.id,
      });
    }
  };
  prepareTableData = () => {
    let { deal:{documents} } = this.state;
    let tableData = (documents || []).map((item) => [
      <p>{getMeaningfulDate(item?.date)}</p>,
      <p onClick={() => this.handleShowDetail(item)} id="doc-name-txt">
        {item?.title}
      </p>,
      <p id="doc-type-container">
        {getFileType(item?.document).toUpperCase()}
      </p>,
      <p>{item?.is_published ? "Public" : "Private"}</p>,
      <ShowActions
        onEdit={this.handleAddDocument}
        onDelete={this.handleShowDeleteModal}
        onView={this.handleShowDetail}
        item={item}
        id={this.state.id}
        toggleShowMoreOptions={this.toggleShowMoreOptions}
        show={this.state.showActions}
      />,
    ]);
    return tableData;
  };
  closeModal = () => {
    this.props.toggleModal({ show: false });
  };

  handleAddDocument = (item = null) => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `${item?.id ? "Update" : "Add New "} document`,
        children: (
          <CreateDealDocument
            close={this.closeModal}
            doc={item}
            currentDeal={this.state?.deal?.id}
          />
        ),
        themeColor: "var(--app-theme)",
        size: "large",
      },
    });
  };

  renderDocuments = () => {
    let { deal:{documents} } = this.state;
    return (
      <div>
        <div style={{ borderRadius: 10 }}>
          <Note note={Descriptions.DEAL_DOCUMENT} />
          <div style={{ marginBottom: "1.5rem" }}>
            <Title
              className="text-toggler"
              onClick={() => this.handleAddDocument()}
            >
              <FontAwesomeIcon icon={faPlus} /> Add Document
            </Title>
          </div>

          {documents && documents.length ? (
            <div style={{ width: "90%" }}>
              <Table
                columns={["Created At", "Name", "Type", "Status", "\t"]}
                data={this.prepareTableData()}
                hoverAnimation={false}
              />
            </div>
          ) : (
            <NoItems text="No Assets yet" />
          )}
        </div>
      </div>
    );
  };

  render() {
    return <div>{this.renderDocuments()}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    deals: state.deals,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleModal: toggleModalAction,
      setEditPayload: (val) => dispatch(setEditSetterAction(val)),
      updateRedux: (current_data, new_data) =>
        dispatch(editMutationAction("DEAL", current_data, new_data)),
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DealsDocuments)
);
