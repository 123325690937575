import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  setEditSetterAction,
  toggleModalAction,
  toggleToastAction,
  editMutationAction,
} from "store/actions/actions";
import Title from "components/title/Title";
import ApiCallHandler from "shared/js/ApiCallHandler";
import URLS from "shared/js/urls";
import { FormGenerator, Table, NoItems } from "@kehillahglobal/ui";
import "./style.css";
import {
  convertNumberToShortForm,
  getActiveEnterprise,
  getMeaningfulDate,
} from "shared/js/utils";
import { getCountryInfo } from "../../../../../shared/js/utils";
import { PARTNER_TYPES } from "../../../../../shared/js/typeConstants";
import BackButton from "shared/components/back-btn/BackButton";

class AllDistributionsForSinglePayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showToast: false,
      msg: "",
      toast_type: "",
      routeName: "",
      currentData: {
        asset: "",
        type: "",
        id: "",
        period_start: "",
        period_end: "",
        amount: "",
      },
      deal:{}
    };
  }
  componentDidMount() {
    let { dealID } = this.props.match.params;
    if (dealID) {
      let deal = this.props.deals.find((deal) => deal.id === dealID);
      this.setState({ deal });
    }
  }

  static getDerivedStateFromProps(props) {
    let { dealID } = props.match.params;
    if (dealID) {
      let deal = props.deals.find((deal) => deal.id === dealID);
      return {
        deal,
      };
    }
    return;
  }

  renderEditDistributionForm = (item) => {
    const handleCreate = async (data) => {
      const toSend = {
        id: item.id,
        payment_status: data?.status?.id,
        deal_id: this.state.deal.id,
      };
      try {
        const res = await ApiCallHandler.send(
          URLS.UPDATE_NEW_DEAL_DISTRIBUTION,
          "POST",
          toSend
        );
        if (res && res.success) {
          this.props.toggleToast({
            show: true,
            message: "Success",
            type: "success",
          });
          await this.props.editRecord(this.props.deals, res.data);
          this.props.setEditPayload({});
          this.props.toggleModal({ show: false });
        } else {
          this.props.toggleToast({
            showToast: true,
            message: "An Error occurred Please try again later",
            type: "failure",
          });
          this.props.toggleModal({ show: false });
          this.props.setEditPayload({});
        }
      } catch (error) {}
    };
let Fields = FormGenerator.Fields
    return (
      <FormGenerator
        elevation={0}
        fields={[
          {
            data: [
              { name: "CREATED", id: "CREATED" },
              { name: "PROCESSING", id: "PROCESSING" },
              { name: "MONEY-IN-TRANSIT", id: "MONEY-IN-TRANSIT" },
              { name: "TRANSACTION-FAILED", id: "TRANSACTION-FAILED" },
              {
                name: "MONEY-RECEIVED-BY-PARTNER",
                id: "MONEY-RECEIVED-BY-PARTNER",
              },
              { name: "COMPLETE", id: "COMPLETE" },
            ],
            fieldType: Fields.DROPDOWN,
            label: "Set Status",
            name: "status",
            placeholder: "Select Status",
            required: true,
            value: { name: item?.payment_status, id: item?.payment_status },
            type: "full",
            labelExtractor: (item) => item.name,
            valueExtractor: (item) => item.id,
          },
        ]}
        onSubmit={handleCreate}
        formSubmitBtnText="UPDATE STATUS"
        subtitle=""
        title=""
      />
    );
  };

  computePartnerPercentage = (item) => {
    const { deal } = this.state;
    let shares = item?.partner?.shares || 0;
    let percentage = (shares / deal?.shares) * 100;
    return percentage?.toFixed(2);
  };

  prepareTableData = () => {
    let currentPayout = this.extractPayoutData();
    const allPayoutDistributions = currentPayout?.distributions || [];
    let tableData = [];
    if (allPayoutDistributions && allPayoutDistributions?.length > 0) {
      allPayoutDistributions.forEach((item, index) => {
        tableData.push([
          <p>{index + 1}</p>,
          <p
            onClick={() => {
              this.props.history.push(
                `/enterprise/${getActiveEnterprise()?.id}/deals/${
                  this.state?.deal?.id
                }/payouts/${currentPayout.id}/distributions/${item.id}`
              );
              
            }}
          >
            {item?.partner?.partner_type !== PARTNER_TYPES.ENTERPRISE
              ? item?.partner?.user?.first_name
              : item?.partner?.enterprise?.name}
          </p>,
          <p>{item?.partner?.shares} </p>,
          <p>{this.computePartnerPercentage(item)}%</p>,
          <p>{item.full_amount?.toFixed(2)}</p>,
          <p>{item.transaction_charges?.toFixed(2)}</p>,
          <p>{item?.payment_status}</p>,
          <p>
            {item?.partner?.user?.payment_election?.length ? "Set" : "Not Set"}
          </p>,
        ]);
      });
    }
    return tableData;
  };

  extractPayoutData = ()=>{
     let { deal } = this.state;
     let params = this.props?.match?.params
     const currentPayout =deal?.payouts?.filter((x) => x.id === params?.id)[0] || {};
     return currentPayout

  }

  renderDistributionsInfo = () => {
    let { currentEnterprise } = this.props;
    let currentPayout = this.extractPayoutData();
    let country = getCountryInfo(currentEnterprise?.currency);

    const allPayoutDistributions = currentPayout?.distributions || [];
    return (
      <div style={{ marginTop: 20 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "95%",
          }}
        >
          <div id="next-payout-date">
            <p>Next Payout Date</p>
            <div>
              <h2>{getMeaningfulDate(currentPayout?.next_payout_date)}</h2>
            </div>
          </div>
          <div>
            <p>Total Amount for Payout</p>
            <div className="payout-available available">
              <span>{country?.currency?.symbol}</span>
              <p>
                {convertNumberToShortForm(
                  currentPayout?.amount?.toFixed(2) || 0.0
                )}
              </p>
            </div>
          </div>
        </div>

        <div style={{ borderRadius: 10, marginTop: 20 }}>
          <Title>Distributions</Title>

          {allPayoutDistributions && allPayoutDistributions.length > 0 ? (
            <Table
              columns={[
                "#",
                "Name",
                "Shares",
                "percentage",
                `Amount (${country?.currency?.symbol})`,
                `Charges (${country?.currency?.symbol})`,
                "Status",
                "Payment Election",
              ]}
              data={this.prepareTableData()}
              hoverAnimation={false}
            />
          ) : (
            <NoItems text="No Asset Expenses Yet..." />
          )}
        </div>
      </div>
    );
  };

  render() {
    return (
      <>
        <div className="distribution-detail-root">
          <BackButton />
          {this.renderDistributionsInfo()}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const currentEnterprise = state?.currentEnterprise;
  return {
    deals: state.deals,
    currentEnterprise,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleToast: toggleToastAction,
      setEditPayload: (val) => dispatch(setEditSetterAction(val)),
      toggleModal: toggleModalAction,
      editRecord: (current_data, new_data) =>
        dispatch(editMutationAction("DEAL", current_data, new_data)),
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllDistributionsForSinglePayout);
