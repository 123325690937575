import React, { Component } from 'react'
import OverviewChart from './Charts/OverviewChart'
import './dealCharts.css'

class DealCharts extends Component {    
    render() {
        let {deal}=  this.props
        return (
          <div style={{ marginBottom: 20 }}>
            <div className='deal-bar-chart-container'>
              <OverviewChart deal={deal} />
            </div>
            {/* {(deal?.income?.length ||
              deal?.expenses?.length) && (
                <div className="deal-pie-chart">
                  <center>
                    <Title>Revenue Vs Expenses</Title>
                  </center>

                  <RevenueVsExpense deal={deal} />
                </div>
              )} */}
          </div>
        );
    }
}

export default DealCharts