import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  toggleSidePane,
  toggleModalAction,
  toggleToastAction,
  toggleImagePreviewAction,
  editMutationAction,
} from "store/actions/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faPlus,
  faEllipsisV,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import Title from "components/title/Title";
import ApiCallHandler from "shared/js/ApiCallHandler";
import URLS from "shared/js/urls";
import "../deal-feeds.css";
import {
  capitalizeMe,
  getFileType,
  getActiveEnterprise,
  getMeaningfulDate,
} from "shared/js/utils";
import { setEditSetterAction } from "../../../../../store/actions/actions";
import { withRouter } from "react-router-dom";
import Note from "shared/components/note/Note";
import { Descriptions } from "documentation/Descriptions";
import ModalContent from "shared/components/modal-content/ModalContent";
import { FormGenerator, NoItems } from "@kehillahglobal/ui";

class CreateAndListDealFeeds extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showToast: false,
      showActions: false,
      toShowActionId: "",
      viewMore: false,
      moreId: "",
      deal: {},
    };
  }

  componentDidMount() {
    let { id } = this.props.match.params;
    if (id) {
      let deal = this.props.deals.find((deal) => deal.id === id);
      this.setState({ deal });
    }
  }

  static getDerivedStateFromProps(props) {
    let { id } = props.match.params;
    if (id) {
      let deal = props.deals.find((deal) => deal.id === id);
      return {
        deal,
      };
    }
    return;
  }

  updateReduxState = (isEdit, res) => {
    const { updateItemInredux, deals } = this.props;
    let { deal } = this.state;
    let newFeeds;
    if (!isEdit) {
      newFeeds = (deal?.feeds || [])?.filter(
        (item) => item.id !== res?.data?.id
      );
      newFeeds = [res?.data, ...newFeeds];
    } else {
      newFeeds = deal?.feeds?.filter((item) => item.id !== res?.data[0]?.id);
      const index = deal?.feeds
        ?.map((object) => object.id)
        .indexOf(res?.data[0]?.id);

      newFeeds?.splice(index, 0, res?.data[0]);
    }
    deal.feeds = newFeeds;
    updateItemInredux(deals, deal);
  };

  handleCreate = async (data, editData, reset) => {
    const { user } = this.props;
    let res;
    let toSend = {
      ...data,
      deal_id: this.state.deal?.id,
    };

    if (data?.file && data.file.data) {
      toSend = { ...toSend, file: data.file.data?.file?.data };
    } else {
      delete toSend.file;
    }

    try {
      if (!editData?.id) {
        toSend.author = user?.id;
        res = await ApiCallHandler.send(URLS.CREATE_DEAL_FEED, "POST", toSend);
      } else {
        res = await ApiCallHandler.send(URLS.UPDATE_DEAL_FEED, "POST", {
          ...toSend,
          id: editData?.id,
        });
      }

      if (res && res.success) {
        this.updateReduxState(editData?.id, res);
        this.setState({ showActions: false, toShowActionId: null });
        this.props.toggleModal({ show: false });
        this.props.toggleToast({
          show: true,
          message: `Deal feed ${
            editData?.id ? "updated" : "added"
          } successfully`,
          type: "success",
        });

        reset();
      } else {
        this.props.toggleToast({
          showToast: true,
          message: "Error creating a deal feed. Try again",
          type: "failure",
        });
        this.props.toggleModal({ show: false });
      }
    } catch (err) {}
  };

  renderCreateForm = (editData) => {
    let {Fields} = FormGenerator
    const formData = [
      {
        fieldType: Fields.INPUT,
        label: "Title",
        name: "title",
        placeholder: "Title Here...",
        required: true,
        value: editData?.id ? editData?.title : "",
      },
      {
        fieldType: Fields.RICHTEXT,
        label: "Body",
        name: "body",
        placeholder: "Body Here...",
        value: editData?.id ? editData?.body : "",
      },
      {
        fieldType: Fields.FILE,
        label: "Upload Attachment",
        name: "file",
        placeholder: "Upload File",
        value: editData?.id ? editData?.file : "",
      },
    ];
    return (
      <>
        <FormGenerator
          elevation={0}
          fields={formData}
          onSubmit={(data, reset) => this.handleCreate(data, editData, reset)}
          formSubmitBtnText={!editData?.id ? "Create Post" : "Save Post"}
          subtitle=""
          title=""
        />
        <br />
        <br />
        <br />
      </>
    );
  };

  showMoreActions = (id = null) => {
    let { showActions } = this.state;
    if (showActions) {
      this.setState({ showActions: false, toShowActionId: null });
    } else {
      this.setState({ showActions: true, toShowActionId: id });
    }
  };

  removeItemFromRedux = (data) => {
    const { updateItemInredux, deals } = this.props;
    let { deal } = this.state;
    let finalDeal = deal;
    let newFeeds = finalDeal?.feeds?.filter((item) => item.id !== data.id);
    finalDeal.feeds = newFeeds;
    updateItemInredux(deals, finalDeal);
  };

  handleDelete = (item) => {
    ApiCallHandler.send(URLS.ARCHIVE_DEAL_FEED, "POST", {
      id: item?.id,
    }).then((res) => {
      if (res && res.success) {
        this.removeItemFromRedux(res?.data);
        this.setState({ showActions: false, toShowActionId: null });
        this.props.toggleToast({
          show: true,
          message: "Deal feed deleted successfully",
          type: "success",
        });
      } else {
        this.props.toggleToast({
          showToast: true,
          message: "An error occurred. Try again",
          type: "failure",
        });
      }
    });
  };

  handleShowDeleteModal = (item) => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `Delete "${item?.title}"`,
        children: (
          <ModalContent content={"Would you like to delete this record ?"} />
        ),
        themeColor: "maroon",
        cancel: true,
        okay: {
          text: "Yes",
          function: () => this.handleDelete(item),
        },
      },
    });
  };

  handleToggleAddOrEditFeed = (item = {}) => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `${item?.id ? "Edit" : "Add"}  Realtime Update`,
        size: "large",
        children: this.renderCreateForm(item),
        themeColor: "var(--app-theme)",
      },
    });
  };

  toggleImagePreview = (file) => {
    this.props.toggleImagePreview({
      show: true,
      image: file,
    });
  };

  showFiles = (file) => {
    const IMAGE_TYPES = ["png", "jpeg", "jpg"];
    const VIDEO_TYPES = ["mp4", "mov", "avi", "wmv", "flv", "mpeg", "mpg"];
    if (IMAGE_TYPES.includes(getFileType(file)))
      return (
        <img src={file} alt="" onClick={() => this.toggleImagePreview(file)} />
      );
    else if (VIDEO_TYPES.includes(getFileType(file)))
      return (
        <video
          src={file}
          controls
          onClick={() => this.toggleImagePreview(file)}
        />
      );
    else
      return (
        <p
          style={{ color: "blue", cursor: "pointer" }}
          onClick={() => window.open(file, "_blank")}
        >
          View Attached file
        </p>
      );
  };

  truncateTextBody = (text) => {
    let toShow = text?.split("</p>")[0];
    if (toShow.length > 300) return toShow?.substr(0, 300) + "...";
    return toShow;
  };

  viewDetails = (feed) => {
    let dealID = this.state.deal?.id;
    let enterpriseID = this.props.match?.params?.enterprise;
    this.props.history.push(
      `/enterprise/${enterpriseID}/deals/${dealID}/feeds/${feed?.id}`
    );
  };
  feedCard = (feed) => {
    return (
      <div className="feed-card">
        <div>
          <div className="feed-author-container">
            <div className="author-image">
              <img src={feed?.author?.image} alt="" />
            </div>
            <div className="feed-author-details">
              <p>
                {capitalizeMe(
                  feed?.author?.first_name + " " + feed?.author?.last_name
                )}
              </p>
              <small>{getMeaningfulDate(feed?.date)}</small>
            </div>
          </div>
          <div
            style={{ cursor: "pointer" }}
            className="feed-card-head optimum-padding-right-left"
            onClick={() => this.viewDetails(feed)}
          >
            <span className="feed-card-handle">{feed.title}</span>
          </div>
          <div className="feed-card-body ">
            <div
              style={{ cursor: "pointer" }}
              onClick={() => this.viewDetails(feed)}
              className="feed-card-content optimum-padding-right-left"
              dangerouslySetInnerHTML={{
                __html: this.truncateTextBody(feed?.body),
              }}
            ></div>
            <div className="feed-card-image optimum-padding-right-left ">
              {feed?.file ? this.showFiles(feed?.file) : <></>}
            </div>
            <div></div>

            {/* <div className="divider"></div>
            <div className="feed-card-footer">
              <div className="comment-report">
                <span>Comment</span>
              </div>
            </div> */}
          </div>
        </div>

        {this.state.showActions && feed?.id === this.state.toShowActionId ? (
          <div className="more-popup-container elevate-float report-action">
            <div onClick={() => this.handleToggleAddOrEditFeed(feed)}>
              <FontAwesomeIcon icon={faPen} /> Edit info
            </div>
            <div onClick={() => this.handleShowDeleteModal(feed)}>
              <FontAwesomeIcon icon={faTrashAlt} /> Delete
            </div>
          </div>
        ) : null}

        <div
          className="show-more-container"
          onClick={() => this.showMoreActions(feed?.id)}
        >
          <FontAwesomeIcon icon={faEllipsisV} />
        </div>
      </div>
    );
  };

  prepareTweetCards = () => {
    let { deal } = this.state;
    const updates = deal?.feeds;

    let tableData = [];
    if (updates && updates.length > 0) {
      updates.forEach((item) => {
        let enterprise = getActiveEnterprise();
        const feed = {
          title: item.title,
          body: item.body,
          logo: enterprise?.logo,
          enterprise: enterprise,
          file: item?.file,
          created_at: item?.created_at,
          id: item.id,
          author: item.author,
        };
        tableData.push(this.feedCard(feed));
      });
    }

    return tableData.sort((a, b) => a?.created_a - b?.created_at);
  };

  render() {
    let { deal } = this.state;
    const updates = deal?.feeds;
    return (
      <div style={{ position: "relative" }}>
        <Note note={Descriptions.REALTIME_UPDATE} />
        <Title
          className="text-toggler"
          onClick={() => this.handleToggleAddOrEditFeed()}
        >
          Add Realtime Update <FontAwesomeIcon icon={faPlus} />
        </Title>

        <div className="report-list-root">
          {updates?.length > 0 ? (
            this.prepareTweetCards()
          ) : (
            <NoItems text="No deal realtime update added" />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    editData: state.editStatusPayload,
    deals: state.deals,
    user: state.loggedInUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleToast: toggleToastAction,
      toggleSidePane,
      toggleImagePreview: toggleImagePreviewAction,
      toggleModal: toggleModalAction,
      setViewData: setEditSetterAction,
      updateItemInredux: (current_data, new_data) =>
        dispatch(editMutationAction("DEAL", current_data, new_data)),
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateAndListDealFeeds)
);
