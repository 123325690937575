import React, { Component } from "react";
import { connect } from "react-redux";
import {FormGenerator} from '@kehillahglobal/ui'
import { getActiveEnterprise, getRandomStringKey, isEmpty } from "shared/js/utils";
import { getCountryInfo } from "../../../../shared/js/utils";
import { numberValidator } from "shared/js/fieldValidators";
class SharesInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      key: null
    }
  }

  componentDidMount() {
    if (this.props.data) {
      this.setState({
        data: this.props.data
      });
    }
  }
  static getDerivedStateFromProps(props, state) {
    if (props.data) {
      if (!state.key) {
        return {
          data: props.data,
          key: getRandomStringKey(),
        };
      }
      return {
        data: props.data,
      };
    }
    return null;
  }
  render() {
    let {data} = this.state
    const { currentEnterprise } = this.props;
    let { currency } = getCountryInfo(currentEnterprise?.currency);
    const formData = [
      {
        fieldType: FormGenerator.Fields.INCREMENT,
        label: "Available shares?",
        name: "shares",
        value: isEmpty(data?.shares) ? 0 : data?.shares,
        required: true,
        width: "100%",
        validator: (number) => this.validateNumber(number),
      },
      {
        fieldType: FormGenerator.Fields.INCREMENT,
        label: `Price per share in (${currency?.code})?`,
        value: isEmpty(data?.price_per_share) ? 0 : data?.price_per_share,
        defaultValue: 0.0,
        name: "price_per_share",
        required: true,
        width: "100%",
        validator: (number) => numberValidator(number),
      },
      {
        fieldType: FormGenerator.Fields.INCREMENT,
        label: "Carry in (%)",
        placeholder: "Example  1.5",
        name: "carry",
        value: isEmpty(data?.carry) ? 0 : data?.carry,
        required: false,
        defaultValue: 0.0,
        width: "50%",
        validator: (number) => numberValidator(number),
        helpText:
          "Carry is the percentage in profit that is allocated to the management team of the deal for their work in managing the deal.",
      },
      {
        fieldType: FormGenerator.Fields.INCREMENT,
        label: `Management Fee in (${currency?.code}).`,
        placeholder: "Example  19.0",
        name: "mgmt_fee",
        value: isEmpty(data?.mgmt_fee) ? 0 : data?.mgmt_fee,
        required: false,
        defaultValue: 0.0,
        validator: (number) => numberValidator(number),
        width: "50%",
        helpText:
          "Management fee is the fee charged by the company to manage the deal.",
      },
    ];
    return (
      <div>
        <FormGenerator
          elevation={0}
          subtitle=""
          title=""
          fields={formData}
          hideSubmitBtn={true}
          onStateChange={this.props.onStateChange && this.props.onStateChange
          }
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const currentEnterprise = state.enterprises.find(
    (item) => item?.id === getActiveEnterprise()?.id
  );
  return {
    currentEnterprise,
  };
};

export default connect(mapStateToProps, null)(SharesInfo);
