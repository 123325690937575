import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  toggleToastAction,
  toggleModalAction,
  editMutationAction,
} from "store/actions/actions";
import ApiCallHandler from "shared/js/ApiCallHandler";
import URLS from "shared/js/urls";
import Title from "components/title/Title";
import { withRouter } from "react-router-dom";

import "./Partners.css";
import ShowActions from '../../../../shared/components/showActions/ShowActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import InvitationForm from "./InvitationForm";
import {
  NoItems,
  Table,
  FormGenerator,
} from "@kehillahglobal/ui";
import Processing from '../../../../shared/components/processing/Processing';
import { PARTNER_TYPES } from '../../../../shared/js/typeConstants';
import { convertNumberToShortForm, getCountryInfo } from "shared/js/utils";

class DealPartners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      showActions: false,
      loading: false,
      deal: {},
    };
  }
  componentDidMount() {
    let { id } = this.props.match.params;
    if (id) {
      let deal = this.props.deals.find((deal) => deal.id === id);
      this.setState({ deal });
    }
  }

  static getDerivedStateFromProps(props) {
    let { id } = props.match.params;
    if (id) {
      let deal = props.deals.find((deal) => deal.id === id);
      return {
        deal,
      };
    }
    return;
  }
  calculatesAvailableShares = () => {
    let {deal } = this.state;
    let totalShares = 0;
    if (deal?.partnerships && deal?.partnerships?.length) {
      deal.partnerships.forEach((deal) => {
        totalShares += deal.shares;
      });
    }
    if (deal?.invitations?.length) {
      deal.invitations
        ?.filter((item) => item?.has_responded === null)
        ?.forEach((invitation) => {
          totalShares += invitation.shares;
        });
    }

    return Number(totalShares);
  };

  toggleShowMoreOptions = (item) => {
    if (!item?.id) {
      this.setState({
        showActions: false,
        id: "",
      });
    } else {
      this.setState({
        showActions: true,
        id: item?.id,
      });
    }
  };

  showReceipt = (item) => {
    if (item?.payment_receipt) {
      return (
        <p
          style={{ color: "blue" }}
          onClick={() => window.open(item?.payment_receipt, "_blank")}
        >
          View Receipt
        </p>
      );
    } else {
      return <p>No Receipt</p>;
    }
  };

  isEnterprisePartner = (item) => {
    if (item?.partner_type === PARTNER_TYPES.ENTERPRISE) {
      return true;
    } else return false;
  };

  getPartnerPercentage = (item) => {
    let { deal } = this.state;
    let shares = item?.shares;
    let totalShares = deal.shares || 1;
    return parseFloat((shares / totalShares) * 100).toFixed(2);
  };

  prepareTableData = () => {
    let {currentEnterprise } = this.props;
    let { deal } = this.state;
    let deals = deal?.partnerships;

    let currency = getCountryInfo(currentEnterprise?.currency)?.currency;

    if (deals?.length > 0) {
      return deals.map((item, index) => [
        <p>{index + 1}</p>,
        <p onClick={() => this.showEditModal(item)}>
          {this.isEnterprisePartner(item)
            ? item?.enterprise?.name
            : item?.user?.first_name}
        </p>,
        <p>{item?.shares}</p>,
        <p>
          {currency.symbol}{" "}
          {convertNumberToShortForm(item?.shares * deal?.price_per_share)}
        </p>,
        <p>{this.getPartnerPercentage(item)} %</p>,
        <p>
          {this.isEnterprisePartner(item)
            ? item?.enterprise?.email
            : item?.user?.email}
        </p>,
        this.showReceipt(item),
        <p>
          {item?.is_approved ? (
            "Approved"
          ) : (
            <ShowActions
              onApproved={this.handleApproveInvestor}
              onDeclined={this.handleRejectInvestor}
              item={item}
              id={this.state.id}
              toggleShowMoreOptions={this.toggleShowMoreOptions}
              show={this.state.showActions}
            />
          )}
        </p>,
      ]);
    }
    return [];
  };

  renderTable = () => {
    return (
      <>
        {this.state.deal?.partnerships?.length > 0 ? (
          <Table
            columns={[
              "#",
              "Name",
              "Shares",
              "Amount",
              "Percentage",
              "Email",
              "Payment Receipt",
              "status",
            ]}
            data={this.prepareTableData()}
            hoverAnimation={false}
          />
        ) : (
          <div>
            <NoItems text="No Partners" />
          </div>
        )}
      </>
    );
  };

  updateDealInReduxState = (data) => {
    let { deals } = this.props;
    let {deal} = this.state
    let filteredDeals = deal?.partnerships?.filter(
      (item) => item?.id !== data?.id
    );
    filteredDeals.push(data);
    deal.partnerships = filteredDeals;
    this.props.updateRedux(deals, deal);
  };

  handleApproveInvestor = async (item) => {
    this.setState({ loading: true });
    const res = await ApiCallHandler.send(URLS.APPROVE_DEAL_PARTNER, "POST", {
      id: item.id,
    });
    if (res.success) {
      this.setState({ loading: false });
      this.updateDealInReduxState(res?.data);
      this.props.toggleToast({
        show: true,
        message: "Partner Has Been Successfully Approved",
        type: "success",
      });
    } else {
      this.setState({ loading: false });
      this.props.toggleToast({
        show: true,
        message: "Approval Failed. Please Try again Later",
        type: "failure",
      });
    }
  };

  handleRejectInvestor = async (item) => {
    this.setState({ loading: true });
    const res = await ApiCallHandler.send(URLS.REJECT_DEAL_PARTNER, "POST", {
      id: item.id,
    });

    if (res?.success) {
      this.setState({ loading: false });
      this.updateDealInReduxState(res?.data);
      this.props.toggleToast({
        show: true,
        message: "Partner Has Been Successfully Disapproved",
        type: "success",
      });
    } else {
      this.setState({ loading: false });
      this.props.toggleToast({
        show: true,
        message: "Disapproval Failed. Please Try again Later",
        type: "failure",
      });
    }
  };
  // update-array

  handleUpdateDealInfo = (data, reset, item) => {
    this.setState({ loading: true });
    const toSend = {
      payment_date: data?.payment_date,
      has_received_payment:
        data?.has_received_payment === "Yes" ? "True" : "False",
      id: item?.id,
    };
    ApiCallHandler.send(URLS.UPDATE_PARTNER_DEALS, "POST", toSend).then(
      (res) => {
        this.setState({ loading: false });
        if (res.success) {
          this.updateDealInReduxState(res?.data[0]);
          this.props.toggleModal({ show: false });
          this.props.toggleToast({
            show: true,
            message: "Partner data Has Been Successfully Updated",
            type: "success",
          });
        } else {
          this.props.toggleToast({
            show: true,
            message: "Approval Failed. Please Try again Later",
            type: "failure",
          });
        }
      }
    );
  };
  dealInfoForm = (item) => {
    let Fields = FormGenerator.Fields
    return (
      <FormGenerator
        elevation={1}
        fields={[
          {
            fieldType: Fields.RADIOGROUP,
            label: "Have you received Payment?",
            name: "has_received_payment",
            placeholder: "Name",
            data: ["Yes", "No"],
            value: item?.has_received_payment ? "Yes" : "No" || "No",
          },
          {
            fieldType: Fields.DATE,
            label: "Date Payment Received",
            name: "payment_date",
            placeholder: "When was payment received",
            value: item?.id ? item?.payment_date?.split("T")[0] : "",
          },
        ]}
        onSubmit={(data, reset) => this.handleUpdateDealInfo(data, reset, item)}
        formSubmitBtnText={"Update Info"}
        title=""
        subtitle=""
      />
    );
  };

  showEditModal = (item) => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `Update Partner Information`,
        children: this.dealInfoForm(item),
        themeColor: "var(--app-theme)",
        size: "medium",
      },
    });
  };

  render() {
    return (
      <>
        <div>
          <Title
            className="text-toggler"
            onClick={() =>
              this.props.toggleModal({
                show: true,
                props: {
                  title: `Partner Creation Form`,
                  children: (
                    <InvitationForm
                      deal={this.state.deal}
                      totalShares={this.calculatesAvailableShares}
                      switchTabs={this.props.switchTabs}
                    />
                  ),
                  themeColor: "var(--app-theme)",
                  size: "medium",
                },
              })
            }
          >
            <FontAwesomeIcon icon={faPlus} /> Add Partner
          </Title>
        </div>
        <br />
        {this.renderTable()}
        {this.state.loading ? <Processing /> : null}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    deals: state.deals,
    currentEnterprise: state.currentEnterprise
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleModal: toggleModalAction,
      toggleToast: toggleToastAction,
      updateRedux: (current_data, new_data) =>
        dispatch(editMutationAction("DEAL", current_data, new_data)),
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DealPartners)
);
