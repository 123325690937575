import React, { useState } from "react";
import ApiCallHandler from "../../../../shared/js/ApiCallHandler";
import URLS from "../../../../shared/js/urls";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleModalAction,
  toggleToastAction,
  editMutationAction,
} from "../../../../store/actions/actions";
import { emailValidator, numberValidator } from "shared/js/fieldValidators";
import {
  FormGenerator,
} from "@kehillahglobal/ui";
import { parseBool } from "shared/js/utils";


export default function InvitationForm(props) {
  const dispatch = useDispatch();
  const deals = useSelector((state) => state.deals);
  const [errMsg, setErrMsg] = useState("");

  const { editData } = props;
  const soldShares = props.totalShares();
  const total = props.deal?.shares;
  const available = total - soldShares;

  const closeModal = () => {
    dispatch(toggleModalAction({ show: false }));
  };

  const showResponse = (res, reset) => {
     closeModal();
    if (res?.success) {
      dispatch(editMutationAction("DEAL", deals, res.data));
      dispatch(
        toggleToastAction({
          show: true,
          message: "Email Invitation Sent Successfully",
          type: "success",
        })
      );

      return reset();
    } else {
      dispatch(
        toggleToastAction({
          show: true,
          message: res?.error,
          type: "failure",
        })
      );
     
    }
  };

  const shipCreatePartnerDataToBackend = async (data, reset) => {
    const res = await ApiCallHandler.send(
      URLS.CREATE_PARTNER_DEALS,
      "POST",
      data
    );
    if (res && res?.success) {

      let { deal } = props;
      let partnerships  = props?.deal?.partnerships || [];
      let updated = [res?.data, ...partnerships];
      deal.partnerships = updated;
      dispatch(editMutationAction("DEAL", deals, res.data));
        dispatch(
          toggleToastAction({
            show: true,
            message: "Partnership Created Successfully",  
            type: "success",
          })
        );
    }
      closeModal();
  };

  const sendDataToBackend = async (toSend, reset) => {
    
    const res = await ApiCallHandler.send(
      URLS.SEND_DEAL_INVITATION_EMAIL,
      "POST",
      toSend
    );

 

    if (res) {
      showResponse(res, reset);
      if (props.switchTabs) {
        props.switchTabs("invited-investors-for-deal");
      }
    }
  };

  const emailUnique = (opp, email) => {
    if (opp?.invitations === null) return true;
    return opp?.invitations?.filter((x) => x.email === email).length === 0;
  };

  const handleCreate = async (datum, reset) => {
    let { deal } = props;
    const isEmailUnique = emailUnique(deal, datum.email);
    let is_valid = emailValidator(datum?.email);
    let current;

    if (editData?.id) {
      current = parseFloat(available) + parseFloat(editData.shares);
    } else {
      current = parseFloat(available);
    }
    if (current <= 0 || current - datum?.shares < 0) {
      setErrMsg(
        `You have or about exceeding the maximum allocated shares. Only ${available} is available.`
      );
      return;
    }

    setErrMsg("");

    const shouldSendInvitation = parseBool(datum?.send_invitation);

    let toSend = {
      name: datum.name,
      email: datum.email,
      deal_id: deal.id,
      shares: datum.shares,
      action: "SEND",
      url: window.location.protocol + "//" + window.location.host + "/",
      invitation_id: "",
    };
    if (shouldSendInvitation) {
      toSend = {
        name: datum.name,
        email: datum.email,
        deal_id: deal.id,
        shares: datum.shares,
        action: "CREATE_PARTNER_WITH_NO_INVITATION",
      };
    }
    if (is_valid) {
      if (editData?.id) {
        if (shouldSendInvitation) {
          toSend.invitation_id = editData?.id;
          shipCreatePartnerDataToBackend(toSend, reset);
        } else {
          toSend.invitation_id = editData?.id;
          sendDataToBackend(toSend, reset);
        }
      } else {
        if (isEmailUnique) {
          if (shouldSendInvitation) {
            shipCreatePartnerDataToBackend(toSend, reset);
          } else {
            sendDataToBackend(toSend, reset);
          }
        } else {
          showErrorResponse(
            "ERROR!!. This email already exists. Please use a different email"
          );
        }
      }
    } else {
      showErrorResponse(
        "ERROR!!. This email is invalid. Please use a different email"
      );
    }
  };

  const showErrorResponse = (err) => {
    dispatch(toggleModalAction({ show: false }));
    dispatch(
      toggleToastAction({
        show: true,
        message: err,
        type: "failure",
      })
    );
  };
let Fields = FormGenerator.Fields
  return (
    <>
      {errMsg ? (
        <p id="available-shares-notifier">{errMsg}</p>
      ) : (
        <p id="available-shares-notifier">Available Shares: {available}</p>
      )}
      <FormGenerator
        elevation={0}
        fields={[
          {
            fieldType: Fields.INPUT,
            label: "Name",
            name: "name",
            placeholder: "Name",
            required: true,
            value: editData?.id ? editData?.name : "",
          },
          {
            fieldType: Fields.INPUT,
            label: "Email",
            name: "email",
            placeholder: "Email",
            required: true,
            value: editData?.id ? editData?.email : "",
            validator: (value) => emailValidator(value),
          },

          {
            fieldType: Fields.INPUT,
            label: "Number Of Shares",
            name: "shares",
            placeholder: "Number Of Shares",
            required: true,
            value: editData?.id ? editData?.shares : "",
            validator: (value) => numberValidator(value)
          },
          {
            fieldType: Fields.CHECKBOX,
            label:
              "Check this box if you want this partner to be added direct without sending invitation",
            name: "send_invitation",
            required: false,
            removeCheckboxLabel: true,
            helpText:
              "If you check this box, the partner will be added directly without sending invitation",
          },
        ]}
        onSubmit={handleCreate}
        formSubmitBtnText={"Submit"}
        title=""
        subtitle=""
      />
    </>
  );
}
