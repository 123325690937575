import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {FormGenerator} from "@kehillahglobal/ui";
import { editMutationAction } from "../../../store/actions/actions";
import ApiCallHandler from "../../../shared/js/ApiCallHandler";
import URLS from "../../../shared/js/urls";
import { getActiveEnterprise } from "shared/js/utils";

class Address extends Component {
  constructor(props) {
    super(props);
    this.addressRef = React.createRef();
  }

  componentDidMount() {
    let { onMount } = this.props;
    if (onMount) {
      onMount(() => this.saveDataToDB());
    }
  }

  saveDataToDB = async () => {
    let { enterprise, enterprises } = this.props;
    const data = this.addressRef.current?.onClickFromOutside();
    let res;
    if (data) {
      data.id = enterprise?.id;
      res = await ApiCallHandler.send(URLS.UPDATE_ENTERPRISES, "POST", data);
      if (res && res?.success) {
        this.props.editRecord(enterprises, res?.data[0]);
      }
    }
  };

  render() {
    let { enterprise } = this.props;
    const formData = [
      {
        fieldType: FormGenerator.Fields.INPUT,
        label: "Address Line",
        name: "address_line_one",
        placeholder: "Address Line 1",
        required: true,
        value: enterprise?.id ? enterprise?.address_line_one : "",
      },
      {
        fieldType: FormGenerator.Fields.INPUT,
        label: "City",
        name: "city",
        placeholder: "City...",
        required: true,
        value: enterprise?.id ? enterprise?.city : "",
      },
      {
        fieldType: FormGenerator.Fields.INPUT,
        label: "State",
        name: "state",
        placeholder: "state",
        value: enterprise?.id ? enterprise?.state : "",
      },
    ];

    return (
      <div>
        {" "}
        <FormGenerator
          elevation={0}
          subtitle=""
          title=""
          formSubmitBtnText={"Save Changes"}
          fields={formData}
          hideSubmitBtn={true}
          ref={this.addressRef}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const enterpriseLoc = getActiveEnterprise();
  return {
    enterprise: state.enterprises?.filter(
      (item) => item.id === enterpriseLoc.id
    )[0],
    enterprises: state.enterprises,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      editRecord: (current_data, new_data) =>
        dispatch(
          editMutationAction("ENTERPRISE_MUTATION", current_data, new_data)
        ),
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Address);
