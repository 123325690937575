import React, { useState } from 'react'
import { Stepper } from "@kehillahglobal/ui";
import EnterpriseInfo from './steps/EnterpriseInfo';
import EnterpriseContactInfo from './steps/EnterpriseContactInfo';
import EnterpriseIncorporationDocuments from './steps/EnterpriseIncorporationDocuments';
import Address from './steps/Address';
import { faInfo, faLocationArrow, faFileContract, faAddressBook } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';


export default function EnterpriseVerification() {
 
  const [submitFunctionForEnterpriseInfo, setSubmitFunctionForEnterpriseInfo] = useState("")
  const [submitFunctionForContactInfo, setSubmitFunctionForContactInfo] = useState("")
  const [submitFunctionForDocuments, setSubmitFunctionForDocuments] = useState("")
  const [submitFunctionForAddress, setSubmitFunctionForAddress] = useState("")

  let history = useHistory()

  const onCancel = ()=>{
    history.goBack()
  }
  const onNext = (step)=>{
    if(step ===0){
      submitFunctionForEnterpriseInfo();
    }
    else if(step ===1){
      submitFunctionForAddress();
    }
    else if(step ===2){
      submitFunctionForContactInfo();
    }
    else if(step ===3){
      submitFunctionForDocuments();
    }
  }
   const steps = [
     {
       name: "Enterprise Info",
       component: (
         <EnterpriseInfo
           onMount={(onSubmit) =>
             setSubmitFunctionForEnterpriseInfo(() => onSubmit)
           }
         />
       ),
       icon: faInfo,
     },
     {
       name: "Address",
       component: (
         <Address
           onMount={(onSubmit) => setSubmitFunctionForAddress(() => onSubmit)}
         />
       ),
       icon: faLocationArrow,
     },
     {
       name: "Contact",
       component: (
         <EnterpriseContactInfo
           onMount={(onSubmit) =>
             setSubmitFunctionForContactInfo(() => onSubmit)
           }
         />
       ),
       icon: faAddressBook,
     },
     {
       name: "Incorporation Documents",
       component: (
         <EnterpriseIncorporationDocuments
           onMount={(onSubmit) => setSubmitFunctionForDocuments(() => onSubmit)}
         />
       ),
       icon: faFileContract,
     },
   ];
     return (
       <div>
         <Stepper variant={"horizontal"} steps={steps} onNext={onNext}  onCancel={onCancel}/>
       </div>
     );
}
