import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toggleToastAction, toggleModalAction, editMutationAction } from "store/actions/actions";
import ApiCallHandler from "shared/js/ApiCallHandler";
import URLS from "shared/js/urls";

import { withRouter } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Title from "components/title/Title";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Partners.css";
import InvitationForm from "./InvitationForm";
import ShowActions from "../../../../shared/components/showActions/ShowActions";
import ModalContent from "shared/components/modal-content/ModalContent";
import { Table, NoItems } from "@kehillahglobal/ui";

class PartnerInvitations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resending: false,
      id: "",
      showActions: false,
      deal:{}
    };
  }
  componentDidMount() {
    let { id } = this.props.match.params;
    if (id) {
      let deal = this.props.deals.find((deal) => deal.id === id);
      this.setState({ deal });
    }
  }

  static getDerivedStateFromProps(props) {
    let { id } = props.match.params;
    if (id) {
      let deal = props.deals.find((deal) => deal.id === id);
      return {
        deal,
      };
    }
    return;
  }

  calculatesAvailableShares = () => {
    let { deal } = this.state;
    let totalShares = 0;
    if (deal?.partnerships && deal?.partnerships?.length) {
      deal.partnerships.forEach((deal) => {
        totalShares += deal.shares;
      });
    }

    if (deal?.invitations?.length) {
      deal.invitations
        ?.filter((item) => item?.has_responded === null)
        ?.forEach((invitation) => {
          totalShares += invitation.shares;
        });
    }

    return Number(totalShares);
  };

  resendInvitation = async (item) => {
    this.setState({ resending: true });
    let { deal } = this.state;

    const dataToSend = {
      invitation_id: item.id,
      name: item.name,
      email: item.email,
      deal_id: deal.id,
      url: window.location.protocol + "//" + window.location.host + "/",
      action: "RESEND",
    };
    const res = await ApiCallHandler.send(
      URLS.SEND_DEAL_INVITATION_EMAIL,
      "POST",
      dataToSend
    );
    if (res.success) {
      this.setState({ resending: false });
      this.props.toggleToast({
        show: true,
        message: "Email Invitation Resent Successfully",
        type: "success",
      });
    } else {
      this.setState({ resending: false });
      this.props.toggleToast({
        show: true,
        message:
          "Sorry something happened, couldn't resend invitation. please try again later. Thanks",
        type: "failure",
      });
    }
  };

  showDeleteModal = (item) => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `Delete Invitation Confirmation`,
        children: (
          <ModalContent content={"Would you like to delete this record ?"} />
        ),
        size: "medium",
        themeColor: "maroon",
        okay: {
          text: "Yes",
          function: () => this.handleDeleteInvitation(item),
        },
      },
    });
  };

  updateDealInRedux = (data) => {
    let { deals } = this.props;
    let {deal} = this.state;
    let newInvitations = deal.invitations.filter(
      (invitation) => invitation?.id !== data.id
    );
    deal.invitations = newInvitations;
    this.props.updateReduxStore(deals, deal);
  };

  handleDeleteInvitation = (item) => {
    ApiCallHandler.send(URLS.ARCHIVE_DEAL_INVITATION, "POST", {
      id: item.id,
    }).then((res) => {
      if (res?.success) {
        this.props.toggleToast({
          show: true,
          message: "Invitation Deleted Successfully",
          type: "success",
        });
        this.updateDealInRedux(res.data);
      } else {
        this.props.toggleToast({
          show: true,
          message:
            "Sorry something happened, couldn't delete invitation. please try again later. Thanks",
          type: "failure",
        });
      }
    });
  };
  toggleShowMoreOptions = (item) => {
    if (!item?.id) {
      this.setState({
        showActions: false,
        id: "",
      });
    } else {
      this.setState({
        showActions: true,
        id: item?.id,
      });
    }
  };
  prepareTableData = () => {
    let { deal } = this.state;
    let { invitations } = deal;

    if (invitations?.length > 0) {
      return invitations.map((item) => [
        <p>{item?.name}</p>,
        <p>{item?.email}</p>,
        <p>{item?.shares}</p>,
        <p>
          {item?.has_accepted ? (
            <span style={{ color: "green" }}>Yes</span>
          ) : (
            <span style={{ color: "maroon" }}>No</span>
          )}
        </p>,
        <p>
          {item?.has_responded ? (
            <span style={{ color: "green" }}>Yes</span>
          ) : (
            <span style={{ color: "maroon" }}>No</span>
          )}
        </p>,
        // item?.has_accepted ? (
        //   <div></div>
        // ) : (
          <ShowActions
            onEdit={this.handleAddOrEditInvitation}
            onResend={this.resendInvitation}
            onDelete={this.showDeleteModal}
            item={item}
            id={this.state.id}
            toggleShowMoreOptions={this.toggleShowMoreOptions}
            show={this.state.showActions}
            loading={this.state.resending}
          />
        // ),
      ]);
    }
    return [];
  };

  handleAddOrEditInvitation = (item = {}) => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `Partner ${item?.id ? " Details Update" : "Creation"}`,
        children: (
          <InvitationForm
            deal={this.state.deal}
            totalShares={this.calculatesAvailableShares}
            editData={item}
          />
        ),
        themeColor: "var(--app-theme)",
        size: "medium",
      },
    });
  };

  renderTable = () => {
    return (
      <div style={{ marginBottom: 20 }}>
        <Title>Invitations Sent</Title>
        {this.state?.deal?.invitations?.length > 0 ? (
          <Table
            columns={["Name", "Email", "Shares", "Accepted", "Responded", "\t"]}
            data={this.prepareTableData()}
            hoverAnimation={false}
          />
        ) : (
          <div>
            <NoItems text="No Invitations Sent" />
          </div>
        )}
      </div>
    );
  };

  render() {
    return (
      <>
        <Title
          className="text-toggler"
          onClick={() => this.handleAddOrEditInvitation()}
        >
          <FontAwesomeIcon icon={faPlus} /> Add Partner
        </Title>
        <br />
        {this.renderTable()}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    deals: state.deals,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleModal: toggleModalAction,
      toggleToast: toggleToastAction,
      updateReduxStore: (current_data, new_data) =>
        dispatch(editMutationAction("DEAL", current_data, new_data)),
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PartnerInvitations)
);
