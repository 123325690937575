import React from "react";
import DealsCard from "../deals-card/DealsCard";
import {NoItems} from "@kehillahglobal/ui";
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { DEAL_STATUS } from 'shared/js/typeConstants';
import { useParams } from "react-router-dom";

export default function CompletedDealList(props) {
  const deals = useSelector((state) =>
    state.deals?.filter((opp) => opp?.status === DEAL_STATUS.COMPLETED)
  );

  const history = useHistory();
  const params = useParams()

  const handleViewDetails = (item) => {
    let enterpriseID = params?.enterprise;
    history.push(`/enterprise/${enterpriseID}/deals/` + item.id);
  };

  return (
    <div className="">
      <div className="user-deal-items-wrapper">
        {deals?.length > 0 ? (
          deals.map((deal, index) => {
            return (
              <DealsCard
                key={index.toString()}
                deal={deal}
                handleViewDetails={handleViewDetails}
              />
            );
          })
        ) : (
          <div id="no-deals-container">
            <NoItems
              text={`You do not have any archived deal. Click "Add New Deal" to create one.`}
            />
          </div>
        )}
      </div>
    </div>
  );
}
