import React, { Component } from "react";
import "./DealsCard.css";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faBolt,
  faEllipsisV,
  faPen,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import {LinearProgressBar} from "@kehillahglobal/ui";
import { getActiveEnterprise } from "shared/js/utils";
import { connect } from 'react-redux';
import { getCountryInfo, isEmpty } from '../../../shared/js/utils';
import { DEAL_STATUS, PARTNER_TYPES } from '../../../shared/js/typeConstants';
import ApiCallHandler from "shared/js/ApiCallHandler";
import URLS from "shared/js/urls";
import { editMutationAction } from "store/actions/actions";
import { bindActionCreators } from "redux";
import Processing from "shared/components/processing/Processing";
class DealsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showActions: false,
      toShowActionId: "",
      isLoading:false
    };
  }

  showDeleteItem = (id = null) => {
    let { showActions } = this.state;
    if (showActions) {
      this.setState({ showActions: false, toShowActionId: null });
    } else {
      this.setState({ showActions: true, toShowActionId: id });
    }
  };

  convertDateToNumberOfMonths = (date) => {
    if (!date) return 0;
    let today = new Date();
    let future = new Date(date);
    const diffTime = future - today;
    if (diffTime <= 0) return 0;
    const months = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return months;
  };

  calculatesAvailableShares = (deals) => {
    let { deal } = this.props;

    if (deals && deals.length) {
      let totalShares = 0;
      deals.forEach((deal) => {
        totalShares += deal.shares;
      });
      return deal?.shares - totalShares;
    }
    return deal?.shares;
  };

  showNotableInvestors = (deals) => {
    if (deals && deals.length) {
      let investors = [];
      deals?.slice(0, 5)?.forEach((deal) => {
        
        if (deal?.partner_type === PARTNER_TYPES.ENTERPRISE) {
          investors.push(deal?.enterprise?.name);
        }
        investors.push(deal?.user?.first_name);
      });
      return investors.join(", ");
    }
    return "No partners yet";
  };
  truncateTextBody = (text) => {
    let toShow = text?.split("</p>")[0];
    let str = toShow?.substring(0, 300);
    if (isEmpty(str)) return "No description added";
    if (str.length < 300) return str;
    return str + "...";
  };

  totalSharesBought = (deals) => {
    let total = 0;
    if (deals && deals.length) {
      deals.forEach((deal) => {
        total += deal.shares;
      });
    }
    return total;
  };

  getSharesPercentage = () => {
    let { deal } = this.props;
    let totalShares = deal?.shares > 0 ? deal?.shares : 1;
    let availableShares = deal?.deals?.length
      ? this.totalSharesBought(deal?.deals)
      : 0;
    let per = (availableShares / totalShares) * 100;
    return per.toFixed(2);
  };

  handleGoLive = (deal )=>{
     this.setState({ isLoading: true });
     const { deals } = this.props;
     ApiCallHandler.send(URLS.UPDATE_DEALS, "POST", {
       id: deal?.id,
       status: DEAL_STATUS.LIVE,
     }).then((res) => {
       this.setState({ isLoading: false });
       if (res?.success) {
         this.props.updateDeals(deals, res?.data[0]);
         this.props.history.push(`/enterprise/${getActiveEnterprise()?.id}/deals`);
       } else {
         this.props.showToast({
           show: true,
           message: "An error occurred. Try again",
           type: "failure",
         });
       }
     });
  }
  render() {
    let { deal, handleDeleteRecord, handleEdit, currentEnterprise } =
      this.props;
    let { currency } = getCountryInfo(currentEnterprise?.currency);
    return !this.state.isLoading ? (
      <>
        <div
          id="invest-card-wrapper"
          style={{ position: "relative" }}
          className="invest-card-wrapper"
        >
          {this.state.showActions && deal?.id === this.state.toShowActionId ? (
            <div className="more-popup-container elevate-float">
              <div
                onClick={() => {
                  this.handleGoLive(deal);
                  this.showDeleteItem();
                }}
              >
                <FontAwesomeIcon icon={faBolt} /> Go Live
              </div>
              <div
                onClick={() => {
                  handleEdit(deal);
                  this.showDeleteItem();
                }}
              >
                <FontAwesomeIcon icon={faPen} /> Edit info
              </div>
              <div onClick={() => handleDeleteRecord(deal)}>
                <FontAwesomeIcon icon={faTrashAlt} /> Delete
              </div>
            </div>
          ) : null}
          {!handleEdit ? null : (
            <div
              className="show-more-container"
              onClick={() => this.showDeleteItem(deal?.id)}
            >
              <FontAwesomeIcon icon={faEllipsisV} />
            </div>
          )}

          <div
            id="invest-card-wrapper"
            onClick={() => this.props.handleViewDetails(deal)}
          >
            <div id="name-and-desc-container">
              <div className="deals-card-image-and-name-container">
                {deal?.image && (
                  <div className="deals-card-image-container">
                    <img src={deal?.image} alt="img" />
                  </div>
                )}
                <div>
                  <h3>{deal?.name}</h3>
                </div>
              </div>
              <div
                style={{ cursor: "pointer" }}
                dangerouslySetInnerHTML={{
                  __html: this.truncateTextBody(deal?.about),
                }}
              ></div>
            </div>
          </div>
          <div
            id="progress-container"
            onClick={() => this.props.handleViewDetails(deal)}
          >
            <p>Shares progress</p>
            <LinearProgressBar
              borderRadius={20}
              containerColor="rgba(173, 216, 230, 1)"
              progressColor="var(--app-theme)"
              progressPercentage={this.getSharesPercentage()}
              progressThickness={10}
              style={{ width: "100%", margin: "auto", position: "relative" }}
            />
          </div>
          <div
            id="invest-money-container"
            onClick={() => this.props.handleViewDetails(deal)}
          >
            <div id="subscriptions-container">
              <div>
                <span>Shares Available</span>
                <p>{this.calculatesAvailableShares(deal?.deals)}</p>
              </div>
              <div>
                <span>Per Share</span>
                <p>
                  {currency?.symbol} {deal?.price_per_share}
                </p>
              </div>

              <div id="duration-container">
                <span>Closes In</span>
                <p>{this.convertDateToNumberOfMonths(deal?.close_date)} Days</p>
              </div>
            </div>

            <div id="notable-investors-container">
              <span>Notable Partners</span>
              <p>{this.showNotableInvestors(deal?.partnerships)}</p>
            </div>

            <div id="return-percentages-container">
              <div>
                <span>Carry</span>
                <p>{deal?.carry || parseFloat(0)} %</p>
              </div>
              <div>
                <span>Mgm. Fee</span>
                <p>
                  {currency?.symbol} {deal?.mgmt_fee || parseFloat(0)}
                </p>
              </div>
              <div>
                <span>Admin Fee</span>
                <p>{deal?.admin_fee || parseFloat(0)} %</p>
              </div>
            </div>
          </div>

          <div id="footer-btns-container">
            <div
              id="view-more-btn-container"
              onClick={() => this.props.handleViewDetails(deal)}
            >
              <p>View More</p>
              <FontAwesomeIcon icon={faArrowRight} />
            </div>
          </div>
        </div>
      </>
    ) : (
      <Processing text={"Going Live"} />
    );
  }
}

const mapstateToProps = (state) => {
  const currentEnterprise = state.enterprises?.find(item=>item?.id === getActiveEnterprise()?.id);
  return {
    currentEnterprise,
    deals:state.deals
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      updateDeals: (current_data, new_data) =>
        dispatch(editMutationAction("DEAL", current_data, new_data)),
    },
    dispatch
  ),
});

export default withRouter(
  connect(mapstateToProps, mapDispatchToProps)(DealsCard)
);
