import React from "react";

export default function Note({ note }) {
  if (!note) {
    return<></>;
  }
  return (
    <div className="tab-note-container">
      <p>{note}</p>
    </div>
  );
}
