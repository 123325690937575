import React, { Component } from "react";
import { Sidebar } from "@kehillahglobal/ui";
import "./PageWrapper.css";
import {  BOTTOM_MENU_BASIC_ADMIN, BOTTOM_MENU_SUPER_ADMIN, MENU } from "./values";
import { bindActionCreators } from "redux";
import {
  setCurrentEnterpriseAction,
  toggleSidePane,
} from "store/actions/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ApiCallHandler from "shared/js/ApiCallHandler";
import URLS from "shared/js/urls";
import { BASIC } from "shared/js/typeConstants";
const SHRINK_SIDE_RATIO = 71;
const EXPANDED_SIDE_RATIO = 271;

class PageWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = { isUntouched: true, key: null };
    this.handleSidebarStateChange = this.handleSidebarStateChange.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (
      state.isUntouched &&
      (state.sideRatio !== props.sideRatio ||
        state.contentRatio !== props.contentRatio)
    ) {
      return {
        sideRatio: props.sideRatio,
        contentRatio: props.contentRatio,
        isUntouched: false,
      };
    }

    return null;
  }
  getContentRatio() {
    var { sideRatio } = this.state;
    sideRatio = sideRatio || 0;
    var contentRatio = 10 - sideRatio;
    return [sideRatio, contentRatio];
  }

  handleSidebarStateChange(state) {
    if (!state.shrink && this.state.sideRatio !== EXPANDED_SIDE_RATIO)
      this.setState({ sideRatio: EXPANDED_SIDE_RATIO });
    else if (state.shrink && this.state.sideRatio !== SHRINK_SIDE_RATIO)
      this.setState({ sideRatio: SHRINK_SIDE_RATIO });
  }

  getUserAndCompanyInfo = () => {
    let { enterprise, user } = this.props;

    let role = enterprise?.role?.role?.toLowerCase()?.match("basic")
      ? "Basic Admin"
      : enterprise?.role?.role?.toLowerCase()?.match("super")
      ? "Super Admin"
      : null;

    let name =
      user?.first_name || enterprise?.role?.user || enterprise?.owner?.user;
    let image =
      user?.image || enterprise?.role?.image || enterprise?.owner?.image;
    return {
      userName: name,
      role: role,
      companyName: enterprise?.name || "COMPANY",
      companyLogo: enterprise?.logo,
      userImage: image || "https://i.ibb.co/0DRHM5M/user5.jpg",
    };
  };

  render() {
    const { sideRatio, key } = this.state;
    const { sidePane, enterprises, user, enterprise } = this.props;

    const isBasicAdmin = enterprise?.role?.role
      ?.toLowerCase()
      ?.includes(BASIC?.toLowerCase());

      const BOTTOM_MENU = isBasicAdmin
        ? BOTTOM_MENU_BASIC_ADMIN
        : BOTTOM_MENU_SUPER_ADMIN;

    return (
      <div
        className="root-wrapper"
        style={{
          "--side-ratio": `${sideRatio}px`,
        }}
        key={key}
      >
        <div>
          <Sidebar
            menu={MENU}
            onShrinkBtnClick={() =>
              this.setState({ sideRatio: SHRINK_SIDE_RATIO })
            }
            accounts={enterprises}
            bottomMenu={BOTTOM_MENU}
            onStateChange={this.handleSidebarStateChange}
            userDetails={this.getUserAndCompanyInfo()}
            onItemSelect={(item) => {
              setCurrentEnterpriseAction(item);
              window.location.href = `/enterprise/${item?.id}/dashboard`;
            }}
            onTabItemClick={(e, { link }) => {
              if (!link) {
                const iAmSureIWantToLogOut = window.confirm(
                  "Are you sure you want to sign out?"
                );
                if (iAmSureIWantToLogOut) {
                  ApiCallHandler.send(URLS.LOGOUT, "POST", {}).then((res) => {
                    if (res.success) {
                      window.location.href = "/";
                    }
                  });
                }
              }
              this.props.history.push(link);
            }}
            verified={user?.is_verified ? true : false}
          />
        </div>
        <div className="children-container">
          {sidePane?.on && (
            <div className="side-pane elevate-float animate-side-pane"></div>
          )}
          <div></div>
          <div style={{ marginTop: "2rem", marginBottom: "5rem" }}>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

PageWrapper.defaultProps = {
  sideRatio: SHRINK_SIDE_RATIO,
};

const mapStateToProps = (store) => {
  return {
    sidePane: store.sidePane,
    enterprise: store.currentEnterprise,
    user: store.loggedInUser,
    enterprises: store.enterprises,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleSidePane,
      setCurrentEnterprise: setCurrentEnterpriseAction,
    },
    dispatch
  );
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PageWrapper)
);
