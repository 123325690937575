import React, { useEffect, useState } from "react";
import "./DealFeedDetail.css";
import { capitalizeMe, getFileType, getMeaningfulDate } from "../../../../../shared/js/utils";
import Header from "../../../../../components/header/Header";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BackButton from "shared/components/back-btn/BackButton";
import Processing from "shared/components/processing/Processing";

export default function DealFeedDetail(props) {
  const [dealFeed, setDealFeed] = useState({});
  let { id, deal } = useParams();

  const currentDeal = useSelector(
    (state) =>
      state?.deals.filter(
        (item) => item?.id === deal
      )[0]
  );

  useEffect(() => {
    const dealFeed = currentDeal?.feeds?.filter(
      (dealFeed) => dealFeed?.id ===id
    )[0];
    setDealFeed(dealFeed);
  }, [currentDeal, id]);

  const showFiles = (file) => {
    const IMAGE_TYPES = ["png", "jpeg", "jpg"];
    const VIDEO_TYPES = ["mp4", "mov", "avi", "wmv", "flv", "mpeg", "mpg"];
    if (IMAGE_TYPES.includes(getFileType(file)))
      return (
        <img
          src={file}
          alt=""
          onClick={() => window.open(file, "_blank")}
          style={{
            width: "100%",
            marginTop: "3rem",
            height: "50vh",
            objectFit: "cover",
          }}
        />
      );
    else if (VIDEO_TYPES.includes(getFileType(file)))
      return (
        <video
          src={file}
          controls
          onClick={() => window.open(file, "_blank")}
          style={{ width: "100%", marginTop: "3rem", height: "50vh" }}
        />
      );
    else
      return (
        <div
          className="pdf-container"
          onClick={() => window.open(file, "_blank")}
        >
          <p>Click to View Attachment</p>
        </div>
      );
  };

  const period = getMeaningfulDate(dealFeed?.date);
  return dealFeed?.id ? (
    <div style={{ width: "95%", margin: "auto" }}>
      <BackButton />

      <div className="detail-header-container">
        <Header
          title={`${dealFeed?.title || ""}`}
          subtitle={`Author: ${capitalizeMe(
            dealFeed?.author?.first_name + " " + dealFeed?.author?.last_name
          )}`}
        />
        <span>{period}</span>
      </div>
      <div className="deal-update-detail-body-container">
        <div
          className="optimum-padding-right-left dealFeed-detail-body"
          dangerouslySetInnerHTML={{
            __html: dealFeed?.body || "",
          }}
        ></div>
        <div className="feed-card-image optimum-padding-right-left ">
          {dealFeed?.file ? showFiles(dealFeed?.file) : <></>}
        </div>
      </div>
    </div>
  ) : (
    <Processing text={"loading..."} />
  );
}
