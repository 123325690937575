import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Title from "components/title/Title";
import { Descriptions } from "documentation/Descriptions";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import Note from "shared/components/note/Note";
import ModalContent from "shared/components/modal-content/ModalContent";
import Processing from "shared/components/processing/Processing";
import ShowActions from "shared/components/showActions/ShowActions";
import ApiCallHandler from "shared/js/ApiCallHandler";
import URLS from "shared/js/urls";
import { parseJSON } from "shared/js/utils";
import {
  editMutationAction,
  toggleModalAction,
  toggleToastAction,
} from "store/actions/actions";
import { NoItems, Table } from "@kehillahglobal/ui";
import CreateAndEditAssetManager from "./CreateAndEditAssetManager";


class ListDealAssetManager extends Component {
  closeModal = () => {
    this.props.toggleModal({
      show: false,
    });
  };
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
      showActions: false,
      id: "",
      uploadingImg: false,
    };
  }

  toggleShowMoreOptions = (item) => {
    if (!item?.id) {
      this.setState({
        showActions: false,
        id: "",
      });
    } else {
      this.setState({
        showActions: true,
        id: item?.id,
      });
    }
  };

  toggleShowManagerInfo = (manager) => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `${manager?.user?.first_name + " " + manager?.user?.last_name}`,
        children: this.renderManagerFullDetails(manager),
        themeColor: "var(--app-theme)",
        size: "large",
      },
    });
  };
  onManagerImageClick = (manager) => {
  
    let fileInput = document.getElementById("manager-image");
    fileInput.click();
    let { deals } = this.props;
    let { currentDeal, asset } = this.props;
    let _this = this
    fileInput.onchange = async (e) => {
      _this.setState({ uploadingImg: true });

      let res = await ApiCallHandler.send(URLS.UPDATE_USER_IMAGE, "POST", {
        image: e.target.files[0],
        user_id: manager?.user?.id,
      });
      if (res?.success) {
        manager.user = res.data;
        let managers = asset?.managers?.filter((item) => item.id !== manager.id );
        managers.push(manager);
        asset.managers = managers;
        let assets = currentDeal?.assets?.filter((item) => item.id !== asset.id);
        assets.push(asset);
        currentDeal.assets = assets;
        this.props.updateItemInredux(deals, currentDeal);
        this.setState({ uploadingImg: false });
        this.closeModal()
      }
      this.setState({ uploadingImg: false });
    };
  };

  renderManagerFullDetails = (manager) => {
    return (
      <div className="manager-detail-container">
        <div style={{ display: "flex" }}>
          <input
            type="file"
            accept="image/*"
            id="manager-image"
            style={{ display: "none" }}
          />
          <div className="asset-manager-img-container">
            <img
              src={manager?.user?.image}
              alt=" "
              className="asset-manager-img"
            />

            <div
              className="middle"
              onClick={() => this.onManagerImageClick(manager)}
            >
              <p>Change</p>
            </div>
          </div>
          <div>
            <h2 style={{ marginBottom: 10 }}>
              {manager?.user?.first_name + " " + manager?.user?.last_name}
            </h2>
            <p>{manager?.user?.email}</p>
            <p>{manager?.user?.phone_number}</p>
            <p>{parseJSON(manager?.user?.info)?.status}</p>
          </div>
        </div>

        <div className="manager-more-info">
          <div
            style={{ textAlign: "justify" }}
            dangerouslySetInnerHTML={{
              __html: parseJSON(manager?.user?.info)?.more_info,
            }}
          ></div>
        </div>
      </div>
    );
  };

  handleToggleDeleteManager = (item) => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `Delete "${item?.user?.first_name}"`,
        children: (
          <ModalContent content={"Would you like to delete this record ?"} />
        ),
        themeColor: "maroon",
        cancel: true,
        okay: {
          text: "Yes",
          function: () => {
            this.handleDeleteManager(item);
          },
        },
      },
    });
  };

  updateRedux = (item) => {
    const { deals } = this.props;
    const { asset, currentDeal } = this.props;
    let newManagers = asset?.managers?.filter(
      (manager) => manager?.id !== item?.id
    );
    asset.managers = newManagers;
    let newAssets = currentDeal?.assets?.filter(
      (item) => item?.id !== asset?.id
    );
    newAssets.push(asset);
    currentDeal.assets = newAssets;

    this.props.updateItemInredux(deals, currentDeal);
  };



  handleDeleteManager = async (item) => {
    let res = await ApiCallHandler.send(
      URLS.ARCHIVE_DEAL_ASSET_MANAGER,
      "POST",
      {
        id: item?.id,
      }
    );

    if (res && res.success) {
      this.updateRedux(item);
      this.props.toggleToast({
        show: true,
        message: "Deal Asset Manager deleted successfully",
        type: "success",
      });
    } else {
      this.props.toggleToast({
        show: true,
        message: "An error occurred. Try again",
        type: "failure",
      });
    }
  };

  handleShowCreateAndEditManagerForm = (item = {}) => {
    let { asset } = this.props;

    this.props.toggleModal({
      show: true,
      props: {
        title: `${item?.id ? "Update Manager Info" : "Add New Manager"}`,
        children: (
          <CreateAndEditAssetManager
            item={item}
            close={this.closeModal}
            asset={asset}
            dealID={this.props?.match?.params?.dealID}
          />
        ),
        themeColor: "var(--app-theme",
        size: "large",
      },
    });
  };

  prepareManagerData = (managers) => {
    let data = Array.isArray(managers) ? managers : [];
    let tableData = data?.map((item) => {
      return [
        <p className="manager-list-image">
          <img src={item?.user?.image}  alt=' '/>
        </p>,
        <p onClick={() => this.toggleShowManagerInfo(item)}>
          {item?.user?.first_name + " " + item?.user?.last_name}
        </p>,
        <p onClick={() => this.toggleShowManagerInfo(item)}>
          {item?.user?.email}
        </p>,
        <p onClick={() => this.toggleShowManagerInfo(item)}>
          {item?.user?.phone_number}
        </p>,
        <p>{parseJSON(item?.user?.info)?.status}</p>,
        <div>
          <ShowActions
            onEdit={this.handleShowCreateAndEditManagerForm}
            onDelete={this.handleToggleDeleteManager}
            item={item}
            id={this.state.id}
            toggleShowMoreOptions={this.toggleShowMoreOptions}
            show={this.state.showActions}
          />
        </div>,
      ];
    });
    return tableData;
  };

  render() {
    let { asset } = this.props;
    let {uploadingImg} = this.state
    return !uploadingImg ? (
      <div id="asset-detail-container">
        <Note note={Descriptions.ASSET_MANAGER} />
        <div>
          <Title
            className="text-toggler"
            onClick={this.handleShowCreateAndEditManagerForm}
          >
            <FontAwesomeIcon icon={faPlus} /> Add Manager
          </Title>
        </div>

        <div style={{ marginTop: 10, marginBottom: "4rem" }}>
          {asset?.managers ? (
            <Table
              columns={["Photo","Name", "Email", "Phone", "Status", "Actions"]}
              data={this.prepareManagerData(asset?.managers)}
              hoverAnimation={false}
            />
          ) : (
            <div
              style={{
                display: "grid",
                placeItems: "center",
                height: "70hv",
              }}
            >
              <NoItems text="You have Not Added managers yet. Click 'Add Manager' to add. " />
            </div>
          )}
        </div>
      </div>
    ) : <Processing text={"uploading image..."}/>;
  }
}

const mapStateToProps = (state) => {
  return {
    deals: state.deals,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleToast: toggleToastAction,
      toggleModal: toggleModalAction,
      updateItemInredux: (current_data, new_data) =>
        dispatch(editMutationAction("DEAL", current_data, new_data)),
    },
    dispatch
  );
};

export default withRouter( connect(
  mapStateToProps,
  mapDispatchToProps
)(ListDealAssetManager))
