import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ApiCallHandler from "shared/js/ApiCallHandler";
import URLS from "shared/js/urls";
import { getLoginUserInfoAction, toggleToastAction } from "store/actions/actions";
import "./T&C.css";
import { useHistory } from "react-router-dom";

export default function TermAndConditions(props) {
  const dispatch = useDispatch(null);
  let history = useHistory()
  const user = useSelector(state=>state.loggedInUser)

  const acceptTerms = () => {
    ApiCallHandler.send(URLS.UPDATE_USERS, "POST", {
      id: user?.id,
      has_accepted_tcs: "True",
    }).then((data) => {
      if (data.success) {
        dispatch(getLoginUserInfoAction(data?.data[0]));
        history.goBack();
      } else {
        dispatch(
          toggleToastAction({
            showToast: true,
            message: "An error occurred. Try again later.",
            type: "failure",
          })
        );
      }
    });
  };
  const rejectTerms = () => {
    window.confirm(
      "Are you sure you want to reject the terms and conditions?"
    ) && props.history.push("/");
  };
  return (
    <div className="tc-root">
      <div className="content-container">
        <div className="header-container">
          <span>AGREEMENT</span>
          <p>Terms Of Service</p>
        </div>

        <div className="tc-body">
          <p>
            Read our Terms of Service{" "}
            <a href="https://docs.google.com/document/d/1SyROLHdxjGokwS5AIRemEUtca0GOHylzOidWwz5d9SU/edit?usp=sharing">
              here
            </a>
          </p>
        </div>

        <div className="tc-btn-container">
          <div className="elevate-float reject btn-item" onClick={rejectTerms}>
            <p>Decline</p>
          </div>
          <div
            className="elevate-float accept-item btn-item"
            onClick={acceptTerms}
          >
            <p>Accept </p>
          </div>
        </div>
      </div>
    </div>
  );
}
