import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "../wallets.css";
import bank from "../../../shared/imgs/bank.svg";
import {
  toggleModalAction,
  toggleToastAction,
  editMutationAction,
} from "../../../store/actions/actions";
import {NoItems} from "@kehillahglobal/ui";
import URLS from "../../../shared/js/urls";
import ApiCallHandler from "../../../shared/js/ApiCallHandler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisV,
  faPen,
  faPlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  getActiveEnterprise,
  isEmpty,
} from "shared/js/utils";
import Title from '../../../components/title/Title';
import VerifyBankInfo from './steps/VerifyBankInfo';
import { getCountryInfo } from '../../../shared/js/utils';
import CreateBankAccount from './CreateBankAccount';
import ModalContent from "shared/components/modal-content/ModalContent";

class BankWallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editData: {},
      showDelete: false,
      toDeleteId: "",
    };
  }
  handleShow = (wallet) => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `${wallet?.account_name} Information`,
        children: (
          <div style={{ margin: "15px 20px" }}>
            <VerifyBankInfo data={wallet} />
          </div>
        ),
        themeColor: "var(--app-theme)",
        cancel: false,
        size: "medium",
      },
    });
  };

  close = () => {
    this.props.toggleModal({
      show: false,
    });
  };

  showBankWallets = (wallet) => {
    let { currency } = getCountryInfo(wallet?.currency);
    return (
      <div id="bank-item-container" className="elevate-float">
        <div className="bank-icon-container icon-container ">
          <img src={bank} alt="bank" />
        </div>

        <div id="bank-wallet-info-container">
          <div onClick={() => this.handleShow(wallet)}>
            <h3>{wallet?.account_name?.toUpperCase()}</h3>
            <p id="bank-name">
              {wallet?.bank_name} ({wallet?.bank_branch})
            </p>
            <p style={{ fontWeight: "bold" }}>{wallet?.country}</p>
            <span>Swift - {wallet?.swift_code}</span>
            <p id="account-number" className="account-num">
              {wallet?.account_number} ({currency?.code})
            </p>
          </div>

          {this.state.showDelete && wallet?.id === this.state.toDeleteId ? (
            <div className="delete-container elevate-float">
              <div onClick={() => this.handleAdd(wallet)}>
                <FontAwesomeIcon icon={faPen} /> Edit Wallet info
              </div>
              <div onClick={() => this.handleDelete(wallet)}>
                <FontAwesomeIcon icon={faTrashAlt} /> Delete Wallet
              </div>
            </div>
          ) : null}

          <div
            className="more-container"
            onClick={() => this.showDeleteItem(wallet.id)}
          >
            <FontAwesomeIcon icon={faEllipsisV} />
          </div>
        </div>
      </div>
    );
  };

  handleAdd = (editData = null) => {
    this.setState({ showDelete: false });
    this.props.toggleModal({
      show: true,
      props: {
        title: `${editData ? "Edit" : "Add New"} Bank Account`,
        children: this.form(editData),
        themeColor: "var(--app-theme)",
        cancel: false,
        size: "large",
      },
    });
  };

  showDeleteItem = (id = null) => {
    let { showDelete } = this.state;
    if (showDelete) {
      this.setState({ showDelete: false, toDeleteId: null });
    } else {
      this.setState({ showDelete: true, toDeleteId: id });
    }
  };

  handleDelete = (item) => {
    let { wallets } = this.props;
    if (wallets?.length < 1) return;
    this.setState({ showDelete: false });
    this.props.toggleModal({
      show: true,
      props: {
        title: `Delete "${item?.account_number}"`,
        children: (
          <ModalContent content={"Would you like to delete this record ?"} />
        ),
        themeColor: "maroon",
        cancel: true,
        okay: {
          text: "Yes",
          function: () => {
            this.triggerBackendDelete(item, wallets);
          },
        },
      },
    });
  };

  triggerBackendDelete = (item, wallets) => {
    ApiCallHandler.send(URLS.ARCHIVE_BANK_WALLETS, "POST", {
      id: item?.id,
    }).then(() => {
      let newWallets = wallets.filter((wallet) => wallet.id !== item.id);
      let newEnterprise = this.props.enterprise;
      newEnterprise.bank_wallets = newWallets;
      this.props.editRecord(this.props.enterprises, newEnterprise);
    });
  };

  form = (editData = {}) => {
    return (
      <CreateBankAccount
        editData={editData}
        close={this.close}
        enterprise={this.props.enterprise}
      />
    );
  };

  render() {
    let { wallets } = this.props;
    return (
      <div id="bank-wallet-wrapper">
        <div id="header-txt-and-add-container">
          <div style={{ marginLeft: "20px" }}>
            <Title className="text-toggler" onClick={() => this.handleAdd()}>
              <FontAwesomeIcon icon={faPlus} /> New Bank Account
            </Title>
          </div>
        </div>
        <div id="bank-wallet-list-container">
          {!isEmpty(wallets) ? (
            wallets?.map((wallet) => this.showBankWallets(wallet))
          ) : (
            <div>
              <NoItems text="No Bank Account Found.Click the button." />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const localEnterprise =getActiveEnterprise();
  const enterprise = state.enterprises?.find(
    (item) => item?.id === localEnterprise.id
  );
  return {
    wallets: enterprise?.bank_wallets,
    enterprise: enterprise,
    enterprises: state.enterprises,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleModal: toggleModalAction,
      toggleToast: toggleToastAction,
      editRecord: (current_data, new_data) =>
        dispatch(
          editMutationAction("ENTERPRISE_MUTATION", current_data, new_data)
        ),
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BankWallet);
