import { faCamera, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Processing from "shared/components/processing/Processing";
import Verified from "shared/components/verified/Verified";
import ApiCallHandler from "shared/js/ApiCallHandler";
import URLS from "shared/js/urls";
import { capitalizeMe, getActiveEnterprise } from "shared/js/utils";
import {
  getLoginUserInfoAction,
  toggleModalAction,
  toggleToastAction,
} from "store/actions/actions";
import { FormGenerator } from "@kehillahglobal/ui";

class AdminProfileSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: "",
      loading: false,
    };
  }

  showEditUserInfo = () => {
    let { user } = this.props;
    this.props.toggleModal({
      show: true,
      props: {
        title: `Update Information`,
        children: this.editUserInfoForm(user),
        themeColor: "var(--app-theme)",
        size: "large",
      },
    });
  };
  showResponse = (res) => {
    if (res && res.success) {
      this.props.updateLoggedInUserInRedux(res?.data[0]);
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });
      this.props.toggleToast({
        showToast: true,
        message: "An error occurred. Try again",
        type: "failure",
      });
    }
  };
  handleSelectImage = () => {
    let { user } = this.props;
    let fileInput = document.getElementById("user-logo-select");
    fileInput.click();

    fileInput.onchange = async (e) => {
      this.setState({ loading: true });
      let res = await ApiCallHandler.send(URLS.UPDATE_USERS, "POST", {
        image: e.target.files[0],
        id: user?.id,
      });
      this.showResponse(res);
    };
  };

  editUserInfoForm = (info) => {
    return (
      <div>
        <FormGenerator
          elevation={0}
          fields={[
            {
              fieldType: FormGenerator.Fields.INPUT,
              label: "First Name",
              name: "first_name",
              placeholder: "Enter your first name",
              value: info?.id ? info?.first_name : "",
            },
            {
              fieldType: FormGenerator.Fields.INPUT,
              label: "Middle Name",
              name: "middle_name",
              placeholder: "Enter your other name",
              value: info?.id ? info?.middle_name : "",
            },
            {
              fieldType: FormGenerator.Fields.INPUT,
              label: "Last Name",
              name: "last_name",
              placeholder: "Enter your last name",
              value: info?.id ? info?.last_name : "",
            },

            {
              fieldType: FormGenerator.Fields.RICHTEXT,
              name: "bio",
              label: "About the info",
              placeholder: "Brief description of what this info is about...",
              required: false,
              value: info ? info.bio : "",
            },
          ]}
          onSubmit={(data, reset) => {
            data = {
              ...data,
              id: info.id,
            };
            this.setState({ loading: true });

            ApiCallHandler.send(URLS.UPDATE_USERS, "POST", data).then((res) => {
              this.showResponse(res);
              this.props.toggleModal({ show: false });
              this.setState({ loading: false });
            });
          }}
          formSubmitBtnText={"Update"}
          subtitle=""
          title=""
        />
      </div>
    );
  };

  render() {
    let { user } = this.props;
    let { loading } = this.state;

    return (
      <div id="enterprise-setting-wrapper">
        <div id="img-and-title-container">
          <div style={{ position: "relative", height: "max-content" }}>
            <img src={user?.image} alt="logo" id="ent-logo" />
            <input
              type="file"
              style={{ display: "none" }}
              id="user-logo-select"
            />
            <div id="change-ent-logo" onClick={this.handleSelectImage}>
              <FontAwesomeIcon icon={faCamera} color="white" />
            </div>
          </div>

          <div id="txts-container">
            <div>
              <h2>
                {capitalizeMe(user?.first_name)}{" "}
                {capitalizeMe(user?.last_name) || ""}
                {user?.is_verified && (
                  <Verified bg={"green"} height={"24px"} width={"24px"} />
                )}
              </h2>
              <span className="email-and-domain">{user?.email}</span>
              <div
                id="update-verification-text-container"
                style={{ marginTop: 15, display: "flex" }}
                onClick={() => this.showEditUserInfo()}
              >
                <FontAwesomeIcon icon={faPen} />
                <p style={{ marginLeft: 5 }}>Edit info</p>
              </div>
            </div>
          </div>
        </div>

        <div id="bio-section-container">
          <h3>About Me</h3>
          <div
            id="bio-text-container"
            dangerouslySetInnerHTML={{
              __html: user?.bio || `You have not added any bio.`,
            }}
          ></div>
        </div>
        {loading && <Processing text={"submitting data ..."} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const enterprise =
    state.enterprises?.find((item) => item.id === getActiveEnterprise()?.id) ||
    {};
  return {
    enterprise,
    user: state.loggedInUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateLoggedInUserInRedux: getLoginUserInfoAction,
      toggleModal: toggleModalAction,
      toggleToast: toggleToastAction,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminProfileSettings);
