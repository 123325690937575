import React, { Component } from "react";
import "./CreateAsset.css";
import Header from "components/header/Header";
import ApiCallHandler from "../../../shared/js/ApiCallHandler";
import URLS from "../../../shared/js/urls";
import { connect } from "react-redux";
import {
  toggleToastAction,
  setAssetCreateOrEditTypeAction,
  createMutationAction,
  editMutationAction,
  setEditSetterAction,
} from "store/actions/actions";
import { bindActionCreators } from "redux";
import { Button, FormGenerator } from "@kehillahglobal/ui";
import { getActiveEnterprise, parseJSON } from "shared/js/utils";

class CreateAsset extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showToast: false,
      assetTypes: [],
      msg: "",
      toast_type: "",
      routeName: "",
      // replace this dummy data with the actual data fromlist asset page
      currentData: {
        name: "",
        id: "",
        identification_number_name: "",
        description: "",
        identification_number: "",
        cost: "",
      },
    };
  }

  handleCreate = async (data, reset) => {
    let { editStatus, switchTabs, editData, assets } = this.props;

    const currentUserProfile = parseJSON(localStorage.userData);
    const currentEnterprise = getActiveEnterprise();
    const currentData = editData?.assetData;

    let res;
    let toSend = {
      ...data,
      owner_id: currentUserProfile.id,
      enterprise_id: currentEnterprise.id,
      asset_type_id: data && data.asset_type ? data.asset_type.id : "",
    };

    if (!editStatus) {
      res = await ApiCallHandler.send(URLS.CREATE_ASSETS, "POST", toSend);
    } else {
      const dataToSend = {
        ...toSend,
        id: currentData.id,
      };
      res = await ApiCallHandler.send(URLS.UPDATE_ASSETS, "POST", dataToSend);
    }
    if (res && res.success) {
      this.props.toggleToast({
        show: true,
        message: !editStatus
          ? "Asset created successfully"
          : "Asset Updated Successfully",
        type: "success",
      });
      !editStatus
        ? await this.props.createAsset(assets, res.data)
        : await this.props.editAsset(assets, res.data[0]);
      reset();
      this.props.setEditPayload({});
      switchTabs("list-all-assets");
    } else {
      this.props.toggleToast({
        showToast: true,
        message: "Error creating an asset. Try again",
        type: "failure",
      });
    }
  };

  getFieldValue = (field) => {
    let { editStatus } = this.props;
    let value = !editStatus ? "" : this.state.currentData[field];
    return value;
  };

  render() {
    let { assetTypes: types, editStatus, editData } = this.props;
    const Fields = FormGenerator.Fields;
    return (
      <div>
        <Header
          title={
            !editStatus ? (
              "Create Asset"
            ) : (
              <div>
                <Button
                  onClick={() => {
                    this.props.setEditPayload({});
                    this.props.switchTabs("list-all-assets");
                  }}
                  theme="danger"
                >
                  Cancel Edit
                </Button>
                <br />
                Edit {editData?.assetData.name}
              </div>
            )
          }
          subtitle={`${!editStatus ? "Create" : "Edit"} all your assets here`}
        />
        <div id="form-container">
          <FormGenerator
            elevation={0}
            fields={[
              {
                fieldType: Fields.INPUT,
                label: "Enter asset name",
                name: "name",
                placeholder: "Asset name",
                required: true,
                value: this.getFieldValue("name") || editData?.assetData?.name,
              },
              {
                data: types,
                fieldType: Fields.DROPDOWN,
                label: "Asset type",
                name: "asset_type",
                placeholder: "Your asset is of which type?",
                required: false,
                type: "full",
                value: editData?.assetData?.asset_type,
                labelExtractor: (item) => item.name,
                valueExtractor: (item) => item.id,
              },
              {
                fieldType: Fields.INPUT,
                label: "Enter asset identification number name",
                name: "identification_number_name",
                placeholder: "Asset identification number name",
                required: true,
                value:
                  this.getFieldValue("identification_number_name") ||
                  editData?.assetData?.identification_number_name,
              },
              {
                fieldType: Fields.INPUT,
                label: "Enter asset identification number",
                name: "identification_number",
                placeholder: "Asset identification number ",
                required: true,
                value:
                  this.getFieldValue("identification_number") ||
                  editData?.assetData?.identification_number,
              },
              {
                fieldType: Fields.INPUT,
                label: "Enter cost of asset",
                name: "cost",
                placeholder: "cost ",
                required: true,
                value: this.getFieldValue("cost") || editData?.assetData?.cost,
              },

              {
                fieldType: Fields.TEXTAREA,
                label: "Enter Description",
                name: "description",
                placeholder: "Write your description here... ",
                required: false,
                value:
                  this.getFieldValue("description") ||
                  editData?.assetData?.description,
              }
            ]}
            onSubmit={this.handleCreate}
            formSubmitBtnText={!editStatus ? "create asset" : "Save Changes"}
            subtitle=""
            title=""
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    assetTypes: state.assetTypes,
    toastProps: state.toastProps,
    assets: state.assets,
    editData: state.editStatusPayload,
    editStatus:
      state.editStatusPayload && state.editStatusPayload.assetEditStatus
        ? state.editStatusPayload.assetEditStatus
        : false,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleToast: toggleToastAction,
      setAssetFormMode: (val) => dispatch(setAssetCreateOrEditTypeAction(val)),
      createAsset: (assets, new_data) =>
        dispatch(createMutationAction("ASSET", assets, new_data)),
      editAsset: (assets, new_data) =>
        dispatch(editMutationAction("ASSET", assets, new_data)),
      setEditPayload: (val) => dispatch(setEditSetterAction(val)),
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateAsset);
