import React from "react";
import {Spinner} from "@kehillahglobal/ui";
import "./LoadingPage.css";

export default function LoadingPage({ style, close, text }) {
  return (
    <div className="loading-container" style={style}>
      <Spinner
        thickness={5}
        variation={Spinner.TWO_HALF_CIRCLES_TYPE}
        color="var(--app-accent-pink)"
      />
      <p
        style={{ margin: "20px 0px", fontSize: "1.2rem", cursor: "pointer" }}
        onClick={() => {
          //  will be removed later when done
          if (close) close();
        }}
      >
        {text || " Loading..."}
      </p>
    </div>
  );
}
