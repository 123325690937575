import React, { Component } from "react";
import { FormGenerator } from "@kehillahglobal/ui";
import { DEAL_STATUS } from "shared/js/typeConstants";
import { getRandomStringKey } from "shared/js/utils";


class LiveInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            key: null
        }
    }

    componentDidMount() {
        if (this.props.data) {
            this.setState({
                data: this.props.data
            });
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (props.data) {
            if (!state.key) {
                return {
                    data: props.data,
                    key: getRandomStringKey(),
                };
            }
            return {
                data: props.data,
            };
        }
        return null;
    }
    getStatus = () => {
        const { data } = this.state;
        if (data?.id && data?.status === DEAL_STATUS.LIVE) {
            return [DEAL_STATUS.LIVE, DEAL_STATUS.COMPLETED]
        }

        else if (data?.id && data?.status === DEAL_STATUS.DRAFT) {
            return [DEAL_STATUS.DRAFT, DEAL_STATUS.LIVE]
        }
        else {
            return [DEAL_STATUS.DRAFT]
        }

    }
    render() {
        const { data } = this.state;
        const formData = [
            {
                fieldType: FormGenerator.Fields.DROPDOWN,
                label: "At what stage is this deal?",
                name: "status",
                data: this.getStatus(),
                required: true,
                value: data['status']|| this.getStatus()[0],
            },
        ];
        return (
            <div key={this.state.key}>
                <FormGenerator
                    elevation={0}
                    subtitle=""
                    title=""
                    fields={formData}
                    hideSubmitBtn={true}
                    onStateChange={
                        this.props.onStateChange ? this.props.onStateChange : null
                    }
                />
            </div>
        );
    }
}

export default LiveInfo;
