import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { useHistory } from 'react-router-dom'
import "./BackButton.css";

export default function BackButton() {
    const history = useHistory()
  return (
      <div
      className="back-btn-container"
      onClick={() => history.goBack()}
    >
      <FontAwesomeIcon icon={faArrowLeft} />
      <span>Back</span>
    </div>
  )
}
