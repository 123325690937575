import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { setEditSetterAction } from "store/actions/actions";
import "./listings.css";
import DealsCard from "../deals-card/DealsCard";
import { DEAL_STATUS } from "shared/js/typeConstants";
import LoadingStatus from "shared/components/LoadingStatus/LoadingStatus";

class ListLiveDeals extends Component {
  handleViewDetails = (item) => {
    let enterpriseID = this.props?.match?.params?.enterprise
    this.props.setEditPayload({
      action: "VIEW_SINGLE_DEAL",
      payload: item,
    });
    this.props.history.push(`/enterprise/${enterpriseID}/deals/` + item.id);
  }

  render() {
    let { deals, all, tags } = this.props;
    return (
      <div className="">
        <div className="user-deal-items-wrapper">
          {deals?.length > 0 ? (
            deals.map((deal, index) => {
              return (
                <DealsCard
                  key={index.toString()}
                  deal={deal}
                  handleViewDetails={this.handleViewDetails}
                />
              );
            })
          ) : (
            <LoadingStatus items={deals} tags={tags} deals={all} />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    deals: state.deals?.filter(
      (item) => item.status === DEAL_STATUS.LIVE
    ),
    tags: state.tags,
    all: state.deals,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setEditPayload: (val) => dispatch(setEditSetterAction(val)),
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ListLiveDeals)
);
