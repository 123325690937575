import React, { Component } from "react";
import DealsPayout from "./payouts/DealsPayout";
import { connect } from "react-redux";
import { convertNumberToShortForm } from "shared/js/utils";
import { isEmpty } from "shared/js/utils";
import { getCountryInfo } from "../../../shared/js/utils";
import ListDealRevenue from "./revenue/ListDealRevenue";
import ListDealExpenses from "./expense/ListDealExpenses";
import { withRouter } from "react-router-dom";
import COLORS from "../../../shared/js/colors";
import {
  editMutationAction,
  getDealStatsAction,
  toggleModalAction,
  toggleToastAction,
} from "../../../store/actions/actions";
import { bindActionCreators } from "redux";
import AllAssets from "./deal-assets/listings/AllAssets";
import DealsDocuments from "./documents/list/DealsDocuments";
import PartnersWrapper from "./partners/PartnersWrapper";
import BackButton from "shared/components/back-btn/BackButton";
import Tips from "shared/components/Tips/Tips";
import SingleDeal from "./deal-details/SingleDeal";
import CreateAndListDealUpdate from "./feeds/create-and-list-deal-feeds/CreateAndListDealFeeds";
import Processing from "shared/components/processing/Processing";
import { DEAL_ASSETS_TAB_ID, DEAL_DOCUMENTS_TAB_ID, DEAL_EXPENSES_TAB_ID, DEAL_FEEDS_TAB_ID, DEAL_OVERVIEW_TAB_ID, DEAL_PARTNERS_TAB_ID, DEAL_PAYOUTS_TAB_ID, DEAL_REVENUE_TAB_ID, DEAL_SETTINGS_TAB_ID } from "./DealsTabIDs";
import DealSettings from "./settings/DealSettings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import URLS from "shared/js/urls";
import ApiCallHandler from "shared/js/ApiCallHandler";

import { TabView } from "@kehillahglobal/ui";


class dealsShowWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showToast: false,
      changer: localStorage.tab ? localStorage?.tab : DEAL_OVERVIEW_TAB_ID,
      oppStats: {},
      isLoading: false,
      loadingText:'',
      deal: {},
    };
  }

  updateStats = (data) => {
    this.setState({ oppStats: data });
  };

  async componentDidMount() {
    let { id } = this.props?.match?.params;
    let { deals } = this.props;
    let deal = (deals || [])?.find((item) => item?.id === id);
    let res = await this.props.getStats(id);
    if (!isEmpty(res?.payload)) {
      this.setState({
        oppStats: res?.payload,
      });
    }
    this.setState({ deal: deal });
  }

  static getDerivedStateFromProps(props) {
    let { id } = props?.match?.params;
    let deal = props.deals?.find((item) => item?.id === id);
    if (props.oppStats) {
      return {
        oppStats: props.oppStats,
        deal: deal,
      };
    }
    return;
  }

  TAB_DATA = [
    {
      name: "Overview",
      id: DEAL_OVERVIEW_TAB_ID,
      component: (
        <SingleDeal
          switchTabs={(changer) => {
            this.state.changer(changer);
          }}
        />
      ),
    },
    {
      name: "Realtime Updates",
      id: DEAL_FEEDS_TAB_ID,
      component: (
        <CreateAndListDealUpdate
          switchTabs={(changer) => {
            this.state.changer(changer);
          }}
        />
      ),
    },
    {
      name: "Assets",
      id: DEAL_ASSETS_TAB_ID,
      component: (
        <AllAssets
          switchTabs={(changer) => {
            this.state.changer(changer);
          }}
        />
      ),
    },
    {
      name: "Documents",
      id: DEAL_DOCUMENTS_TAB_ID,
      component: (
        <DealsDocuments
          switchTabs={(changer) => {
            this.state.changer(changer);
          }}
        />
      ),
    },
    {
      name: "Partners",
      id: DEAL_PARTNERS_TAB_ID,
      component: (
        <PartnersWrapper
          switchTabs={(changer) => {
            this.state.changer(changer);
          }}
        />
      ),
    },
    {
      name: "Revenue",
      id: DEAL_REVENUE_TAB_ID,
      component: (
        <ListDealRevenue
          switchTabs={(changer) => {
            this.state.changer(changer);
          }}
          updateStats={this.updateStats}
        />
      ),
    },
    {
      name: "Expenses",
      id: DEAL_EXPENSES_TAB_ID,
      component: (
        <ListDealExpenses
          switchTabs={(changer) => {
            this.state.changer(changer);
          }}
          updateStats={this.updateStats}
        />
      ),
    },
    {
      name: "Payouts",
      id: DEAL_PAYOUTS_TAB_ID,
      component: (
        <DealsPayout
          switchTabs={(changer) => {
            this.state.changer(changer);
          }}
          updateStats={this.updateStats}
        />
      ),
    },
    {
      name: "Settings",
      id: DEAL_SETTINGS_TAB_ID,
      component: (
        <DealSettings
          switchTabs={(changer) => {
            this.state.changer(changer);
          }}
          updateStats={this.updateStats}
        />
      ),
    },
  ];

  renderStats = (oppStats) => {
    let { currentEnterprise } = this.props;
    let { currency } = getCountryInfo(currentEnterprise?.currency);

    return (
      <div className="deal-figures-container">
        <Tips
          direction="bottom"
          content="Total Revenue is the full amount of total sales
              of goods and services made by the deal."
        >
          <div
            className="elevate-float card-me figure-item-container"
            style={{ backgroundColor: COLORS.REVENUE_PRIMARY }}
          >
            <div id="figures-details">
              <span>Total Revenue</span>
              <span>
                {currency?.symbol}{" "}
                {convertNumberToShortForm(
                  parseFloat(oppStats?.total_revenue || 0)?.toFixed(2)
                ) || parseFloat(0.0)?.toFixed(2)}
              </span>
            </div>
          </div>
        </Tips>

        <Tips
          direction="bottom"
          content="Total distribution the sum of all the payments made to the partners of the deal."
        >
          <div
            className="elevate-float card-me figure-item-container"
            style={{ backgroundColor: COLORS.DISTRIBUTION_PRIMARY }}
          >
            <div id="figures-details">
              <span>Total distribution</span>
              <span>
                {currency?.symbol}{" "}
                {convertNumberToShortForm(
                  parseFloat(oppStats?.total_distributions || 0)?.toFixed(2)
                ) || parseFloat(0.0)?.toFixed(2)}
              </span>
            </div>
          </div>
        </Tips>

        <Tips
          direction="bottom"
          content="Total Expense is the amount incurred in expense
                on the deal."
        >
          <div
            className="elevate-float card-me figure-item-container"
            style={{ backgroundColor: COLORS.EXPENSE_PRIMARY }}
          >
            <div id="figures-details">
              <span>Total Expense</span>
              <span>
                {currency?.symbol}{" "}
                {convertNumberToShortForm(
                  parseFloat(oppStats?.total_expenses || 0)?.toFixed(2)
                ) || parseFloat(0.0)?.toFixed(2)}
              </span>
            </div>
          </div>
        </Tips>
      </div>
    );
  };

  handleSelectImage = () => {
    let { deal } = this.state;
    let { deals } = this.props;
    let toUpload = document.getElementById("deal-image-upload");
    toUpload.click();

    toUpload.onchange = async (e) => {
       this.setState({ isLoading: true, loadingText: "Uploading image..." });
      let res = await ApiCallHandler.send(URLS.UPDATE_DEALS, "POST", {
        image: e.target.files[0],
        id: deal?.id,
      });
      if (res.success) {
        this.props.updateDeals(deals, res?.data[0]);
      }
      this.setState({ isLoading: false, loadingText: "" });
    };
  };

  renderDealStats = ()=>{
    let {oppStats} = this.state;

    let statData = [
      {
        title: "Revenue",
        value: oppStats?.total_revenue ||0,
        color: COLORS.INCOME_PRIMARY,
      },
      {
        title: "Distributions",
        value: oppStats?.total_distributions ||0,
        color: COLORS.DISTRIBUTION_PRIMARY,
      },
      {
        title: "Expenses",
        value: oppStats?.total_expenses || 0,
        color: COLORS.EXPENSE_PRIMARY,
      },
  
    ];

    return(
      <div  className="deal-stat-root-container">
        {
          statData.map((stat, index)=>{
            return (
              <div className="deal-stat-item">
                <p style={{ color: stat.color}}>{stat.title}</p>
                <div className="deal-stat-amount-container">
                  <p style={{ color: stat.color }}>$</p>
                  <h2 style={{ color: stat.color }}>
                    {convertNumberToShortForm(stat.value?.toFixed(1))}
                  </h2>
                </div>
              </div>
            );
          })
        }
      </div>
    )
  }

  render() {
    let { deal, isLoading } = this.state;
    return deal?.id ? (
      <div key={this.state.key}>
        <div style={{ marginTop: 20 }}>
          <div style={{ width: "93%", margin: "auto" }}>
            <BackButton />

            {deal?.image ? (
              <div className="deal-name-and-stats-container">
                <div className="deal-image-container">
                  <div style={{ position: "relative", height:'max-content' }}>
                    <img src={deal?.image} alt="" />
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      id="deal-image-upload"
                    />
                    <div id="change-ent-logo" onClick={this.handleSelectImage}>
                      <FontAwesomeIcon icon={faCamera} color="white" />
                    </div>
                  </div>

                  <div className="change-deal-image-container">
                    <h1>
                      <p>{deal?.name}</p>
                    </h1>
                  </div>
                </div>
                <div>{this.renderDealStats()}</div>
              </div>
            ) : (
              <div className="deal-name-and-stats-container">
                <div style={{}}>
                  <h1>
                    <p>{deal?.name}</p>
                  </h1>
                </div>
                <div>{this.renderDealStats()}</div>
              </div>
            )}
            <div style={{ marginTop: "2rem" }}>
              <TabView
                data={this.TAB_DATA}
                contentAreaStyle={{ paddingTop: 30 }}
                defaultTab={this.state.changer}
                onMount={(changer) => this.setState({ changer })}
              />
            </div>
          </div>
        </div>
        {isLoading && <Processing text={this.state.loadingText} />}
      </div>
    ) : (
      <Processing text={"Retrieving data...."} />
    );
  }
}

const mapStateToProps = (state) => {
  const currentEnterprise = state.currentEnterprise ||{};
  return {
    currentEnterprise,
    oppStats: state.dealStats,
    deals: state.deals,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getStats: getDealStatsAction,
      updateDeals: (current_data, new_data) =>
        dispatch(editMutationAction("DEAL", current_data, new_data)),
      showToast: toggleToastAction,
      showModal: toggleModalAction,
    },
    dispatch
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(dealsShowWrapper)
);
