import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { FormGenerator, NoItems, Spinner } from "@kehillahglobal/ui";
import {
  editMutationAction,
  toggleModalAction,
  toggleToastAction,
} from "../../../store/actions/actions";
import URLS from "../../../shared/js/urls";
import ApiCallHandler from "../../../shared/js/ApiCallHandler";
import { withRouter } from "react-router-dom";
import "../verification.css";
import { VERIFICATION_STATUSES } from "shared/js/typeConstants";
import { getActiveEnterprise, hasEmptyFields } from "shared/js/utils";
import { emailValidator } from "shared/js/fieldValidators";
require("dotenv").config();

class EnterpriseIncorporationDocuments extends Component {
  constructor(props) {
    super(props);
    this.documentsRef = React.createRef();

    this.state = {
      loading: false,
    };
  }
  componentDidMount() {
    let { onMount } = this.props;
    if (onMount) {
      onMount(() => this.saveDataToDB());
    }
  }

  extractDocument = (data) => {
    if (data?.primary_requirement_document?.data) {
      data.primary_requirement_document =
        data?.primary_requirement_document?.data?.file?.data;
    } else {
      delete data.primary_requirement_document;
    }
    if (data?.secondary_requirement_document?.data) {
      data.secondary_requirement_document =
        data?.secondary_requirement_document?.data?.file?.data;
    } else {
      delete data.secondary_requirement_document;
    }
    if (data?.other_requirement_document?.data) {
      data.other_requirement_document =
        data?.other_requirement_document?.data?.file?.data;
    } else {
      delete data.other_requirement_document;
    }
    return data;
  };

  saveDataToDB = async () => {
    let { enterprise, enterprises, requirements, compliance_documents } =
      this.props;
    const data = this.documentsRef.current?.onClickFromOutside();
    let res;
    if (data) {
      this.setState({ loading: true });
      data.enterprise_id = enterprise?.id;
      data.country_requirement_id = requirements?.id;
      data.status = VERIFICATION_STATUSES.SUBMITTED;

      let toSend = this.extractDocument(data);

      if (compliance_documents?.id) {
        toSend.id = compliance_documents?.id;
        res = await ApiCallHandler.send(
          URLS.UPDATE_ENTERPRISE_COMPLIANCES,
          "POST",
          toSend
        );
      } else {
        res = await ApiCallHandler.send(
          URLS.CREATE_ENTERPRISE_COMPLIANCES,
          "POST",
          toSend
        );
      }

      if (res?.success) {
        this.setState({ loading: false });
        let newEnterprise = enterprise;
        newEnterprise.compliance_documents = res?.data;
        this.props.editRecord(enterprises, newEnterprise);
        this.props.toggleToast({
          show: true,
          message:
            "Congratulations! Your compliance documents and information have been successfully submitted. Familiarize yourself with the platform as we verify you. Thank you.",
          type: "success",
        });
        this.props.history.push(`/enterprise/${enterprise?.id}/dashboard`);
      } else {
        this.setState({ loading: false });
        this.props.toggleToast({
          show: true,
          message: "An error occurred. Please try again",
          type: "failure",
        });
      }
    }
  };

  toggleRequestVerification = () => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `Verification request Form`,
        children: (
          <FormGenerator
            elevation={0}
            fields={[
              {
                fieldType: FormGenerator.Fields.INPUT,
                label: "Name",
                name: "name",
                placeholder: "Please enter enterprise name",
                required: true,
              },
              {
                fieldType: FormGenerator.Fields.INPUT,
                label: "Email",
                name: "email",
                placeholder: "An email to notify you when your request is processed",
                required: true,
                validator: (value) => emailValidator(value),
              },
              {
                fieldType: FormGenerator.Fields.INPUT,
                label: "Country",
                name: "country",
                placeholder: "Enter country name",
                required: true,
              },
            ]}
            onSubmit={(data, reset) => {
              ApiCallHandler.jsonAPICall(
                URLS.REQUEST_COUNTRY_REQUIREMENTS,
                "POST",
                {
                  name: data?.name,
                  country: data?.country,
                  email:data?.email
                }
              ).then((res) => {
                this.props.toggleModal({
                  show: false,
                });
                if (res?.success) {
                  this.props.toggleToast({
                    show: true,
                    message: "Your request has been submitted successfully",
                    type: "success",
                  });
                } else {
                  this.props.toggleToast({
                    show: true,
                    message: "An error occurred. Please try again",
                    type: "failure",
                  });
                }
              });
            }}
            formSubmitBtnText={"submit"}
            subtitle=""
            title=""
          />
        ),
        themeColor: "var(--app-theme)",
        size: "medium",
      },
    });
  };

  renderInvitationNotFoundMessage = () => {
    return (
      <div className="elevate-float invitation-not-found-message-container">
        <div>
          <h2>Hi 👋 there! </h2>
        </div>
        <div className="message-body-container">
          <span>
            Oops! the verification document for your country has not been
            uploaded yet. Please click{" "}
            <small onClick={() => this.toggleRequestVerification()}>here</small>{" "}
            to submit a request to our admins.
          </span>
          <p>Thank You 😊</p>
        </div>
      </div>
    );
  };

  render() {
    let { requirements, compliance_documents } = this.props;
    const main = [
      {
        fieldType: FormGenerator.Fields.FILE,
        label: requirements?.id
          ? requirements?.primary_requirement_name
          : "Primary Document",
        name: "primary_requirement_document",
        placeholder: "Select Document",
        required: true,
        value: compliance_documents?.id
          ? compliance_documents?.primary_requirement_document
          : "",
      },
      {
        fieldType: FormGenerator.Fields.FILE,
        label: requirements?.id
          ? requirements?.secondary_requirement_name
          : "Secondary Document",
        name: "secondary_requirement_document",
        placeholder: "Select Document",
        required: true,
        value: compliance_documents?.id
          ? compliance_documents?.secondary_requirement_document
          : "",
      },
    ];

    const otherDoc = [
      {
        fieldType: FormGenerator.Fields.FILE,
        label: requirements?.id
          ? requirements?.other_requirement_name
          : "Other Document",
        name: "other_requirement_document",
        placeholder: "Select Document",
        required: true,
        value: compliance_documents?.id
          ? compliance_documents?.other_requirement_document
          : "",
      },
    ];

    const formData = requirements?.other_requirement_name
      ? [...main, ...otherDoc]
      : main;
    let { enterprise } = this.props;

    return !hasEmptyFields(requirements) ? (
      <div style={{ position: "relative" }}>
        {" "}
        {enterprise?.country ? (
          <FormGenerator
            elevation={0}
            subtitle=""
            title=""
            formSubmitBtnText={"Save Changes"}
            fields={formData}
            hideSubmitBtn={true}
            ref={this.documentsRef}
          />
        ) : (
          <div id="no-country-selected-notification">
            <NoItems
              text={`Incorporation Document vary for different countries. Please set
              the country of your enterprise in "Address section" to see the
              required documents.`}
            />
          </div>
        )}
        {this.state.loading ? (
          <div id="loading-overlay-root">
            <div id="activity-indicators-container">
              <Spinner variation={"TwoHalfCirclesType"} />
              <p>submitting data ...</p>
            </div>
          </div>
        ) : null}
      </div>
    ) : (
      <div className="center-me-on-page">
        {this.renderInvitationNotFoundMessage()}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const enterpriseLoc = getActiveEnterprise();
  const enterprise = state.enterprises?.filter(
    (item) => item.id === enterpriseLoc.id
  )[0];
  return {
    enterprise: enterprise,
    requirements: state.countryEnterpriseRequirements || {},
    compliance_documents:
      enterprise?.compliance_documents !== null
        ? enterprise?.compliance_documents
        : {},
    enterprises: state.enterprises,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      editRecord: (current_data, new_data) =>
        dispatch(
          editMutationAction("ENTERPRISE_MUTATION", current_data, new_data)
        ),

      toggleToast: toggleToastAction,
      toggleModal: toggleModalAction,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EnterpriseIncorporationDocuments)
);
