export const Descriptions = {
  REALTIME_UPDATE: ``,
  ASSET: ``,
  DEAL_DOCUMENT: ``,
  REVENUE: ``,
  EXPENSE: ``,
  PAYOUT: ``,
  PAYMENT_ELECTION: ``,
  PARTNERS: ``,
  ASSET_MANAGER: ``,
  ASSET_DOCUMENT: ``,
};
