import React from "react";
import {NoItems} from "@kehillahglobal/ui";
import Processing from "../processing/Processing";

export default function LoadingStatus({ items, tags, deals }) {
  if (tags) {
    if (deals?.length) {
      if(!items?.length) {
        return (
          <div id="no-deals-container">
            <NoItems text="No Record found" />
          </div>
        );
      }
      return <Processing text={"Loading data..."} />;
    } else {
      return <Processing text={"Loading data...."} />;
    }
  } else {
    return (
      <div id="no-deals-container">
        <NoItems text="No Record found" />
      </div>
    );
  }
}
