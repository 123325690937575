import {
  faDatabase,
  faHandshake,
  faUsers,
  faCog,
  faSignOutAlt,
  faWallet,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import ApiCallHandler from "shared/js/ApiCallHandler";
import URLS from "shared/js/urls";
import store from "store/store";
let id = window.location.href.split("/")[4];
let ent = store.getState().currentEnterprise

let entId = ent?.id || id

export const MENU = [
  {
    groupName: "Manage",
    children: [
      {
        id: "dashboard",
        name: "Dashboard",
        icon: faDatabase,
        link: `/enterprise/${entId}/dashboard`,
      },
      {
        id: "deals",
        name: "Deals",
        icon: faHandshake,
        link: `/enterprise/${entId}/deals`,
      },
      {
        id: "partners",
        name: "Partners",
        icon: faUsers,
        link: `/enterprise/${entId}/partners`,
      },
    ],
  },
];



export const BOTTOM_MENU_BASIC_ADMIN = [
  {
    id: "settings",
    name: "Profile",
    icon: faUser,
    link: `/enterprise/${entId}/admin/profile`,
  },
  {
    id: "sign-out",
    name: "Sign Out",
    icon: faSignOutAlt,
    onClick: () => {
      const iAmSureIWantToLogOut = window.confirm(
        "Are you sure you want to sign out?"
      );
      if (iAmSureIWantToLogOut) {
        ApiCallHandler.send(URLS.LOGOUT, "POST", {}).then((res) => {
          if (res.success) {
            if (res.success) {
              window.location.href = "/";
            }
          }
        });
      }
    },
  },
];
export const BOTTOM_MENU_SUPER_ADMIN = [
  {
    id: "users",
    name: "Users",
    icon: faUsers,
    link: `/enterprise/${entId}/users`,
  },
  {
    id: "wallets",
    name: "Wallets",
    icon: faWallet,
    link: `/enterprise/${entId}/wallets`,
  },

  {
    id: "settings",
    name: "Settings",
    icon: faCog,
    link: `/enterprise/${entId}/manage`,
  },
  {
    id: "sign-out",
    name: "Sign Out",
    icon: faSignOutAlt,
  },
];
