import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import LoadingPage from "shared/components/loading/LoadingPage";
import ApiCallHandler from "shared/js/ApiCallHandler";
import URLS from "shared/js/urls";
import {
  extractEnterprises,
  extractOtherEnterprises,
  parseJSON,
} from "shared/js/utils";
import {
  getLoginUserInfoAction,
  setAllEnterprisesAction,
} from "store/actions/actions";

export default function AuthenticateUser() {
  let dispatch = useDispatch();
  let history = useHistory();
  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const [url, params] = window.location.href.toString().split("?");
    if (params) {
      // eslint-disable-next-line no-unused-vars
      const [paramName, paramValue] = params.split("=");

      const decodedData = parseJSON(atob(paramValue));
      const tokenId = decodedData?.tokenId;

      ApiCallHandler.send(URLS.LOGIN, "POST", { idToken: tokenId }).then(
        (res) => {
          if (res?.success) {
            let { enterprises, ...user } = res?.data;
            dispatch(getLoginUserInfoAction(user));
            const myEnterprises = extractEnterprises(res?.data);
            const myOtherEnterprises = extractOtherEnterprises(res?.data);
            dispatch(
              setAllEnterprisesAction([...myEnterprises, ...myOtherEnterprises])
            );
            history.push("/enterprises");
          }
          else{
            console.log("Error: ", res?.error);
          }
        }
      );
    }
  }, [dispatch, history]);
  return <LoadingPage text={"Preparing For You 🤗"} />;
}
