import React from "react";
import {Stepper} from "@kehillahglobal/ui";
import UserInfo from "./steps/UserInfo";
import BankInfo from "./steps/BankInfo";
import VerifyBankInfo from "./steps/VerifyBankInfo";
import { useState } from "react";
import {
  faCheckCircle,
  faPiggyBank,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { isEmpty } from "../../../shared/js/utils";
import ApiCallHandler from "../../../shared/js/ApiCallHandler";
import URLS from "../../../shared/js/urls";
import { useDispatch, useSelector } from "react-redux";
import {
  editMutationAction,
  toggleToastAction,
} from "../../../store/actions/actions";
import Processing from "shared/components/processing/Processing";

export default function CreateBankAccount(props) {
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  let dispatch = useDispatch();
  let enterprise = props.enterprise;
  let enterprises = useSelector((state) => state.enterprises);

  useEffect(() => {
    if (!isEmpty(props?.editData)) {
      setState({
        data: {
          ...state.data,
          ...props.editData,
        },
      });
    }
    // eslint-disable-next-line
  }, [props?.editData]);

  const collectFormData = (data) => {
    setState({
      data: {
        ...state.data,
        ...data,
      },
    });
  };
  const onNext = (step) => {
    if (step === steps.length - 1) {
      handleSubmit();
    }
  };

  const updateRedux = (isEdit, data) => {
    let { enterprise } = props;
    let bankWallets = enterprise?.bank_wallets;
    if (!isEdit) {
      bankWallets = isEmpty(bankWallets) ? [data] : [data, ...bankWallets];
    } else {
      let filtered = bankWallets.filter((item) => item.id !== data[0].id);
      bankWallets = [data[0], ...filtered];
    }
    enterprise.bank_wallets = bankWallets;
    dispatch(
      editMutationAction("ENTERPRISE_MUTATION", enterprises, enterprise)
    );
  };


  const handleSubmit = async () => {
    setLoading(true)
    let toSend = { ...state.data, enterprise_id: enterprise?.id };

    let res;
    toSend.currency =typeof toSend?.currency === "string"? toSend?.currency: `${toSend?.currency?.name}-${toSend?.currency?.currency?.name}`;
    toSend.country =typeof toSend?.country === "string"? toSend?.country: toSend?.country?.name;
    if (!toSend?.id) {
      res = await ApiCallHandler.send(URLS.CREATE_BANK_WALLETS, "POST", toSend);
    } else {
      let {confirm_account_number,...rest } = toSend;
      res = await ApiCallHandler.send(URLS.UPDATE_BANK_WALLETS, "POST", rest);
    }
    if (res && res.success) {
      updateRedux(toSend?.id, res?.data);
      setLoading(false)
      props.close();
      dispatch(
        toggleToastAction({
          show: true,
          message: !toSend?.id
            ? "Bank Account created successfully"
            : "Bank Account Updated Successfully",
          type: "success",
        })
      );
    } else {
      setLoading(false)
      props.close();
      dispatch(
        toggleToastAction({
          show: true,
          message: `An error occurred. Please make sure all required fields are populated.`,
          type: "failure",
        })
      );
    }
  };

  const steps = [
    {
      name: "Holder's Information",
      component: (
        <UserInfo onStateChange={collectFormData} data={state?.data} />
      ),
      icon: faUser,
    },
    {
      name: "Bank Information",
      component: (
        <BankInfo onStateChange={collectFormData} data={state?.data} />
      ),
      icon: faPiggyBank,
    },
    {
      name: "Verify Information",
      component: (
        <VerifyBankInfo onStateChange={collectFormData} data={state?.data} />
      ),
      icon: faCheckCircle,
    },
  ];
  const onCancel = () => {
    props.close();
  };
  return (
    <div>
      <Stepper
        variant={"horizontal"}
        steps={steps}
        onNext={onNext}
        state={state}
        onCancel={onCancel}
      />
      {loading && <Processing text={"Submitting data ....."} />}
    </div>
  );
}
