import React from 'react'
import ReactDOM from "react-dom";
import {Spinner} from '@kehillahglobal/ui';


export default function Processing({text}) {
   return ReactDOM.createPortal(
     <div id="loading-overlay-root">
       <div id="activity-indicators-container">
         <Spinner variation={"TwoHalfCirclesType"} />
         <p>{text ? text : "Processing ..."}</p>
       </div>
     </div>,
     document.body
   );
}
