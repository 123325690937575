import React, { Component } from "react";
import {Table, NoItems} from "@kehillahglobal/ui";
import Title from "components/title/Title";
import { connect } from "react-redux";
import {getActiveEnterprise} from '../../../shared/js/utils';
class EnterpriseUsers extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  prepareTableData = () => {
    let { admins } = this.props;

    let tableData = [];
    if (admins?.length > 0) {
      // TODO: Tweak table component to give access to manipulation of an entire row, instead of cells
      admins.forEach((item) => {
        tableData.push([
          <p>{item?.user?.first_name}</p>,
          <p>{item?.user?.email}</p>,
          <p>{item?.role?.name?.toLowerCase()?.replace(/_/g, " ")?.replace("enterprise", '')}</p>,
        ]);
      });
    }
    return tableData;
  };

  renderEnterpriseUsers = () => {
    let { admins } = this.props;
    return (
      <div>
        <div style={{ borderRadius: 10 }}>
          <Title>All Users</Title>

          {admins && admins.length ? (
            <Table
              columns={["Name", "Email", "Role"]}
              data={this.prepareTableData()}
              hoverAnimation={false}
            />
          ) : (
            <div id="no-items-container">
              <NoItems text="You have no invited users." />
            </div>
          )}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div id="create-asset-type-container">
        {this.renderEnterpriseUsers()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let ent = state.enterprises?.filter((e) => e.id === getActiveEnterprise()?.id)[0];
  return {
    admins: ent?.admins,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EnterpriseUsers);
