import React from "react";
import Title from "../../../components/title/Title";
import "./complianceInfo.css";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {Table, NoItems} from "@kehillahglobal/ui";
import { getFileType, getActiveEnterprise, isEmpty } from "../../../shared/js/utils";

export default function ComplianceInfo(props) {
  let history = useHistory();

  const enterpriseLoc = getActiveEnterprise();
  const enterprise =
    useSelector((state) =>
      state.enterprises?.find((item) => item.id === enterpriseLoc?.id)
    ) || {};
  const countryRequirements = useSelector(
    (state) =>
      state.countryEnterpriseRequirements|| null
  );

  const complianceDocs = enterprise?.compliance_documents;

  const showFile = (item) => {
    window.open(item, "_blank");
  };

  const prepareTableData = () => {
    return [
      complianceDocs?.primary_requirement_document
        ? [
            <p>{countryRequirements?.primary_requirement_name}</p>,
            <p>{getFileType(complianceDocs?.primary_requirement_document)}</p>,
            <p
              onClick={() =>
                showFile(complianceDocs?.primary_requirement_document)
              }
              className="show-file-link"
            >
              View File
            </p>,
          ]
        : [],
      complianceDocs?.secondary_requirement_document
        ? [
            <p>{countryRequirements?.secondary_requirement_name}</p>,
            <p>
              {getFileType(complianceDocs?.secondary_requirement_document)}
            </p>,
            <p
              onClick={() =>
                showFile(complianceDocs?.secondary_requirement_document)
              }
              className="show-file-link"
            >
              View File
            </p>,
          ]
        : [],
      complianceDocs?.other_requirement_document
        ? [
            <p>{countryRequirements?.other_requirement_name}</p>,
            <p>{getFileType(complianceDocs?.other_requirement_document)}</p>,
            <p
              onClick={() =>
                showFile(complianceDocs?.other_requirement_document)
              }
                className="show-file-link"
            >
              View File
            </p>,
          ]
        : [],
    ];
  };

  return (
    <div id="compliance-info-root">
      <div>
        <Title
          className="text-toggler"
          onClick={() => history.push(`/enterprise/${enterprise?.id}/verification`)}
        >
          Update Compliance Info
        </Title>
      </div>
      <div id="basic-info-wrapper">
        <Title>Basic Information</Title>
        <div id="basic-info-item">
          <p>Staff Size</p>
          <span>{enterprise?.staff_size || "Not set"}</span>
        </div>
        <div id="basic-info-item">
          <p>Industry</p>
          <span>{enterprise?.industry || "Not set"}</span>
        </div>
        <div id="basic-info-item">
          <p>Business Type</p>
          <span>{enterprise?.business_type || "Not set"}</span>
        </div>
      </div>

      <div id="basic-info-wrapper">
        <Title>Location Information</Title>
        <div id="basic-info-item">
          <p>Country</p>
          <span>{enterprise?.country || "Not set"}</span>
        </div>
        <div id="basic-info-item">
          <p>State or Region</p>
          <span>{enterprise?.state || "Not set"}</span>
        </div>
        <div id="basic-info-item">
          <p>City or Town</p>
          <span>{enterprise?.city || "Not set"}</span>
        </div>
        <div id="basic-info-item">
          <p>Address Line 1</p>
          <span>{enterprise?.address_line_one || "Not set"}</span>
        </div>
      </div>

      <div id="basic-info-wrapper">
        <Title>Compliance Documents</Title>

        {!isEmpty(enterprise?.compliance_documents) ? (
          <div>
            <Table
              columns={["Name", "Type", "\t"]}
              data={prepareTableData()}
              hoverAnimation={false}
            />
          </div>
        ) : (
          <div>
            <NoItems text="No Compliance Documents Uploaded yet" />
          </div>
        )}
      </div>
    </div>
  );
}
