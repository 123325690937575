import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  editMutationAction,
  toggleModalAction,
  toggleToastAction,
} from "store/actions/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Title from "components/title/Title";
import ApiCallHandler from "shared/js/ApiCallHandler";
import URLS from "shared/js/urls";
import ShowActions from "../../../../shared/components/showActions/ShowActions";
import {
  getActiveEnterprise,
  getMeaningfulDate,
  getCountryInfo,
  isEmpty,
} from "../../../../shared/js/utils";
import CreateDealRevenue from "./CreateDealRevenue";
import "./DealRevenue.css";
import { withRouter } from 'react-router';
import { getDealStatsAction } from '../../../../store/actions/actions';
import {NoItems, Table} from "@kehillahglobal/ui";
import Note from "shared/components/note/Note";
import { Descriptions } from "documentation/Descriptions";
import ModalContent from "shared/components/modal-content/ModalContent";

class ListDealRevenue extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showToast: false,
      assets: [],
      incomeTypes: [],
      msg: "",
      toast_type: "",
      routeName: "",
      currentData: {
        asset: "",
        type: "",
        id: "",
        period_start: "",
        period_end: "",
        amount: "",
      },

      id: "",
      showActions: false,
      newlyCreated: {},
    };
  }

  close = () => {
    this.props.toggleModal({ show: false });
  };

  DeleteItemfromRedux = (id) => {
    let { currentDeal, deals } = this.props;
    let filteredRevenue = currentDeal?.income?.filter(
      (item) => item.id !== id
    );
    currentDeal.income = filteredRevenue;
    this.props.updateItemInredux(deals, currentDeal);
  };

  handleDeleteDealRevenue = async (item) => {
    let res = await ApiCallHandler.send(
      URLS.ARCHIVE_DEAL_INCOME,
      "POST",
      {
        id: item?.id,
      }
    );
    if (res && res.success) {
      this.DeleteItemfromRedux(item?.id);
      let stats = await this.props.getOppStats(
            this.props.currentDeal?.id
          );
      this.props.updateStats(stats?.payload);
      this.props.toggleToast({
        show: true,
        message: "Revenue deleted successfully",
        type: "success",
      });
    } else {
      this.props.toggleToast({
        showToast: true,
        message: "An error occurred. Try again",
        type: "failure",
      });
    }
  };
  toggleShowMoreOptions = (item) => {
    if (!item?.id) {
      this.setState({
        showActions: false,
        id: "",
      });
    } else {
      this.setState({
        showActions: true,
        id: item?.id,
      });
    }
  };

  toggleShowAllRevenueInfo = (income) => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `Revenue Details`,
        children: this.renderRevenueFullDetails(income),
        themeColor: "var(--app-theme)",
        size: "medium",
      },
    });
  };

  renderRevenueFullDetails = (income) => {
    let { currentEnterprise } = this.props;
    let { currency } = getCountryInfo(currentEnterprise?.currency);
    return (
      <div className="detail-main-container">
        <div>
          <p>Type</p>
          <span>{income?.income_type?.name}</span>
        </div>
        <div>
          <p>Amount</p>
          <span>
            {currency?.symbol} {parseFloat(income?.amount)?.toFixed(2)}
          </span>
        </div>
        <div>
          <p>Payment Mode</p>
          <span>{income?.payment_mode}</span>
        </div>
        <div>
          <p>Receipt</p>
          {income?.receipt ? (
            <span onClick={() => window.open(income?.receipt, "_blank")}>
              View Receipt
            </span>
          ) : (
            <span>N/A</span>
          )}
        </div>
        <div>
          <p>Transaction Date</p>
          <span>
            {getMeaningfulDate(income?.transaction_date?.split("T")[0])}
          </span>
        </div>
        <div>
          <p>Reference ID</p>
          <span>{income?.reference_id}</span>
        </div>
        <div>
          <p>Notes</p>
          {income?.notes ? (
            <p
              style={{
                fontWeight: "normal",
                fontSize: "1rem",
                textAlign: "justify",
                color: "black",
              }}
            >
              {income?.notes}
            </p>
          ) : (
            <span>N/A</span>
          )}
        </div>
      </div>
    );
  };

  prepareTableData = () => {
    let { dealRevenue } = this.props;
    let tableData = [];

    if (!isEmpty(dealRevenue)) {
      dealRevenue.forEach((item, index) => {
        tableData.push([
          <p>{index+1}</p>,
          <p onClick={() => this.toggleShowAllRevenueInfo(item)}>
            {item?.income_type?.name}
          </p>,
          <p onClick={() => this.toggleShowAllRevenueInfo(item)}>
            {parseFloat(item?.amount)?.toFixed(2)}
          </p>,
          <p onClick={() => this.toggleShowAllRevenueInfo(item)}>
            {getMeaningfulDate(item?.transaction_date?.split("T")[0])}{" "}
          </p>,
          item?.receipt ? (
            <p onClick={() => window.open(item?.receipt, "_blank")}>
              View Receipt
            </p>
          ) : (
            <p>N/A</p>
          ),
          <p>
            {item?.reference_id
              ? item?.reference_id?.length> 20 ? item?.reference_id?.substring(0, 20) + " ...." : item?.reference_id
              : "No Ref. Id"}{" "}
          </p>,
          <p>{item?.payment_mode}</p>,
          <ShowActions
            onEdit={this.handleAddOrEditRevenue}
            onDelete={this.showDealRevenueDeleteModal}
            item={item}
            id={this.state.id}
            toggleShowMoreOptions={this.toggleShowMoreOptions}
            show={this.state.showActions}
          />,
        ]);
      });
    }
    return tableData;
  };

  renderDealRevenue = () => {
    let { dealRevenue, currentEnterprise } = this.props;
    let { currency } = getCountryInfo(currentEnterprise?.currency);
    return (
      <div>
        <Note note={Descriptions.REVENUE}/>
        <Title
          className="text-toggler"
          onClick={() => this.handleAddOrEditRevenue()}
        >
          Add Revenue <FontAwesomeIcon icon={faPlus} />
        </Title>
        <div style={{ borderRadius: 10 }}>
          {!isEmpty(dealRevenue) ? (
            <Table
              columns={[
                "#",
                "Revenue Type",
                `Amount (${currency?.symbol})`,
                "Transaction Date",
                "Receipt",
                "Ref. ID",
                "Payment Mode",
                "Actions",
              ]}
              data={this.prepareTableData()}
              hoverAnimation={false}
            />
          ) : (
            <NoItems text="No Revenue recorded yet." />
          )}
        </div>
      </div>
    );
  };

  handleAddOrEditRevenue = (item = {}) => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `${item?.id ? "Update " : "Add"} Revenue`,
        children: (
          <CreateDealRevenue
            editData={item}
            close={this.close}
            currentDeal={this.props.currentDeal?.id}
            updateStats={this.props.updateStats}
          />
        ),
        themeColor: "var(--app-theme)",
        size: "medium",
      },
    });
  };

  showDealRevenueDeleteModal = (item) => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `Delete "${item?.income_type?.name}"`,
        children: (
          <ModalContent content={"Would you like to delete this record ?"} />
        ),
        themeColor: "maroon",
        cancel: true,
        okay: {
          text: "Yes",
          function: () => {
            this.handleDeleteDealRevenue(item);
          },
        },
      },
    });
  };

  render() {
    return <div>{this.renderDealRevenue()}</div>;
  }
}

const mapStateToProps = (state, ownerProps) => {
  const currentDeal = state?.deals?.filter(
    (x) => x.id ===  ownerProps?.match?.params?.id
  )[0];


  const currentEnterprise = state.enterprises?.find(
    (item) => item.id === getActiveEnterprise()?.id
  );
  return {
    dealRevenue: currentDeal?.income,
    incomeTypes: currentDeal?.income_types,
    currentDeal,
    deals: state.deals,
    currentEnterprise,
    tags:state.tags,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleToast: toggleToastAction,
      toggleModal: toggleModalAction,
      updateItemInredux: (current_data, new_data) =>
        dispatch(editMutationAction("DEAL", current_data, new_data)),
      getOppStats:getDealStatsAction
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ListDealRevenue)
);
