import { Descriptions } from "documentation/Descriptions";
import React, {Component} from "react";
import Note from "shared/components/note/Note";
import { TabView } from "@kehillahglobal/ui";
import DealPartners from "./DealPartners";
import PartnerInvitations from "./PartnerInvitations";


export default class PartnersWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showToast: false,
      changer: "investors-for-deal",
      mode: "create",
    };
  }

  TAB_DATA = [
    {
      name: "All Partners",
      id: "investors-for-deal",
      component: (
        <DealPartners
          switchTabs={(changer) => {
            this.state.changer(changer);
          }}
        />
      ),
    },
    {
      name: "Partner Invitations",
      id: "invited-investors-for-deal",
      component: (
        <PartnerInvitations
          switchTabs={(changer) => {
            this.state.changer(changer);
          }}
        />
      ),
    },
  ];

  render() {
    return (
      <div id="asset-setup-and-config-container" style={{ paddingTop: 40 }}>
        <div style={{ width: "90%", margin: "auto" }}>
        <Note note={Descriptions.partners} />
          <TabView
            data={this.TAB_DATA}
            contentAreaStyle={{ paddingTop: 30 }}
            defaultTab={this.state.changer}
            onMount={(changer) => this.setState({ changer })}
          />
        </div>
      </div>
    );
  }
}
