import React from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {NoItems} from "@kehillahglobal/ui";
import DealsCard from "../deals-card/DealsCard";

export default function SearchResults(props) {

    let history =  useHistory()
    let params = useParams()


  const handleViewDetails = (item) => {
    let enterpriseID = params?.enterprise;
    history.push(`/enterprise/${enterpriseID}/deals/` + item.id);
  };
  return (
    <div className="user-deal-items-wrapper">
      {props?.results?.length > 0 ? (
        props?.results.map((item, index) => {
          return (
            <DealsCard
              key={index.toString()}
              deal={item}
              handleViewDetails={handleViewDetails}
            />
          );
        })
      ) : (
        <div id="no-deals-container">
          <NoItems text="Records matching query not found" />
        </div>
      )}
    </div>
  );
}
