import React, { Component } from 'react'
import {TabView} from '@kehillahglobal/ui';
import { bindActionCreators } from 'redux';
import AssetDetail from './detail/DealAssetDetail';
import { connect } from 'react-redux';
import BackButton from 'shared/components/back-btn/BackButton';
import Processing from 'shared/components/processing/Processing';
import ListDealAssetDocuments from './asset documents/ListDealAssetDocuments';
import ListDealAssetManagers from '../asset managers/ListDealAssetManagers';


class DealAssetDetailWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changer: "asset-detail",
      currentAsset: {},
      currentDeal: {},
      dealID: this.props.match?.params?.dealID,
      assetID: this.props.match?.params?.id,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (state.dealID) {
      let currentDeal = props.deals.find((deal) => deal?.id === state.dealID);
      if (currentDeal) {
        let asset = currentDeal.assets.find(
          (asset) => asset?.id === state.assetID
        );
        return {
          currentAsset: asset,
          currentDeal: currentDeal,
        };
      }
      return {};
    }
    return {};
  }


  render() {
    let { currentAsset } = this.state;

     const TABS = [
       {
         name: "Details",
         id: "asset-detail",
         component: (
           <AssetDetail
             switchTabs={(changer) => this.state.changer(changer)}
             currentAsset={currentAsset}
             currentDeal={this.state.currentDeal}
           />
         ),
       },
       {
         name: "Managers",
         id: "asset-managers",
         component: (
           <ListDealAssetManagers
             switchTabs={(changer) => this.state.changer(changer)}
             asset={currentAsset}
             currentDeal={this.state.currentDeal}
           />
         ),
       },
       {
         name: "Documents",
         id: "asset-documents",
         component: (
           <ListDealAssetDocuments
             edit={true}
             switchTabs={(changer) => this.state.changer(changer)}
             currentAsset={currentAsset}
             currentDeal={this.state.currentDeal}
           />
         ),
       },
     ];

    return currentAsset?.id ? (
      <div style={{ width: "90%", margin: "auto", marginTop: "2rem" }}>
        <BackButton />
        <TabView
          data={TABS}
          contentAreaStyle={{ paddingTop: 30 }}
          onMount={(changer) => this.setState({ changer })}
        />
      </div>
    ): <Processing  text="loading data ....."/>;
  }
}

const mapStateToProps = (state) => {
  return {
    deals: state.deals,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DealAssetDetailWrapper);

