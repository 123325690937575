import React, { Component } from "react";
import Header from "components/header/Header";
import ApiCallHandler from "../../../shared/js/ApiCallHandler";
import URLS from "../../../shared/js/urls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faArchive, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Table, FormGenerator } from "@kehillahglobal/ui";
import Title from "components/title/Title";
import { connect } from "react-redux";
import {
  getAssetIncomeTypesAction,
  toggleToastAction,
} from "../../../store/actions/actions";
import "./CreateAssetIncomeType.css";
import { getActiveEnterprise } from "shared/js/utils";

class CreateAssetIncomeType extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      toEdit: {},
      show: false,
    };
  }

  toggleShow = () => this.setState({ show: !this.state.show });

  handleDeleteAssetIncomeType = async (item) => {
    let res = await ApiCallHandler.roamAndFind(
      URLS.ARCHIVE_ASSETS_INCOME_TYPES,
      "POST",
      { id: item.id }
    );
    if (res && res.success) {
      this.props.createToastMessage({
        show: true,
        message: "Asset Income Type successfully deleted",
        type: "success",
      });
      this.props.getAllAssetIncomeTypes();
    } else {
      this.props.createToastMessage({
        show: true,
        message: "Error deleting the asset income type. Try again",
        type: "failure",
      });
    }
  };

  handleEditAssetIncomeType = (item) => {
    this.setState({ editMode: true, toEdit: item, show: true });
    let topOfPage = document.getElementById(
      "create-asset-income-type-container"
    );
    topOfPage.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  prepareTableData = () => {
    let { assetIncomeTypes } = this.props;
    let tableData = [];
    if (assetIncomeTypes && assetIncomeTypes.length > 0) {
      // TODO: Tweak table component to give access to manipulation of an entire row, instead of cells
      assetIncomeTypes.forEach((item) => {
        tableData.push([
          <p>{item.name}</p>,
          <p></p>,
          <p></p>,
          <p></p>,
          <p></p>,
          <div className="actions-container">
            <div id="edit" onClick={() => this.handleEditAssetIncomeType(item)}>
              <FontAwesomeIcon icon={faPen} color={"white"} size={"sm"} />
            </div>
            <div
              id="delete"
              onClick={() => this.handleDeleteAssetIncomeType(item)}
            >
              <FontAwesomeIcon icon={faArchive} color={"white"} size={"sm"} />
            </div>
          </div>,
        ]);
      });
    }
    return tableData;
  };

  showAllAssetIncomeTypes = () => {
    let { assetIncomeTypes } = this.props;
    return (
      <div>
        <div style={{ borderRadius: 10 }}>
          <Title>Asset Income Types</Title>

          {assetIncomeTypes && assetIncomeTypes.length ? (
            <Table
              columns={["Name", " ", "  ", "  ", "\t", "Actions"]}
              data={this.prepareTableData()}
              hoverAnimation={false}
            />
          ) : (
            <div id="no-items-container">
              <h3>No Asset Income Types</h3>
            </div>
          )}
        </div>
      </div>
    );
  };

  handleCreate = async (data, reset) => {
    let { toEdit, editMode, showToast } = this.state;
    if (showToast) {
      this.setState({ showToast: false });
    }
    let res;
    if (editMode) {
      res = await ApiCallHandler.send(URLS.UPDATE_ASSETS_INCOME_TYPES, "POST", {
        id: toEdit.id,
        name: data.name,
      });
    } else {
      let ent = getActiveEnterprise()
      res = await ApiCallHandler.send(URLS.CREATE_ASSETS_INCOME_TYPES, "POST", {
        name: data.name,
        enterprise_id: ent?.id
      });
    }

    if (res && res.success) {
      const msg = `Asset Income Type ${
        editMode ? "updated" : "created"
      } successfully`;
      this.setState({
        toEdit: {},
        editMode: false,
      });
      this.props.createToastMessage({
        show: true,
        message: msg,
        type: "success",
      });
      reset();
      this.toggleShow();
      this.props.getAllAssetIncomeTypes();
    } else {
      const msg = `Error ${
        editMode ? "updating" : "creating"
      } the asset income type. Try again`;
      this.props.createToastMessage({
        show: true,
        message: msg,
        type: "danger",
      });
    }
  };
  showFieldValue = () => {
    let { editMode, toEdit } = this.state;
    let value = editMode ? toEdit.name : "";
    return value;
  };

  renderForm = (editMode, status) => {
    if (status) {
      return (
        <div className="scale-and-fade-in">
          <Header
            title={
              !editMode
                ? "Create Asset Income Type"
                : `Edit "${this.state.toEdit.name}" `
            }
            subtitle="Create all your asset income types here"
          />

          <FormGenerator
            elevation={0}
            fields={[
              {
                fieldType: FormGenerator.Fields.INPUT,
                label: "Enter asset income type name",
                name: "name",
                placeholder: "Asset Income Type Name",
                defaultValue: this.showFieldValue(),
                required: true,
              },
            ]}
            onSubmit={this.handleCreate}
            formSubmitBtnText={!editMode ? "create" : "Save Changes"}
            subtitle=""
            title=""
            hasCancel
            cancel={(reset) => {
              reset();
              this.toggleShow();
            }}
          />
        </div>
      );
    } else {
      return (
        <div>
          <Title className="text-toggler" onClick={this.toggleShow}>
            Create new asset income type <FontAwesomeIcon icon={faPlus} />
          </Title>
        </div>
      );
    }
  };

  render() {
    let { editMode, show } = this.state;
    return (
      <div id="create-asset-income-type-container">
        {this.renderForm(editMode, show)}
        {this.showAllAssetIncomeTypes()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    assetIncomeTypes: state.assetIncomeTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllAssetIncomeTypes: () => dispatch(getAssetIncomeTypesAction()),
    createToastMessage: (obj) => dispatch(toggleToastAction(obj)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateAssetIncomeType);
