import {encode as btoa} from 'base-64'
import { parseJSON } from './utils'
require('dotenv').config()



const isLocal = parseJSON(process.env.REACT_APP_IS_LOCAL || false)

const port  = window.location.port
const protocol = window.location.protocol
const domain = window.location.hostname

const local = `${protocol}//${domain}:${port}`
const live = `${protocol}//${domain}`

const AppNames = {
    "lightlify.com": "Lightlify",
    "admin.lightlify.com": "Lightlify - Auth for Enterprise Admin",
    "enterprise.lightlify.com": "Lightlify - Auth for Enterprise Admin",
    "jointlify.com": "Jointlify",
    "admin.jointlify.com": "Jointlify - Auth for Enterprise Admin",
    "enterprise.jointlify.com": "Jointlify - Auth for Enterprise Admin",
    "partnersledger.com": "Partners Ledger",
    "admin.partnersledger.com": "Partners Ledger - Auth for Enterprise Admin",
    "enterprise.partnersledger.com": "Partners Ledger - Auth for Enterprise Admin",
    "kehillah.global": "Kehillah Partners",
    "admin.kehillah.global": "Kehillah Partners - Auth for Enterprise Admin",
    "enterprise.kehillah.global": "Kehillah Partners - Auth for Enterprise Admin",
}

/**
 * Returns a name for the Default authenticator
 * @param {*} domain 
 * @returns 
 */
const getAppNameForAuthenticator = (domain) => {
    domain = domain && domain.toLowerCase()
    if (domain && domain in AppNames) {
        return AppNames[domain]
    }

    return "Auth"
}

const baseUrl = isLocal ? local : live
const payloadData = JSON.stringify({
    "appLogo": "https://i.postimg.cc/25QYx1ST/logooo.png",
    "appName": getAppNameForAuthenticator(domain),
    "redirectTo": `${baseUrl}/success/?entry=`,
    "theme": {
        "primaryColor": "#4A154B",
        "formColor": "#f9f9f9",
        "whiteText": "#f9f9f9",
        "darkText": "#010101",
        "font": ""
    }
})

const encodedString = btoa(payloadData)

const API_HOST = process.env.REACT_APP_API_HOST || "https://gold-dev.herokuapp.com"
const DOMAIN = isLocal ? "http://127.0.0.1:8000" : API_HOST;

let URLS = {}



URLS.AUTH_URL = `https://auth-dev.deal.partners/login/?entry=${encodedString}`;

URLS.LOGIN = DOMAIN + "/auth.login";
URLS.WHO_AM_I = DOMAIN + "/auth.whoami";
URLS.LOGOUT = DOMAIN + "/auth.logout";
URLS.UPDATE_ENTERPRISES = DOMAIN + '/enterprises.update'

URLS.DELETE_ENTERPRISES = DOMAIN + '/enterprises.delete'

URLS.LIST_ENTERPRISES = DOMAIN + '/enterprises.list'

URLS.CREATE_ENTERPRISES = DOMAIN + '/enterprises.create'

URLS.ARCHIVE_ENTERPRISES = DOMAIN + '/enterprises.archive'

URLS.GET_ENTERPRISES = DOMAIN + '/enterprises.info'

URLS.UPDATE_BANK_WALLETS = DOMAIN + '/bank.wallets.update'

URLS.DELETE_BANK_WALLETS = DOMAIN + '/bank.wallets.delete'

URLS.LIST_BANK_WALLETS = DOMAIN + '/bank.wallets.list'

URLS.CREATE_BANK_WALLETS = DOMAIN + '/bank.wallets.create'

URLS.ARCHIVE_BANK_WALLETS = DOMAIN + '/bank.wallets.archive'

URLS.GET_BANK_WALLETS = DOMAIN + '/bank.wallets.info'

URLS.LIST_FOR_ADMIN_ASSETS_DOCUMENTS = DOMAIN + '/assets.documents.listForAdmin'

URLS.UPDATE_ASSETS_DOCUMENTS = DOMAIN + '/assets.documents.update'

URLS.DELETE_ASSETS_DOCUMENTS = DOMAIN + '/assets.documents.delete'

URLS.LIST_ASSETS_DOCUMENTS = DOMAIN + '/assets.documents.list'

URLS.CREATE_ASSETS_DOCUMENTS = DOMAIN + '/assets.documents.create'

URLS.ARCHIVE_ASSETS_DOCUMENTS = DOMAIN + '/assets.documents.archive'

URLS.GET_ASSETS_DOCUMENTS = DOMAIN + '/assets.documents.info'

URLS.UPLOAD_ASSETS_TRACKING_DATA = DOMAIN + '/assets.tracking.data.upload'

URLS.GET_ADMIN_DASHBOARD_DEALS = DOMAIN + '/admin.dashboard.deals.get'

URLS.UPDATE_TEAM_GOALS = DOMAIN + '/team.goals.update'

URLS.DELETE_TEAM_GOALS = DOMAIN + '/team.goals.delete'

URLS.LIST_TEAM_GOALS = DOMAIN + '/team.goals.list'

URLS.CREATE_TEAM_GOALS = DOMAIN + '/team.goals.create'

URLS.ARCHIVE_TEAM_GOALS = DOMAIN + '/team.goals.archive'

URLS.GET_TEAM_GOALS = DOMAIN + '/team.goals.info'

URLS.UPDATE_ASSETS_INSURANCE_TYPES = DOMAIN + '/assets.insurance.types.update'

URLS.DELETE_ASSETS_INSURANCE_TYPES = DOMAIN + '/assets.insurance.types.delete'

URLS.LIST_ASSETS_INSURANCE_TYPES = DOMAIN + '/assets.insurance.types.list'

URLS.CREATE_ASSETS_INSURANCE_TYPES = DOMAIN + '/assets.insurance.types.create'

URLS.ARCHIVE_ASSETS_INSURANCE_TYPES = DOMAIN + '/assets.insurance.types.archive'

URLS.GET_ASSETS_INSURANCE_TYPES = DOMAIN + '/assets.insurance.types.info'

URLS.UPDATE_ASSETS_INCOME = DOMAIN + '/assets.income.update'

URLS.DELETE_ASSETS_INCOME = DOMAIN + '/assets.income.delete'

URLS.LIST_ASSETS_INCOME = DOMAIN + '/assets.income.list'

URLS.CREATE_ASSETS_INCOME = DOMAIN + '/assets.income.create'

URLS.ARCHIVE_ASSETS_INCOME = DOMAIN + '/assets.income.archive'

URLS.GET_ASSETS_INCOME = DOMAIN + '/assets.income.info'

URLS.UPDATE_ASSETS_MANAGERS = DOMAIN + '/assets.managers.update'

URLS.DELETE_ASSETS_MANAGERS = DOMAIN + '/assets.managers.delete'

URLS.LIST_ASSETS_MANAGERS = DOMAIN + '/assets.managers.list'

URLS.CREATE_ASSETS_MANAGERS = DOMAIN + '/assets.managers.create'

URLS.ARCHIVE_ASSETS_MANAGERS = DOMAIN + '/assets.managers.archive'

URLS.GET_ASSETS_MANAGERS = DOMAIN + '/assets.managers.info'

URLS.ADD_ROLES_PERMISSIONS = DOMAIN + '/roles.permissions.add'

URLS.REMOVE_ROLES_PERMISSIONS = DOMAIN + '/roles.permissions.remove'

URLS.GET_USER_DASHBOARD_SETTLED_TRADE = DOMAIN + '/user.dashboard.settled.trade.get'

URLS.UPDATE_ROLES = DOMAIN + '/roles.update'

URLS.DELETE_ROLES = DOMAIN + '/roles.delete'

URLS.LIST_ROLES = DOMAIN + '/roles.list'

URLS.CREATE_ROLES = DOMAIN + '/roles.create'

URLS.ARCHIVE_ROLES = DOMAIN + '/roles.archive'

URLS.GET_ROLES = DOMAIN + '/roles.info'

URLS.UPDATE_DEALS_DOCUMENTS = DOMAIN + '/deals.documents.update'

URLS.DELETE_DEALS_DOCUMENTS = DOMAIN + '/deals.documents.delete'

URLS.LIST_DEALS_DOCUMENTS = DOMAIN + '/deals.documents.list'

URLS.CREATE_DEALS_DOCUMENTS = DOMAIN + '/deals.documents.create'

URLS.ARCHIVE_DEALS_DOCUMENTS = DOMAIN + '/deals.documents.archive'

URLS.GET_DEALS_DOCUMENTS = DOMAIN + '/deals.documents.info'

URLS.UPDATE_ASSETS_EXPENSES = DOMAIN + '/assets.expenses.update'

URLS.DELETE_ASSETS_EXPENSES = DOMAIN + '/assets.expenses.delete'

URLS.LIST_ASSETS_EXPENSES = DOMAIN + '/assets.expenses.list'

URLS.CREATE_ASSETS_EXPENSES = DOMAIN + '/assets.expenses.create'

URLS.ARCHIVE_ASSETS_EXPENSES = DOMAIN + '/assets.expenses.archive'

URLS.GET_ASSETS_EXPENSES = DOMAIN + '/assets.expenses.info'

URLS.UPDATE_ENTERPRISES_DOCUMENTS = DOMAIN + '/enterprises.documents.update'

URLS.DELETE_ENTERPRISES_DOCUMENTS = DOMAIN + '/enterprises.documents.delete'

URLS.LIST_ENTERPRISES_DOCUMENTS = DOMAIN + '/enterprises.documents.list'

URLS.CREATE_ENTERPRISES_DOCUMENTS = DOMAIN + '/enterprises.documents.create'

URLS.ARCHIVE_ENTERPRISES_DOCUMENTS = DOMAIN + '/enterprises.documents.archive'

URLS.GET_ENTERPRISES_DOCUMENTS = DOMAIN + '/enterprises.documents.info'

URLS.UPDATE_USER_PREFERENCES = DOMAIN + '/user.preferences.update'

URLS.DELETE_USER_PREFERENCES = DOMAIN + '/user.preferences.delete'

URLS.LIST_USER_PREFERENCES = DOMAIN + '/user.preferences.list'

URLS.CREATE_USER_PREFERENCES = DOMAIN + '/user.preferences.create'

URLS.ARCHIVE_USER_PREFERENCES = DOMAIN + '/user.preferences.archive'

URLS.GET_USER_PREFERENCES = DOMAIN + '/user.preferences.info'

URLS.UPDATE_ASSETS_TRACKING = DOMAIN + '/assets.tracking.update'

URLS.DELETE_ASSETS_TRACKING = DOMAIN + '/assets.tracking.delete'

URLS.LIST_ASSETS_TRACKING = DOMAIN + '/assets.tracking.list'

URLS.CREATE_ASSETS_TRACKING = DOMAIN + '/assets.tracking.create'

URLS.ARCHIVE_ASSETS_TRACKING = DOMAIN + '/assets.tracking.archive'

URLS.GET_ASSETS_TRACKING = DOMAIN + '/assets.tracking.info'

URLS.UPDATE_ASSETS_MODEL_CONFIG = DOMAIN + '/assets.model.config.update'

URLS.DELETE_ASSETS_MODEL_CONFIG = DOMAIN + '/assets.model.config.delete'

URLS.LIST_ASSETS_MODEL_CONFIG = DOMAIN + '/assets.model.config.list'

URLS.CREATE_ASSETS_MODEL_CONFIG = DOMAIN + '/assets.model.config.create'

URLS.ARCHIVE_ASSETS_MODEL_CONFIG = DOMAIN + '/assets.model.config.archive'

URLS.GET_ASSETS_MODEL_CONFIG = DOMAIN + '/assets.model.config.info'

URLS.GET_USER_DASHBOARD_REFERENCES = DOMAIN + '/user.dashboard.references.get'

URLS.UPDATE_TRADES = DOMAIN + '/trades.update'

URLS.DELETE_TRADES = DOMAIN + '/trades.delete'

URLS.LIST_TRADES = DOMAIN + '/trades.list'

URLS.CREATE_TRADES = DOMAIN + '/trades.create'

URLS.ARCHIVE_TRADES = DOMAIN + '/trades.archive'

URLS.GET_TRADES = DOMAIN + '/trades.info'

URLS.UPDATE_PERMISSIONS = DOMAIN + '/permissions.update'

URLS.DELETE_PERMISSIONS = DOMAIN + '/permissions.delete'

URLS.LIST_PERMISSIONS = DOMAIN + '/permissions.list'

URLS.CREATE_PERMISSIONS = DOMAIN + '/permissions.create'

URLS.ARCHIVE_PERMISSIONS = DOMAIN + '/permissions.archive'

URLS.GET_PERMISSIONS = DOMAIN + '/permissions.info'

URLS.RESET_AUTH_PASSWORD = DOMAIN + '/auth.password.reset'

URLS.UPDATE_TEAMS_POLLS = DOMAIN + '/teams.polls.update'

URLS.DELETE_TEAMS_POLLS = DOMAIN + '/teams.polls.delete'

URLS.LIST_TEAMS_POLLS = DOMAIN + '/teams.polls.list'

URLS.CREATE_TEAMS_POLLS = DOMAIN + '/teams.polls.create'

URLS.ARCHIVE_TEAMS_POLLS = DOMAIN + '/teams.polls.archive'

URLS.GET_TEAMS_POLLS = DOMAIN + '/teams.polls.info'

URLS.GET_ADMIN_DASHBOARD_POLLS = DOMAIN + '/admin.dashboard.polls.get'

URLS.UPDATE_USER_GROUPS = DOMAIN + '/user_groups.update'

URLS.DELETE_USER_GROUPS = DOMAIN + '/user_groups.delete'

URLS.LIST_USER_GROUPS = DOMAIN + '/user_groups.list'

URLS.CREATE_USER_GROUPS = DOMAIN + '/user_groups.create'

URLS.ARCHIVE_USER_GROUPS = DOMAIN + '/user_groups.archive'

URLS.GET_USER_GROUPS = DOMAIN + '/user_groups.info'

URLS.ADD_USER_GROUPS_USERS = DOMAIN + '/user_groups.users.add'

URLS.REMOVE_USER_GROUPS_USERS = DOMAIN + '/user_groups.users.remove'

URLS.GET_ADMIN_DASHBOARD_PREFERENCES = DOMAIN + '/admin.dashboard.preferences.get'

URLS.UPDATE_TEAM_MEMBERS = DOMAIN + '/team_members.update'

URLS.DELETE_TEAM_MEMBERS = DOMAIN + '/team_members.delete'

URLS.LIST_TEAM_MEMBERS = DOMAIN + '/team_members.list'

URLS.CREATE_TEAM_MEMBERS = DOMAIN + '/team_members.create'

URLS.ARCHIVE_TEAM_MEMBERS = DOMAIN + '/team_members.archive'

URLS.GET_TEAM_MEMBERS = DOMAIN + '/team_members.info'

URLS.UPDATE_MOBILE_WALLETS = DOMAIN + '/mobileMoney.wallets.update'

URLS.DELETE_MOBILE_WALLETS = DOMAIN + '/mobileMoney.wallets.delete'

URLS.LIST_MOBILE_WALLETS = DOMAIN + '/mobileMoney.wallets.list'

URLS.CREATE_MOBILE_WALLETS = DOMAIN + '/mobileMoney.wallets.create'

URLS.ARCHIVE_MOBILE_WALLETS = DOMAIN + '/mobileMoney.wallets.archive'

URLS.GET_MOBILE_WALLETS = DOMAIN + '/mobileMoney.wallets.info'

URLS.GET_ADMIN_DASHBOARD_USER_MANAGEMENT = DOMAIN + '/admin.dashboard.user.management.get'

URLS.GET_ADMIN_DASHBOARD_PAYMENTS = DOMAIN + '/admin.dashboard.payments.get'

URLS.UPDATE_TEAMS_MEMBERS_SHARES_ALLOCATIONS = DOMAIN + '/teams.members.shares.allocations.update'

URLS.DELETE_TEAMS_MEMBERS_SHARES_ALLOCATIONS = DOMAIN + '/teams.members.shares.allocations.delete'

URLS.LIST_TEAMS_MEMBERS_SHARES_ALLOCATIONS = DOMAIN + '/teams.members.shares.allocations.list'

URLS.CREATE_TEAMS_MEMBERS_SHARES_ALLOCATIONS = DOMAIN + '/teams.members.shares.allocations.create'

URLS.ARCHIVE_TEAMS_MEMBERS_SHARES_ALLOCATIONS = DOMAIN + '/teams.members.shares.allocations.archive'

URLS.GET_TEAMS_MEMBERS_SHARES_ALLOCATIONS = DOMAIN + '/teams.members.shares.allocations.info'

URLS.GET_USER_DENIED_TRADES = DOMAIN + '/user.denied.trades.get'

URLS.GET_USER_DASHBOARD_PAYMENTS = DOMAIN + '/user.dashboard.payments.get'

URLS.UPDATE_ASSETS_EXPENSES_TYPES = DOMAIN + '/assets.expenses.types.update'

URLS.DELETE_ASSETS_EXPENSES_TYPES = DOMAIN + '/assets.expenses.types.delete'

URLS.LIST_ASSETS_EXPENSES_TYPES = DOMAIN + '/assets.expenses.types.list'

URLS.CREATE_ASSETS_EXPENSES_TYPES = DOMAIN + '/assets.expenses.types.create'

URLS.ARCHIVE_ASSETS_EXPENSES_TYPES = DOMAIN + '/assets.expenses.types.archive'

URLS.GET_ASSETS_EXPENSES_TYPES = DOMAIN + '/assets.expenses.types.info'

URLS.GET_USER_DASHBOARD_GROUPS = DOMAIN + '/user.dashboard.groups.get'

URLS.UPDATE_ASSETS_TYPES = DOMAIN + '/assets.types.update'

URLS.DELETE_ASSETS_TYPES = DOMAIN + '/assets.types.delete'

URLS.LIST_ASSETS_TYPES = DOMAIN + '/assets.types.list'

URLS.CREATE_ASSETS_TYPES = DOMAIN + '/assets.types.create'

URLS.ARCHIVE_ASSETS_TYPES = DOMAIN + '/assets.types.archive'

URLS.GET_ASSETS_TYPES = DOMAIN + '/assets.types.info'

URLS.GET_ADMIN_DASHBOARD_TEAMS = DOMAIN + '/admin.dashboard.teams.get'

URLS.UPDATE_TEAMS_POLLS_RESPONSES = DOMAIN + '/teams.polls.responses.update'

URLS.DELETE_TEAMS_POLLS_RESPONSES = DOMAIN + '/teams.polls.responses.delete'

URLS.LIST_TEAMS_POLLS_RESPONSES = DOMAIN + '/teams.polls.responses.list'

URLS.CREATE_TEAMS_POLLS_RESPONSES = DOMAIN + '/teams.polls.responses.create'

URLS.ARCHIVE_TEAMS_POLLS_RESPONSES = DOMAIN + '/teams.polls.responses.archive'

URLS.GET_TEAMS_POLLS_RESPONSES = DOMAIN + '/teams.polls.responses.info'

URLS.DELETE_ASSETS = DOMAIN + '/assets.delete'

URLS.LIST_ASSETS = DOMAIN + '/assets.list'

URLS.CREATE_ASSETS = DOMAIN + '/assets.create'
URLS.UPDATE_ASSETS = DOMAIN + '/assets.update'

URLS.ARCHIVE_ASSETS = DOMAIN + '/assets.archive'

URLS.GET_ASSETS = DOMAIN + '/assets.info'

URLS.GET_ADMIN_DASHBOARD_MESSAGING = DOMAIN + '/admin.dashboard.messaging.get'

URLS.UPDATE_TEAMS_POLLS_OPTIONS = DOMAIN + '/teams.polls.options.update'

URLS.DELETE_TEAMS_POLLS_OPTIONS = DOMAIN + '/teams.polls.options.delete'

URLS.LIST_TEAMS_POLLS_OPTIONS = DOMAIN + '/teams.polls.options.list'

URLS.CREATE_TEAMS_POLLS_OPTIONS = DOMAIN + '/teams.polls.options.create'

URLS.ARCHIVE_TEAMS_POLLS_OPTIONS = DOMAIN + '/teams.polls.options.archive'

URLS.GET_TEAMS_POLLS_OPTIONS = DOMAIN + '/teams.polls.options.info'

URLS.UPDATE_MESSAGES = DOMAIN + '/messages.update'

URLS.DELETE_MESSAGES = DOMAIN + '/messages.delete'

URLS.LIST_MESSAGES = DOMAIN + '/messages.list'

URLS.CREATE_MESSAGES = DOMAIN + '/messages.create'

URLS.ARCHIVE_MESSAGES = DOMAIN + '/messages.archive'

URLS.GET_MESSAGES = DOMAIN + '/messages.info'

URLS.GET_USER_DASHBOARD_COMMENTS = DOMAIN + '/user.dashboard.comments.get'

URLS.UPDATE_DEALS = DOMAIN + '/deals.update'

URLS.DELETE_DEALS = DOMAIN + '/deals.delete'

URLS.LIST_DEALS = DOMAIN + '/deals.list'

URLS.CREATE_DEALS = DOMAIN + '/deals.create'

URLS.ARCHIVE_DEALS = DOMAIN + '/deals.archive'

URLS.GET_DEALS = DOMAIN + '/deals.info'

URLS.GET_USER_DASHBOARD_DEALS = DOMAIN + '/user.dashboard.deals.get'

URLS.UPDATE_USERS = DOMAIN + '/users.update'

URLS.DELETE_USERS = DOMAIN + '/users.delete'

URLS.LIST_USERS = DOMAIN + '/users.list'

URLS.CREATE_USERS = DOMAIN + '/users.create'

URLS.ARCHIVE_USERS = DOMAIN + '/users.archive'

URLS.GET_USERS = DOMAIN + '/users.info'

URLS.GET_USER_DASHBOARD_TRADES = DOMAIN + '/user.dashboard.trades.get'

URLS.UPDATE_DEALS_MODELS = DOMAIN + '/deals.models.update'

URLS.DELETE_DEALS_MODELS = DOMAIN + '/deals.models.delete'

URLS.LIST_DEALS_MODELS = DOMAIN + '/deals.models.list'

URLS.CREATE_DEALS_MODELS = DOMAIN + '/deals.models.create'

URLS.ARCHIVE_DEALS_MODELS = DOMAIN + '/deals.models.archive'

URLS.GET_DEALS_MODELS = DOMAIN + '/deals.models.info'

URLS.GET_API_DOCS = DOMAIN + '/api.docs.get'

URLS.GET_ADMIN_DASHBOARD_TRADE = DOMAIN + '/admin.dashboard.trade.get'

URLS.GET_ADMIN_DASHBOARD_ASSETS_STATS = DOMAIN + '/admin.dashboard.assets.stats.get'

URLS.UPDATE_ASSETS_INCOME_TYPES = DOMAIN + '/assets.income.types.update'

URLS.DELETE_ASSETS_INCOME_TYPES = DOMAIN + '/assets.income.types.delete'

URLS.LIST_ASSETS_INCOME_TYPES = DOMAIN + '/assets.income.types.list'

URLS.CREATE_ASSETS_INCOME_TYPES = DOMAIN + '/assets.income.types.create'

URLS.ARCHIVE_ASSETS_INCOME_TYPES = DOMAIN + '/assets.income.types.archive'

URLS.GET_ASSETS_INCOME_TYPES = DOMAIN + '/assets.income.types.info'

URLS.ADD_USERS_ROLES = DOMAIN + '/users.roles.add'

URLS.REMOVE_USERS_ROLES = DOMAIN + '/users.roles.remove'

URLS.UPDATE_TEAMS = DOMAIN + '/teams.update'

URLS.DELETE_TEAMS = DOMAIN + '/teams.delete'

URLS.LIST_TEAMS = DOMAIN + '/teams.list'

URLS.CREATE_TEAMS = DOMAIN + '/teams.create'

URLS.ARCHIVE_TEAMS = DOMAIN + '/teams.archive'

URLS.GET_TEAMS = DOMAIN + '/teams.info'

URLS.UPDATE_DISTRIBUTIONS = DOMAIN + '/distributions.update'

URLS.CREATE_FOR_TEAM_DISTRIBUTIONS = DOMAIN + '/distributions.createForTeam'

URLS.DELETE_DISTRIBUTIONS = DOMAIN + '/distributions.delete'

URLS.LIST_DISTRIBUTIONS = DOMAIN + '/distributions.list'

URLS.CREATE_FOR_INDIVIDUAL_DISTRIBUTIONS = DOMAIN + '/distributions.createForIndividual'

URLS.CREATE_FOR_COMPANY_DISTRIBUTIONS = DOMAIN + '/distributions.createForCompany'

URLS.ARCHIVE_DISTRIBUTIONS = DOMAIN + '/distributions.archive'

URLS.GET_DISTRIBUTIONS = DOMAIN + '/distributions.info'

URLS.UPDATE_ASSETS_FEEDS = DOMAIN + '/assets.posts.update'

URLS.DELETE_ASSETS_FEEDS = DOMAIN + '/assets.posts.delete'

URLS.LIST_ASSETS_FEEDS = DOMAIN + '/assets.posts.list'

URLS.CREATE_ASSETS_FEEDS = DOMAIN + '/assets.posts.create'

URLS.ARCHIVE_ASSETS_FEEDS = DOMAIN + '/assets.posts.archive'

URLS.GET_ASSETS_FEEDS = DOMAIN + '/assets.posts.info'

URLS.GET_ADMIN_DASHBOARD_COMPANY = DOMAIN + '/admin.dashboard.company.get'

URLS.UPDATE_PARTNER_DEALS = DOMAIN + '/partnerships.update'

URLS.DELETE_PARTNER_DEALS = DOMAIN + '/partnerships.delete'

URLS.LIST_PARTNER_DEALS = DOMAIN + '/partnerships.list'

URLS.CREATE_PARTNER_DEALS = DOMAIN + '/partnerships.create'

URLS.ARCHIVE_PARTNER_DEALS = DOMAIN + '/partnerships.archive'

URLS.GET_PARTNER_DEALS = DOMAIN + '/partnerships.info'

URLS.GET_USER_TRADE_NEGOTIATION = DOMAIN + '/user.trade.negotiation.get'

URLS.UPDATE_COMMENTS = DOMAIN + '/comments.update'

URLS.DELETE_COMMENTS = DOMAIN + '/comments.delete'

URLS.LIST_COMMENTS = DOMAIN + '/comments.list'

URLS.CREATE_COMMENTS = DOMAIN + '/comments.create'

URLS.ARCHIVE_COMMENTS = DOMAIN + '/comments.archive'

URLS.GET_COMMENTS = DOMAIN + '/comments.info'

URLS.SEND_DEAL_INVITATION_EMAIL = DOMAIN + '/deals.invitations.create'

URLS.GET_DEAL_INVITATION_INFO = DOMAIN +  "/deals.invitations.info"
URLS.ARCHIVE_DEAL_INVITATION = DOMAIN +  "/deals.invitations.archive"

URLS.ACCEPT_INVITATION_PATH = DOMAIN + "/partnerships.create"

URLS.GET_CURRENT_STAKE_IN_DEAL = DOMAIN + "/partnerships.details"
URLS.APPROVE_DEAL_PARTNER = DOMAIN + "/partnerships.approve";
URLS.REJECT_DEAL_PARTNER = DOMAIN + "/partnerships.reject"


URLS.CREATE_DEAL_INCOME = DOMAIN + "/deals.income.create"
URLS.EDIT_DEAL_INCOME = DOMAIN + "/deals.income.update"
URLS.ARCHIVE_DEAL_INCOME = DOMAIN + "/deals.income.archive"
URLS.LIST_DEAL_INCOME = DOMAIN + "/deals.income.list"

URLS.CREATE_DEAL_EXPENSE = DOMAIN + "/deals.expenses.create"
URLS.EDIT_DEAL_EXPENSE = DOMAIN + "/deals.expenses.update"
URLS.ARCHIVE_DEAL_EXPENSE = DOMAIN + "/deals.expenses.archive"
URLS.LIST_DEAL_EXPENSE = DOMAIN + "/deals.expenses.list"
URLS.UPDATE_ENTERPRISES_LOGO = DOMAIN + "/enterprises.logo.update";
URLS.UPDATE_ASSETS_IMAGE = DOMAIN + "/assets.image.update";

URLS.CREATE_DEAL_INCOME_CONFIG  = DOMAIN + "/deals.income.config.create";
URLS.DELETE_DEAL_INCOME_CONFIG = DOMAIN + "/deals.income.config.delete";
URLS.UPDATE_DEAL_INCOME_CONFIG = DOMAIN + "/deals.income.config.update";

URLS.CREATE_DEAL_EXPENSE_CONFIG  = DOMAIN + "/deals.expense.config.create";
URLS.DELETE_DEAL_EXPENSE_CONFIG = DOMAIN + "/deals.expense.config.delete";
URLS.UPDATE_DEAL_EXPENSE_CONFIG = DOMAIN + "/deals.expense.config.update";

URLS.CREATE_PAYOUT  = DOMAIN + "/deals.payouts.create";
URLS.EDIT_PAYOUT_PAYOUT = DOMAIN + "/deals.payouts.update";
URLS.ARCHIVE_PAYOUT = DOMAIN + "/deals.payouts.archive";

URLS.ADD_ASSET_TO_DEAL = DOMAIN + "/deals.assets.create"

URLS.CREATE_DEAL_FEED = DOMAIN + "/deals.posts.create"
URLS.ARCHIVE_DEAL_FEED = DOMAIN + "/deals.posts.archive"
URLS.UPDATE_DEAL_FEED = DOMAIN + "/deals.posts.update"

URLS.GET_DEAL_STATS = DOMAIN + "/deal.stats.info";



URLS.CREATE_DEAL_ASSET_TYPE = DOMAIN + "/deals.assets.types.create"
URLS.UPDATE_DEAL_ASSET_TYPE = DOMAIN + "/deals.assets.types.update"
URLS.DELETE_NEW_DEAL_ASSET_TYPE = DOMAIN + "/deals.assets.types.delete"

URLS.CREATE_NEW_DEAL_ASSET = DOMAIN + "/deals.assets.create"
URLS.UPDATE_NEW_DEAL_ASSET = DOMAIN + "/deals.assets.update"
URLS.UPDATE_DEAL_ASSET_IMAGE = DOMAIN + "/deal.assets.image.update";

URLS.CREATE_NEW_DEAL_INCOME_TYPE = DOMAIN + "/deals.income.types.create"
URLS.UPDATE_NEW_DEAL_INCOME_TYPE = DOMAIN + "/deals.income.types.update"


URLS.CREATE_NEW_DEAL_EXPENSE_TYPE = DOMAIN + "/deals.expenses.types.create"
URLS.UPDATE_NEW_DEAL_EXPENSE_TYPE = DOMAIN + "/deals.expenses.types.update"

URLS.CREATE_NEW_DEAL_DISTRIBUTION = DOMAIN + "/deals.distributions.create"
URLS.UPDATE_NEW_DEAL_DISTRIBUTION = DOMAIN + "/deals.distributions.update"


URLS.CREATE_NEW_DEAL_DISTRIBUTION_COMMENT = DOMAIN + "/deals.distributions.comments.create"


URLS.CREATE_ENTERPRISE_USER_INVITATION = DOMAIN + "/enterprises.users.invitations.create"
URLS.UPDATE_ENTERPRISE_USER_INVITATION = DOMAIN + "/enterprises.users.invitations.update"
URLS.LIST_ENTERPRISE_USER_INVITATIONS = DOMAIN + "/enterprises.users.invitations.list"
URLS.ARCHIVE_ENTERPRISE_USER_INVITATIONS = DOMAIN + "/enterprises.users.invitations.archive"

URLS.ACCEPT_PENDING_ENTERPRISE_USER_INVITATION = DOMAIN + "/enterprises.users.invitations.accept"

URLS.CREATE_DEAL_ASSET_DOCUMENT = DOMAIN + "/deals.assets.documents.create";
URLS.UPDATE_DEAL_ASSET_DOCUMENT = DOMAIN + "/deals.assets.documents.update";
URLS.ARCHIVE_DEAL_ASSET_DOCUMENT = DOMAIN + "/deals.assets.documents.archive";

URLS.LIST_COUNTRY_ENTERPRISE_REQUIREMENTS =DOMAIN + "/countries.enterprises.requirements.list";


URLS.UPDATE_ENTERPRISE_COMPLIANCES = DOMAIN + "/enterprises.compliances.update";
URLS.CREATE_ENTERPRISE_COMPLIANCES = DOMAIN + "/enterprises.compliances.create";


URLS.CREATE_ENTERPRISE_PREFERENCES = DOMAIN + "/enterprises.preferences.create";
URLS.UPDATE_ENTERPRISE_PREFERENCES = DOMAIN + "/enterprises.preferences.update";
URLS.ARCHIVE_ENTERPRISE_PREFERENCES = DOMAIN + "/enterprises.preferences.archive";

URLS.LIST_ENTERPRISE_PARTNERS = DOMAIN + "/enterprises.partners.list";
URLS.GET_USERS_OTHER_DEALS = DOMAIN + "/users.other.partnerships.get";

URLS.CREATE_CONTACTS = DOMAIN + "/contacts.create";
URLS.UPDATE_CONTACTS = DOMAIN + "/contacts.update";

URLS.CREATE_ENTERPRISE_PAYMENT_ELECTIONS = DOMAIN + "/enterprises.payment.elections.create";
URLS.UPDATE_ENTERPRISE_PAYMENT_ELECTIONS = DOMAIN + "/enterprises.payment.elections.update";
URLS.DELETE_ENTERPRISE_PAYMENT_ELECTIONS = DOMAIN + "/enterprises.payment.elections.delete";
URLS.UPDATE_USER_IMAGE = DOMAIN + "/user.image.update";

URLS.CREATE_DEAL_ASSET_MANAGER = DOMAIN + "/user.roles.create";
URLS.UPDATE_DEAL_ASSET_MANAGER = DOMAIN + "/user.roles.update";
URLS.ARCHIVE_DEAL_ASSET_MANAGER = DOMAIN + "/user.roles.archive";

URLS.CREATE_TAG = DOMAIN + "/tags.create";
URLS.UPDATE_TAG = DOMAIN + "/tags.update";
URLS.LIST_TAGS = DOMAIN + "/tags.list";

URLS.REQUEST_COUNTRY_REQUIREMENTS =DOMAIN + "/verification.requirements.request.get";






// External urs
URLS.GET_EXCHANGE_RATES = "https://api.exchangerate-api.com/v4/latest/";
export default URLS