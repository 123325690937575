import React, { Component } from "react";
import ApiCallHandler from "shared/js/ApiCallHandler";
import URLS from "shared/js/urls";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  toggleToastAction,
  setEditSetterAction,
  editMutationAction,
} from "store/actions/actions";
import { Stepper, Spinner } from "@kehillahglobal/ui";
import { faCubes, faEye, faInfo } from "@fortawesome/free-solid-svg-icons";
import BasicInfo from "./creation steps/BasicInfo";
import SharesInfo from "./creation steps/SharesInfo";
import LiveInfo from "./creation steps/LiveInfo";
import { DEAL_STATUS } from "shared/js/typeConstants";
import {
  getActiveEnterprise,
  getRandomStringKey,
  hasDatePassed,
  hasEmptyFields,
  isEmpty,
  parseJSON,
  removeNullFields,
} from "shared/js/utils";
import { withRouter } from "react-router-dom";

class CreateDeal extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      data: {
        name: "",
        about: "",
        has_started: "",
        close_date: "",
        status: "",
        shares: "",
        price_per_share: "",
        carry: "",
        mgmt_fee: "",
        deal_running_period_in_months: "",
        id: "",
        tags: "",
        setup_cost: "",
        image: "",
      },
      loading: false,
      key: null,
      dealID: "",
    };
  }

  componentDidMount() {
    if (this.props?.match?.params?.id) {
      this.setState({ dealID: this.props?.match?.params?.id });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.dealID) {
      let opp = props.deals.find((inv) => inv.id === state.dealID);
      if (opp?.id) {
        let wantedFields = Object.keys(state.data);
        let data = {};

        Object.entries(opp).forEach(async ([key, value]) => {
          if (wantedFields?.includes(key)) {
            data[key] = value;
          }
        });
        data.tags = parseJSON(isEmpty(data?.tags) ? [] : data?.tags);
        return {
          data: hasEmptyFields(state.data)
            ? { ...data, ...{} }
            : { ...data, ...state.data },
          key: isEmpty(state.key) ? getRandomStringKey() : state.key,
        };
      }
    }
    return null;
  }

  updateReduxState = (isEdit, json) => {
    let { updateRedux, deals } = this.props;
    if (!isEdit) {
      updateRedux(deals || [], json?.data);
    } else {
      updateRedux(deals, json?.data[0]);
    }
  };

  async createBackendInv(data) {
    const json = await ApiCallHandler.send(
      URLS.CREATE_DEALS,
      ApiCallHandler.POST,
      data
    );
    return json;
  }

  async editBackendInv(data) {
    const json = await ApiCallHandler.send(
      URLS.UPDATE_DEALS,
      ApiCallHandler.POST,
      data
    );
    return json;
  }

  async onSubmit(data) {
    if (!data) return;
    data = removeNullFields(data);

    if (data?.close_date) {
      let isDatePassed = hasDatePassed(data?.close_date);
      if (isDatePassed) {
        return this.props.toggleToast({
          show: true,
          message: "Close date cannot be in the past",
          type: "failure",
        });
      }
    }

    this.setState({ loading: true });
    let { dealID } = this.state;
    data = {
      ...data,
      image: data?.image?.data?.file?.data
        ? data?.image?.data?.file?.data
        : null,
      enterprise_id: getActiveEnterprise()?.id,
    };
    if (isEmpty(data?.status)) {
      data.status = DEAL_STATUS.DRAFT;
    }
    if (!data?.image) {
      delete data?.image;
    }
    if (!isEmpty(data?.tags)) {
      data.tags = JSON.stringify(data?.tags);
    } else {
      data.tags = [];
    }

    let json = {};
    if (!dealID) {
      json = await this.createBackendInv(data);
    } else {
      data = { ...data, id: dealID };
      json = await this.editBackendInv(data);
    }

    if (json?.success) {
      this.setState({ loading: false });
      this.updateReduxState(dealID, json);
      if (!dealID) {
        this.props.history.push(
          `/enterprise/${getActiveEnterprise()?.id}/deals/drafted/${
            json?.data?.id
          }`
        );
        return;
      }
      if (json?.data[0]?.status === DEAL_STATUS.LIVE)
        return this.props.history.push(
          `/enterprise/${getActiveEnterprise()?.id}/deals`
        );
      this.props.history.goBack();
    }

    if (json?.success === false) {
      this.setState({ loading: false });
      return this.props.toggleToast({
        show: true,
        message: json?.error,
        type: "failure",
      });
    }
  }

  collectFormData = (data) => {
    this.setState({
      data: {
        ...this.state.data,
        ...data,
      },
    });
  };

  onCancel = () => {
    this.props.history.goBack();
  };

  render() {
    const steps = [
      {
        name: "Basic Info",
        component: (
          <BasicInfo
            onStateChange={this.collectFormData}
            data={this.state?.data}
          />
        ),
        icon: faInfo,
      },
      {
        name: "Shares & Fees ",
        component: (
          <SharesInfo
            onStateChange={this.collectFormData}
            data={this.state?.data}
          />
        ),
        icon: faCubes,
      },
      {
        name: "Deal Stage",
        component: (
          <LiveInfo
            onStateChange={this.collectFormData}
            data={this.state?.data}
          />
        ),
        icon: faEye,
      },
    ];
    const onNext = (step) => {
      if (step === steps?.length - 1) {
        this.onSubmit(this.state.data);
      }
    };

    return (
      <div key={this.state.key}>
        {this.state.loading ? (
          <div id="loading-overlay-root">
            <div id="activity-indicators-container">
              <Spinner variation={"TwoHalfCirclesType"} />
              <p>Please wait ...</p>
            </div>
          </div>
        ) : (
          <Stepper
            variant={"horizontal"}
            steps={steps}
            onNext={onNext}
            onCancel={this.onCancel}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    deals: state.deals,
    enterprises: state.enterprises,
    tags: state.tags,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleToast: toggleToastAction,
      setEditPayload: (val) => dispatch(setEditSetterAction(val)),
      updateRedux: (current_data, new_data) =>
        dispatch(editMutationAction("DEAL", current_data, new_data)),
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateDeal)
);
