import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  getActiveEnterprise,
  isEmpty,
  getRandomStringKey,
} from "shared/js/utils";
import URLS from 'shared/js/urls';
import { bindActionCreators } from 'redux';
import { editMutationAction } from 'store/actions/actions';
import { FormGenerator } from '@kehillahglobal/ui';

class BasicInfo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      key:null,
    }
  }

  componentDidMount() {
    if (this.props.data) {
      this.setState({
        data: this.props.data
      });
    }
  }
  static getDerivedStateFromProps(props, state) {
    if (props.data) {
      if (!state.key) {
        return {
          data: props.data,
          key: getRandomStringKey(),
        };
      }
      return {
        data: props.data,
      };
    }
    return null;
  }

  updateFieldAndRedux = (res)=>{
    let { data } = this.state;
    let { updateItemInredux,onStateChange, tags} = this.props
    updateItemInredux(tags||[], res?.data)
    data.tags = [...data.tags, res?.data?.name];
    onStateChange(data)
    this.setState({ key: getRandomStringKey() })
  }



  render() {
    let {tags} =  this.props
    let { data } = this.state;
    const getFieldValue = (field) => {
      let value = !isEmpty(data) ? data[field] : "";
      return value;
    };
    
    const formData = [
      {
        fieldType: FormGenerator.Fields.INPUT,
        label: "What would you like to call this deal?",
        name: "name",
        placeholder: "Deal name...",
        required: true,
        value: getFieldValue("name"),
      },
      {
        fieldType: FormGenerator.Fields.INPUT,
        label: "What is the initial setup cost of this deal?",
        name: "setup_cost",
        placeholder: "Enter initial setup cost",
        required: true,
        value: getFieldValue("setup_cost"),
      },
      {
        fieldType: FormGenerator.Fields.DROPDOWN,
        placeholder: "Add tags eg.Transport, Real Estate.",
        label: "Tags",
        value: typeof data?.tags === "string" ? [] : data?.tags,
        name: "tags",
        elevation: 0,
        multiple: true,
        data: (tags || [])?.map((tag) => tag?.name),
        required: true,
        helpText:
          "Add tags to help you find this Deal. You can select multiple tags if this deal is related to those things",
        child: {
          fields: [
            {
              fieldType: FormGenerator.Fields.INPUT,
              label: "Tag",
              name: "name",
              placeholder: "Tag Name",
              required: true,
              value: "",
            },
          ],
          metaData: {
            endpoint: URLS.CREATE_TAG,
            additionalData: {
              enterprise_id: getActiveEnterprise()?.id,
            },
            onSuccess: (res) => {
              this.updateFieldAndRedux(res);
            },
          },
        },
      },

      {
        fieldType: FormGenerator.Fields.DATE,
        label: "When does this deal close?",
        name: "close_date",
        placeholder: "Enter close date...",
        required: true,
        value: getFieldValue("close_date")?.split("T")[0],
      },
      {
        fieldType: FormGenerator.Fields.INCREMENT,
        label: "Period This Deal Will Run In Months",
        value: getFieldValue("deal_running_period_in_months") || 12,
        name: "deal_running_period_in_months",
        elevation: 0,
        helpText: "How many months will this deal run for?",
      },
      {
        fieldType: FormGenerator.Fields.RICHTEXT,
        label: "What can you say about this deal?",
        name: "about",
        placeholder: "Brief description of what this deal is about...",
        required: true,
        value: getFieldValue("about"),
      },
      {
        fieldType: FormGenerator.Fields.FILE,
        label: "Add an image representation of this deal",
        name: "image",
        placeholder: "",
        required: false,
        value: getFieldValue("image"),
      },
    ];
    return (
      <div key={this.state.key}>
        <FormGenerator
          elevation={0}
          subtitle=""
          title=""
          fields={formData}
          hideSubmitBtn={true}
          onStateChange={this.props.onStateChange && this.props.onStateChange}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    tags:state.tags
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    updateItemInredux: (current_data, new_data) =>
      dispatch(editMutationAction("TAGS", current_data, new_data)),
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(BasicInfo)