import EnterpriseList from "pages/auth/EnterprisesList";
import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "./pages/dashboard/Dashboard";
import ErrorPage from "./pages/error/ErrorPage";
import Landing from "./pages/landing/Landing";
import Playground from "./pages/Playground";
import AllAssets from "pages/assets/listings/AllAssets";
import PageWrapper from "components/page wrapper/PageWrapper";
import AssetSetupAndConfig from "./pages/assets/setup and config/AssetSetupAndConfig";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CreateAndListWrapper from "pages/assets/create/CreateAndListWrapper";
import {
  toggleToastAction,
  setManageAssetTabIdAction,
  toggleModalAction,
  toggleLoadingBlanket,
  getAllDealsAction,
  toggleImagePreviewAction,
  getEnterpriseUserInvitationAction,
  setAllEnterprisesAction,
  getAllPartnersAction,
  getExchangeRatesAction,
  getCurrentEnterpriseDashboardContentsAction,
  getAllTagsAction,
  getLoginUserInfoAction,
  setCurrentEnterpriseAction,
} from "store/actions/actions";
import {
  getEnterprisesActions,
  getAllCountryEnterpriseRequirementsAction,
} from "./store/actions/actions";
import URLS from "shared/js/urls";
import { version } from "../package.json";
import { extractEnterprises, extractOtherEnterprises } from "shared/js/utils";
import ApiCallHandler from "shared/js/ApiCallHandler";


import LoadingPage from "shared/components/loading/LoadingPage";
import EnterpriseSettingsWrapper from "./pages/enterprise/EnterpriseSettingWrapper";
import AssetDetailWrapper from "./pages/assets/asset detail/AssetDetailWrapper";
import AllDistributionsForSinglePayout from "pages/deals/single deal/payouts/individual payout distributions/AllDistributionsForSinglePayout";
import SingleDistributionView from "pages/deals/single deal/payouts/individual payout distributions/SingleDistributionView";
import ImagePreview from "./shared/components/ImagePreview/ImagePreview";
import EnterpriseUsersWrapper from "pages/enterprise/EnterpriseUsersWrapper";
import EnterpriseVerification from "./pages/enterprise verification/EnterpriseVerification";
import DealsWrapper from "./pages/deals/DealsWrapper";
import DealsShowWrapper from "./pages/deals/single deal/DealsShowWrapper";
import CreateDeals from "pages/deals/create deal/CreateDeal";
import TermAndConditions from "pages/terms and conditions/TermAndConditions";
import EnterprisePartnersList from "./pages/enterprise-partners/list/EnterprisePartnersList";
import PartnerDetail from "./pages/enterprise-partners/detail/PartnerDetail";
import WalletsWrapper from "./pages/wallets/WalletsWrapper";
import CreateEnterprise from "pages/enterprise/create enterprise/CreateEnterprise";
import DraftedDealsWrapper from "pages/deals/single deal/DraftedDealsWrapper";
import DealUpdateDetail from "./pages/deals/single deal/feeds/feed-details/DealFeedDetail";
import DealAssetDetailWrapper from "pages/deals/single deal/deal-assets/asset detail/DealAssetDetailWrapper";
import AdminProfileSettings from "pages/enterprise/admin profile settings/AdminProfileSettings";
import AuthenticateUser from "pages/auth";


import {Modal, Toast, Spinner} from '@kehillahglobal/ui'

import '@kehillahglobal/ui/dist/index.css'

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: false,
    };
  }

  makeAPICalls = () => {
    this.props.getDeals();
    this.props.getEnterpriseUserInvitations();
    this.props.getCountryEnterpriseRequirements();
    this.props.getEnterprisePartners();
    this.props.getEveryEnterprise();
    this.props.getTags();
  };

  updateEnterpriseLists = (res) => {
    const myEnterprises = extractEnterprises(res?.data);
    const myOtherEnterprises = extractOtherEnterprises(res?.data);
    let allEnterprises = [...myEnterprises, ...myOtherEnterprises];
    let enterpriseId = window.location.href.split("/")[4];
    if (enterpriseId) {
      const activeEnterprise =
        allEnterprises.find((item) => item?.id === enterpriseId) || {};
      this.props.setCurrentEnterprise(activeEnterprise);
    }
    this.props.getAllEnterprises(allEnterprises);
  };

  whoAmI = async () => {
    let res = await ApiCallHandler.send(URLS.WHO_AM_I, "POST", {});
    if (res?.data) {
      this.setState({ authenticated: true });

      this.updateEnterpriseLists(res);
      this.props.getLoggedInUser(res?.data);

      this.makeAPICalls();

      if (window.location.pathname === "/") {
        window.location.pathname = "/enterprises";
      }
    } else {
      if (window.location.pathname !== "/") {
        if (window.location.pathname.indexOf("/success/") < 0) {
          window.location.pathname = "/";
        }
      }
    }
  };


  titles = ["Kehillah | Deals"];
  async componentDidMount() {
    document.title = this.titles[0];
    this.whoAmI();
  }

  render() {
    const { blanketProps } = this.props;
    if (blanketProps.show)
      return (
        <LoadingPage
          {...blanketProps.props}
          close={this.props.toggleLoadingBlanket}
        />
      );

    return (
      <>
        <NotificationManager
          close={this.props.toggleToast}
          {...this.props.toastProps}
        />
        <ModalManager
          close={this.props.toggleModal}
          stack={this.props.modalProps}
        />
        <ImagePreviewManager {...this.props.imagePreviewProps} />
        <Router>
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route exact path="/play" component={Playground} />
            <Route
              exact
              path="/terms-and-conditions"
              component={TermAndConditions}
            />

            <Route exact path="/success" component={AuthenticateUser} />
            <Route exact path="/enterprises" component={EnterpriseList} />
            <AuthenticatedRoutes
              {...this.props}
              authenticated={this.state.authenticated}
            />

            {/* ------------------- THIS SHOULD BE THE LAST ROUTE AT ALL TIMES --------------_ */}
            <Route path="*" component={ErrorPage} />
          </Switch>
        </Router>
        <div
          style={{ position: "fixed", bottom: 5, right: 20 }}
          className="version-number"
        >
          <p style={{ color: "grey", fontSize: "0.9rem" }}>v{version}</p>
        </div>
      </>
    );
  }
}

const AuthenticatedRoutes = (props) => {
  const params = window.location.href.toString().split("?")[1];
  const user = props.user;

  if (!user?.id) {
    return <LoadingPage text={"Preparing For You 🤗"} />;
  } else if (!params) {
    if (!user?.has_accepted_tcs) {
      window.location.href = "/terms-and-conditions";
    }
    if (!user?.id) {
      window.location.href = "/";
    }
  }

  if (props.authenticated) {
    props.getExchangeRates();
    props.getDashboardStats();

    return (
      <>
        <PageWrapper>
          <Switch>
            <Route
              exact
              path="/enterprise/:enterprise/dashboard"
              component={Dashboard}
            />
            <Route
              exact
              path="/enterprise/:enterprise/manage"
              component={EnterpriseSettingsWrapper}
            />
            <Route
              exact
              path="/enterprise/:enterprise/users"
              component={EnterpriseUsersWrapper}
            />
            <Route
              exact
              path="/asset/manage"
              component={CreateAndListWrapper}
            />
            <Route
              exact
              path="/asset/tools/:page"
              component={AssetSetupAndConfig}
            />
            <Route
              exact
              path="/enterprise/create"
              component={CreateEnterprise}
            />
            <Route exact path="/asset/listing" component={AllAssets} />
            <Route
              exact
              path="/enterprise/:enterprise/deals"
              component={DealsWrapper}
            />
            <Route
              exact
              path="/enterprise/:enterprise/partners"
              component={EnterprisePartnersList}
            />
            <Route
              exact
              path="/enterprise/:enterprise/partners/:id"
              component={PartnerDetail}
            />
            <Route
              exact
              path="/enterprise/:enterprise/deal/create"
              component={CreateDeals}
            />
            <Route
              exact
              path="/enterprise/:enterprise/deal/:id/edit"
              component={CreateDeals}
            />
            <Route
              exact
              path="/enterprise/:enterprise/deals/drafted/:id"
              component={DraftedDealsWrapper}
            />
            <Route
              exact
              path="/enterprise/:enterprise/deals/:id"
              component={DealsShowWrapper}
            />
            <Route
              exact
              path="/enterprise/:enterprise/deals/:deal/feeds/:id"
              component={DealUpdateDetail}
            />
            <Route
              exact
              path="/enterprise/:enterprise/deals/:dealID/asset/:id"
              component={DealAssetDetailWrapper}
            />

            <Route
              exact
              path="/assets/asset-detail"
              component={AssetDetailWrapper}
            />
            <Route
              exact
              path="/enterprise/:enterprise/deals/:dealID/payout-distributions/:id"
              component={AllDistributionsForSinglePayout}
            />
            <Route
              exact
              path="/enterprise/:enterprise/admin/profile"
              component={AdminProfileSettings}
            />
            <Route
              exact
              path="/enterprise/:enterprise/deals/:dealID/payouts/:payoutID/distributions/:distributionID"
              component={SingleDistributionView}
            />
            <Route
              exact
              path="/enterprise/:enterprise/verification"
              component={EnterpriseVerification}
            />
            <Route
              exact
              path="/enterprise/:enterprise/wallets"
              component={WalletsWrapper}
            />
            <Route component={ErrorPage} />
          </Switch>
        </PageWrapper>
      </>
    );
  } else {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner variation={"TwoHalfCirclesType"} />
      </div>
    );
  }
};

const ModalManager = ({ stack, close }) => {
  if (stack?.length > 0) {
    return (
      <Modal close={() => close()} {...stack[0]?.props}>
        {stack[0]?.props?.children}
      </Modal>
    );
  }
  return <></>;
};
const NotificationManager = (props) => {
  if (props.show) return <Toast close={() => props.close()} {...props} />;
  return <></>;
};

const ImagePreviewManager = (props) => {
  if (props.show) return <ImagePreview {...props} />;
  return <></>;
};

const mapStateToProps = (state) => {
  return {
    toastProps: state.toastProps,
    modalProps: state.modalProps,
    blanketProps: state.blanketProps,
    imagePreviewProps: state.imagePreviewProps,
    enterprises: state.enterprises,
    user: state.loggedInUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleToast: toggleToastAction,
      getAssetTab: setManageAssetTabIdAction,
      toggleModal: toggleModalAction,
      toggleLoadingBlanket,
      getDeals: getAllDealsAction,
      toggleImagePreview: toggleImagePreviewAction,
      getEnterpriseUserInvitations: getEnterpriseUserInvitationAction,
      getAllEnterprises: setAllEnterprisesAction,
      getEveryEnterprise: getEnterprisesActions,
      getCountryEnterpriseRequirements:
        getAllCountryEnterpriseRequirementsAction,
      getEnterprisePartners: getAllPartnersAction,
      getExchangeRates: getExchangeRatesAction,
      getDashboardStats: getCurrentEnterpriseDashboardContentsAction,
      getTags: getAllTagsAction,
      getLoggedInUser: getLoginUserInfoAction,
      setCurrentEnterprise: setCurrentEnterpriseAction,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
