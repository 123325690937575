import React, { useState } from "react";
import "./DealSettings.css";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  editMutationAction,
  toggleModalAction,
  toggleToastAction,
} from "store/actions/actions";
import URLS from "shared/js/urls";
import ApiCallHandler from "shared/js/ApiCallHandler";
import { DEAL_STATUS } from "shared/js/typeConstants";
import Processing from "shared/components/processing/Processing";
import LoadingPage from "shared/components/loading/LoadingPage";
import { getActiveEnterprise } from "shared/js/utils";

export default function DealSettings() {
  const [loading, setLoading] = useState(false);
  let params = useParams();
  let dispatch = useDispatch();
  let history = useHistory();
  let deals = useSelector((state) => state.deals);
  let deal = deals.find((deal) => deal.id === params?.id);

  const GO_LIVE = "LIVE";
  const ARCHIVE = "ARCHIVE";
  const RESTORE = "RESTORE";

  let draftedDealData = [
    {
      id: 1,
      title: "Edit deal",
      description:
        "This will send you to the edit page for this deal. You can make changes to the deal here.",
      buttonText: "Edit deal",
      onButtonClick: () =>
        history.push(
          `/enterprise/${getActiveEnterprise()?.id}/deal/${deal?.id}/edit`
        ),
      colorTheme: "grey",
    },
    {
      id: 2,
      title: "Make this deal live",
      description:
        "This will make this deal live and remove it from your list of drafted deals.",
      buttonText: "Go Live",
      onButtonClick: () => renderConfirmation(GO_LIVE),
      colorTheme: "rgb(40, 167, 69)",
    },
  ];

  let liveAndArchivedDealData = [
    {
      id: 1,
      title: "Edit deal",
      description:
        "This will send you to the edit page for this deal. You can make changes to the deal here.",
      buttonText: "Edit deal",
      onButtonClick: () =>
        history.push(
          `/enterprise/${getActiveEnterprise()?.id}/deal/${deal?.id}/edit`
        ),
      colorTheme: "grey",
    },
    deal.status === DEAL_STATUS.LIVE
      ? {
          id: 2,
          title: "Set As Completed",
          description:
            "This will set this deal as completed and remove it from your list of live deals. You can add it back to your list of live deals by clicking the 'Restore' button.",
          buttonText: "Set As Completed",
          onButtonClick: () => renderConfirmation(ARCHIVE),
          colorTheme: "tomato",
        }
      : {
          id: 2,
          title: "Restore this deal to live",
          description:
            "This will restore this deal to live and add it to your list of deals.",
          buttonText: "Restore Deal",
          onButtonClick: () => renderConfirmation(RESTORE),
          colorTheme: "tomato",
        },
  ];

  const handleDealOperations = (operation) => {
    const status =
      operation === ARCHIVE ? DEAL_STATUS.COMPLETED : DEAL_STATUS.LIVE;
    setLoading(true);
    ApiCallHandler.send(URLS.UPDATE_DEALS, "POST", {
      id: deal.id,
      status: status,
    }).then((res) => {
      setLoading(false);
      if (res?.success) {
        dispatch(editMutationAction("DEAL", deals, res?.data[0]));
        history.push(`/enterprise/${params?.enterprise}/deals`);
      } else {
        dispatch(
          toggleToastAction({
            show: true,
            message: "An error occurred. Try again",
            type: "failure",
          })
        );
      }
    });
  };

  const renderConfirmation = (operation) => {
    return dispatch(
      toggleModalAction({
        show: true,
        props: {
          title: `Confirmation`,
          children: (
            <div style={{ padding: "20px 15px" }}>
              <p>
                {operation === ARCHIVE
                  ? "This action will move this deal to the completed deals list. Are you sure you want to proceed?"
                  : operation === RESTORE
                  ? "This action will move this deal to the live list. Are you sure you want to proceed?"
                  : "This action will move this deal to the live list. This action can not be undone. Are you sure you want to proceed?"}
              </p>
            </div>
          ),
          themeColor: "maroon",
          size: "medium",

          cancel: true,
          okay: {
            text: "Yes",
            function: () => handleDealOperations(operation),
          },
        },
      })
    );
  };

  const renderDangerAreaTemplate = (data) => {
    return (
      <div className="danger-item-container">
        <div className="danger-item-description-container">
          <h3>{data?.title}</h3>
          <p>{data?.description}</p>
        </div>
        <div>
          <div
            className="edit-deal-title"
            style={{
              color: data?.colorTheme,
              border: `1px solid ${data?.colorTheme}`,
              "&:hover": {
                color: "white",
                backgroundColor: data?.colorTheme,
              },
            }}
            onClick={data?.onButtonClick}
            theme={data?.colorTheme}
          >
            <span>{data?.buttonText}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderDangerArea = () => {
    return (
      <div>
        <div className="danger-area">
          {(deal?.status === DEAL_STATUS.DRAFT
            ? draftedDealData
            : liveAndArchivedDealData
          ).map((item) => renderDangerAreaTemplate(item))}
        </div>
      </div>
    );
  };
  return deal?.id ? (
    <div className="danger-root">
      <div>{renderDangerArea()}</div>
      {loading && <Processing text={"processing ...."} />}
    </div>
  ) : (
    <LoadingPage text={"fetching data..."} />
  );
}
