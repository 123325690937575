export const DEAL_OVERVIEW_TAB_ID = "deal_overview";
export const DEAL_FEEDS_TAB_ID = "deal-feeds";
export const DEAL_ASSETS_TAB_ID = "deal-assets";
export const DEAL_CONFIG_TAB_ID = "deal-config";
export const DEAL_DOCUMENTS_TAB_ID = "deal-documents";
export const DEAL_PARTNERS_TAB_ID = "deal-partners";
export const DEAL_REVENUE_TAB_ID = "deal-revenue";
export const DEAL_EXPENSES_TAB_ID = "deal-expenses";
export const DEAL_PAYOUTS_TAB_ID = "deal-payouts";
export const DEAL_SETTINGS_TAB_ID = "deal-settings";


export const DRAFT = "draft-"


export const TAB_IDS = [
  DEAL_OVERVIEW_TAB_ID,
  DEAL_FEEDS_TAB_ID,
  DEAL_ASSETS_TAB_ID,
  DEAL_CONFIG_TAB_ID,
  DEAL_DOCUMENTS_TAB_ID,
  DEAL_PARTNERS_TAB_ID,
  DEAL_REVENUE_TAB_ID,
  DEAL_EXPENSES_TAB_ID,
  DEAL_PAYOUTS_TAB_ID,
  DEAL_SETTINGS_TAB_ID,
];

export const DRAFT_TAB_IDS = [
  DRAFT + DEAL_ASSETS_TAB_ID,
  DRAFT + DEAL_DOCUMENTS_TAB_ID,
  DRAFT + DEAL_OVERVIEW_TAB_ID,
  DRAFT + DEAL_PARTNERS_TAB_ID,
  DRAFT + DEAL_SETTINGS_TAB_ID,
];