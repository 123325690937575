import React, { Component } from "react";
import {  FormGenerator } from "@kehillahglobal/ui";
import ApiCallHandler from "../../../shared/js/ApiCallHandler";
import URLS from "../../../shared/js/urls";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toggleToastAction } from "store/actions/actions";
import { getAssetsAction } from '../../../store/actions/actions';

class AssetDocumentCreation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleAddDocument = async (data, reset) => {
    const asset_id = localStorage.assetID;
    let { doc: toEditDoc } = this.props;
    let res;
    const { document: doc, name } = data;
    const toSend = { asset_id, name, file: doc?.data?.file?.data };

    if (toEditDoc) {
      toSend.id = toEditDoc.id;
      if (typeof doc === "string") {
        delete toSend.file;
      } else {
        toSend.file = doc?.data?.file?.data;
      }
      res = await ApiCallHandler.send(URLS.UPDATE_ASSETS_DOCUMENTS,"POST", toSend);
    } else {
      res = await ApiCallHandler.send(
        URLS.CREATE_ASSETS_DOCUMENTS,
        "POST",
        toSend
      );
    }

    if (res && res.success) {
      this.props.toggleToast({
        show: true,
        message: "Asset Documment Added",
        type: "success",
      });
      this.props.reloadAssets()
      this.props.close();
      reset();
    } else {
      this.props.toggleToast({
        showToast: true,
        message: "An error occurred. Try again",
        type: "failure",
      });
    }
  };
  render() {
    let { doc } = this.props;
    const Fields = FormGenerator.Fields
    return (
      <div>
        <div>
          <FormGenerator
            elevation={0}
            fields={[
              {
                fieldType: Fields.INPUT,
                label: "Enter document name",
                name: "name",
                placeholder: "Document name  (eg. license, reciept)",
                required: true,
                value: doc?.name ? doc.name : "",
              },
              {
                fieldType: Fields.FILE,
                label: "Upload Document",
                placeholder: "Select a file",
                name: "document",
                required: true,
                disabled: doc?.id ? true : false,
                value: doc?.file ? doc.file : "",
              },
            ]}
            onSubmit={this.handleAddDocument}
            formSubmitBtnText={`${doc?.name ? "Update " : "Add"} document`}
            subtitle=""
            title=""
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleToast: toggleToastAction,
      reloadAssets:getAssetsAction
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetDocumentCreation);
