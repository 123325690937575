export const DO_NOTHING = "DO_NOTHING";
export const SHOW_SIDE_PANE = "SHOW_SIDE_PANE";

export const GET_ASSET_TYPES = "GET_ASSET_TYPES";
export const GET_ASSETS = "GET_ASSETS";
export const GET_ASSET_EXPENSE_TYPES = "GET_ASSET_EXPENSE_TYPES";
export const GET_ASSET_EXPENSES = "GET_ASSET_EXPENSES";
export const GET_ASSET_INCOME_TYPES = "GET_ASSET_INCOME_TYPES";
export const GET_ASSET_INCOMES = "GET_ASSET_INCOMES";

export const TOGGLE_TOAST = "TOGGLE_TOAST";

export const SET_ASSET_CREATE_EDIT_TYPE = "SET_ASSET_CREATE_EDIT_TYPE";
export const SET_MANAGE_ASSET_TYPES_ID = "SET_MANAGE_ASSET_TYPES_ID";
export const TOGGLE_MODAL = "TOGGLE_MODAL";

export const LOADING_BLANKET = "TOGGLE_LOADING_BLANKET";
export const SET_CURRENT_ENTERPRISE = "SET_CURRENT_ENTERPRISE";

export const LOAD_DEALS = "LOAD_DEALS"; 
export const UPDATE_DEAL = "UPDATE_DEAL"; 
export const DELETE_DEAL = "DELETE_DEAL"; 
export const LOAD_ENTERPRISES = "LOAD_ENTERPRISES"; 
export const LOAD_ENTERPRISE_INVITATIONS = "LOAD_ENTERPRISE_INVITATIONS";
export const LOAD_ENTERPRISE_PARTNERS = "LOAD_ENTERPRISE_PARTNERS";



////////////////////// ASSET CRUD CONSTANTS BEGIN ////////////////////////
export const CREATE_ASSET  = "CREATE_ASSET"
export const EDIT_ASSET    = "EDIT_ASSET"
export const ARCHIVE_ASSET = "ARCHIVE_ASSET"
////////////////////// ASSET CRUD CONSTANTS END //////////////////////////


export const EDIT_SETTER = "EDIT_SETTER"

export const SET_CURRENT_ENTERPRISE_DASHBOARD_DETAILS = "SET_CURRENT_ENTERPRISE_DASHBOARD_DETAILS"
export const TOGGLE_IMAGE_PREVIEW = "TOGGLE_IMAGE_PREVIEW";

export const SET_ALL_ENTERPRISES = "SET_ALL_ENTERPRISES";
export const LOAD_ALL_ENTERPRISES = "LOAD_ALL_ENTERPRISES";
export const LOAD_COUNTRY_ENTERPRISE_REQUIREMENTS = "LOAD_COUNTRY_ENTERPRISE_REQUIREMENTS";
export const LOAD_EXCHANGE_RATES = "LOAD_EXCHANGE_RATES";
export const LOAD_FORM_DATA = "LOAD_FORM_DATA";
export const SET_TYPE_DATA = "SET_TYPE_DATA";
export const GET_DEAL_STATS = "GET_DEAL_STATS";

export const LOAD_ALL_TAGS = "LOAD_ALL_TAGS";
export const LOAD_USER_INFO = "LOAD_USER_INFO";