import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "./EnterprisePartnersList.css";
import { Table, NoItems } from "@kehillahglobal/ui";
import { withRouter } from "react-router-dom";
import {
  capitalizeMe,
  convertNumberToShortForm,
  getCountryInfo,
  getActiveEnterprise,
  isEmpty,
} from "shared/js/utils";
import { PARTNER_TYPES } from "../../../shared/js/typeConstants";
import SearchBar from "pages/deals/SearchBar/SearchBar";

class EnterprisePartnersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      isSearching: false,

    };
  }


  isEnterprisePartner(deal) {
    if (deal?.partner_type === PARTNER_TYPES.ENTERPRISE) {
      return true;
    } else return false;
  }

  computePartnerTotalShares = (data, item) => {
    let totalShares = 0;
    if (data?.length) {
      data
        ?.filter(({ user }) => user?.id === item?.user?.id)
        .forEach(({ shares }) => {
          totalShares += shares;
        });
    }
    return totalShares;
  };

  computePartnerTotalAmount = (data, item) => {
    let totalAmount = 0;
    if (data?.length) {
      data
        ?.filter(({ user }) => user?.id === item?.user?.id)
        .forEach((deal) => {
          let pps = deal?.deal?.price_per_share;
          let shares = deal?.shares;
          totalAmount += pps * shares;
        });
    }
    return totalAmount;
  };

  removeDuplicates = (arr) => {
    return [...new Map(arr.map((item) => [item?.user?.id, item])).values()];
  };

  prepareTableData = (data) => {
    let uniqueData = this.removeDuplicates(data);

    return uniqueData?.map((item, index) => [
      <p>{index + 1}</p>,
      <p onClick={() => this.showDetail(item)}>
        {capitalizeMe(item?.user?.first_name + " ")}{" "}
        {capitalizeMe(item?.user?.last_name || "")}
        {this.isEnterprisePartner(item) && item?.enterprise?.name}
      </p>,
      <p onClick={() => this.showDetail(item)}>
        {this.isEnterprisePartner(item)
          ? item?.enterprise?.email
          : item?.user?.email}
      </p>,
      <p onClick={() => this.showDetail(item)}>
        {data?.filter((partner) => partner?.user?.id === item?.user?.id).length}
      </p>,
      <p onClick={() => this.showDetail(item)}>
        {convertNumberToShortForm(this.computePartnerTotalShares(data, item))}
      </p>,
      <p onClick={() => this.showDetail(item)}>
        {convertNumberToShortForm(this.computePartnerTotalAmount(data, item))}
      </p>,
    ]);
  };

  isEnterprisePartner = (item) => {
    if (item?.partner_type === PARTNER_TYPES.ENTERPRISE) {
      return true;
    } else return false;
  };

  showDetail = (item) => {
    const id = !isEmpty(item?.enterprise?.id)
      ? item?.enterprise?.id
      : item?.user?.id;
    this.props.history.push(`/enterprise/${getActiveEnterprise()?.id}/partners/${id}`);
  };

  updateSearchStatus = (filtered, queryText, searchFilters) =>{
    if(queryText){
       this.setState({ results:filtered, isSearching:true });
    }
    else{
      this.setState({ results:this.props.partnerships, isSearching:false });
    }
  };

  render() {
    const { ent, partnerships } = this.props;
    const { results, isSearching } = this.state;
    let { currency } = getCountryInfo(ent?.currency);

    let hasData = isSearching ? results?.length : partnerships?.length;
    return (
      <div style={{ width: "90%", margin: "auto", marginTop: "1rem" }}>
        <SearchBar
          placeholder="search for partners"
          filters={false}
          data={partnerships?.map((item) => ({
            ...item,
            email: item?.user?.email,
            name: item?.user?.first_name + " " + item?.user?.last_name,
          }))}
          updateSearchStatus={this.updateSearchStatus}
          searchFields={["shares", "name", "email"]}
        />
        <div className="all-investors-list-container elevate-half">
          <div>
            {hasData ? (
              <Table
                columns={[
                  "#",
                  "Name",
                  "Email",
                  "Number of deals",
                  "Totals Shares",
                  `Total Amount (${currency?.symbol})`,
                ]}
                data={this.prepareTableData(
                  isSearching ? results : partnerships
                )}
                hoverAnimation={false}
                rootClass="asset-doc-table"
              />
            ) : (
              <div className="center-me-on-page">
                <NoItems text="No Partners" />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  let ent = state.enterprises?.filter(
    (ent) => ent?.id === getActiveEnterprise()?.id
  );
  return {
    partnerships: state.enterprisePartners,
    ent: ent?.[0],
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EnterprisePartnersList)
);
