/* eslint-disable import/no-anonymous-default-export */
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ApiCallHandler from "shared/js/ApiCallHandler";
import URLS from "shared/js/urls";
import { FormGenerator, NoItems } from "@kehillahglobal/ui";
import Title from "components/title/Title";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentEnterpriseAction,
} from "store/actions/actions";
import "./Success.css";
import {
  capitalizeMe,
  removeCountriesWithEmptyCurrency,
} from "shared/js/utils";
import { toggleModalAction } from "../../store/actions/actions";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { onCountryOrCurrencySearch } from "../../shared/js/utils";
import LoadingPage from "shared/components/loading/LoadingPage";



export default () => {
  const dispatch = useDispatch();
  const all = useSelector((state) => state.enterprises) || [];

  const user = useSelector((state) => state.loggedInUser) || {};
  const invitations = user?.my_enterprise_invitations || [];

  const onSelectEnterprise = (item) => {
    dispatch(setCurrentEnterpriseAction(item));
    window.location.href = `/enterprise/${item?.id}/dashboard`;
  };

  const renderAllEnterprises = (data = []) => {
    if (all?.length) {
      return data?.map((item) => {
        return (
          <div className="all-enterprises-list-container">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 15,
                paddingTop: 15,
              }}
            >
              <img
                src={item?.logo}
                alt="logo"
                className="enterprise-logo-item"
              />
              <h3 style={{ marginLeft: 15 }}>{item?.name}</h3>
            </div>

            <div
              id="goto-dashboard-container"
              onClick={() => {
                onSelectEnterprise(item);
              }}
            >
              <p>Open Enterprise</p>
            </div>
          </div>
        );
      });
    } else {
      return <></>;
    }
  };

  const handleAcceptInvitation = (id, enterprise) => {
    const dataToSend = {
      id,
      enterprise_id: enterprise,
      user_id: user?.id,
    };
    ApiCallHandler.send(
      URLS.ACCEPT_PENDING_ENTERPRISE_USER_INVITATION,
      "POST",
      dataToSend
    ).then((res) => {
      if (res.success) {
        alert("Accepted Successfully");
        window.location.reload();
      }
    });
  };
  const handleDeclineInvitation = (id) => {
    const dataToSend = {
      id,
    };
    ApiCallHandler.send(
      URLS.ARCHIVE_ENTERPRISE_USER_INVITATIONS,
      "POST",
      dataToSend
    ).then((res) => {
      if (res.success) {
        alert("Declined Successfully");
        window.location.reload();
      }
    });
  };

  const renderEnterpriseInvitations = () => {
    if (invitations?.length) {
      return invitations?.map((item) => {
        return (
          <div className="all-enterprises-list-container">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: 15,
              }}
            >
              <img
                src={item?.enterprise?.logo}
                alt="logo"
                className="enterprise-logo-item"
              />
              <h3 style={{ marginLeft: 15 }}>{item?.enterprise?.name}</h3>
            </div>

            <div id="actions">
              <div
                className="elevate-float btn-item accept"
                onClick={() =>
                  handleAcceptInvitation(item.id, item?.enterprise?.id)
                }
              >
                <p>Accept</p>
              </div>
              <div
                className="elevate-float btn-item reject"
                style={{ marginLeft: "10px" }}
                onClick={() => handleDeclineInvitation(item.id)}
              >
                <p>Decline</p>
              </div>
            </div>
          </div>
        );
      });
    }
  };
  const showAllEnterprises = () => {
    return (
      <div>
        <div style={{ borderRadius: 10 }}>
          <div className="mb" style={{ marginBottom: 20 }}>
            <Title
              className="text-toggler"
              onClick={() => toggleAddEnterpriseModal()}
            >
              <FontAwesomeIcon icon={faPlus} /> Add New Enterprise
            </Title>
          </div>
          {all && all.length ? (
            renderAllEnterprises(all)
          ) : (
            <NoItems text="You Have No Enterprises. Click 'Add New Enterprise' to create." />
          )}
        </div>
      </div>
    );
  };

  const showAllPendingEnterpriseInvitations = () => {
    return (
      <div>
        <div style={{ borderRadius: 10, marginLeft: "30px" }}>
          <Title>Pending Invitations</Title>
          {invitations.length ? (
            renderEnterpriseInvitations()
          ) : (
            <NoItems text="No Pending Invitations" />
          )}
        </div>
      </div>
    );
  };

  const renderCreateOrListEnterprise = () => {
    if (all?.length || invitations?.length) {
      return (
        <div className="list-enterprise-wrapper elevate-float">
          <div style={{ maxHeight: "60vh", overflowY: "auto" }}>
            {invitations?.length ? showAllPendingEnterpriseInvitations() : null}
            <br />
            <br />
            {showAllEnterprises()}
          </div>
        </div>
      );
    } else {
      const message = `Hi ${
        capitalizeMe(user?.first_name) || ", there"
      } you do not have any enterprise of your own at the moment.You can fill the form below to create one for yourself`;
      return (
        <div className="create-and-list-enterprise-wrapper elevate-float">
          <center>
            <br />
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{ fontSize: "5rem", color: "green", marginBottom: 30 }}
            />
          </center>
          <br />
          {renderCreateEnterpriseForm(user, dispatch, onSelectEnterprise, message)}
          {invitations?.length ? showAllPendingEnterpriseInvitations() : null}
          <br />
          <br />
        </div>
      );
    }
  };

  const toggleAddEnterpriseModal = () => {
    dispatch(
      toggleModalAction({
        show: true,
        props: {
          title: `Add An Enterprise`,
          children: renderCreateEnterpriseForm(user, dispatch, onSelectEnterprise, ''),
          size: "medium",
          themeColor: "var(--app-theme)",
        },
      })
    );
  };

  return user?.id ? (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width: "100%" }}>{renderCreateOrListEnterprise()}</div>
    </div>
  ): <LoadingPage text={"Preparing For You 🤗"} />;
};
function renderCreateEnterpriseForm(user, dispatch, onSelectEnterprise, message) {
  const Fields = FormGenerator.Fields
  return <FormGenerator
    elevation={0}
    fields={[
      {
        fieldType: Fields.INPUT,
        name: "name",
        label: "Name",
        placeholder: "Eg: Kehillah Ghana Limited",
        required: true
      },
      {
        data: removeCountriesWithEmptyCurrency(),
        fieldType: Fields.DROPDOWN,
        type: "full",
        name: "country",
        label: "Country",
        placeholder: "Select Country",
        required: true,
        labelExtractor: (item) => item.name?.toUpperCase(),
        valueExtractor: (item) => item.name,
        onSearch: (value) => onCountryOrCurrencySearch(value)
      },
      {
        data: removeCountriesWithEmptyCurrency(),
        fieldType: Fields.DROPDOWN,
        type: "full",
        name: "currency",
        label: "Currency",
        placeholder: "Select Currency",
        required: true,
        labelExtractor: (item) => `${item.name?.toUpperCase()} - ${item?.currency?.name?.toUpperCase()} (${item?.currency?.symbol})`,
        onSearch: (value) => onCountryOrCurrencySearch(value)
      },
    ]}
    onSubmit={async (datum) => {
      datum = { ...datum, user_id: user.id };
      datum.country = datum?.country?.name;
      datum.currency = `${datum?.currency?.name}-${datum?.currency?.currency?.name}`;
      const res = await ApiCallHandler.send(
        URLS.CREATE_ENTERPRISES,
        "POST",
        datum
      );
      if (res.success) {
        dispatch(setCurrentEnterpriseAction(res?.data));
        onSelectEnterprise(res?.data);
      }
    } }
    formSubmitBtnText="Create Enterprise"
    subtitle=""
    title={message} />;
}

