export const MOBILE_WALLET_COLORS = [
  "#CD6155",
  "#FF8F00",
  "#6495ED",
  "#B9770E",
  "#5B2C6F",
  "#2874A6",
  "#5F6A6A",
  "#1E8449",
];

export const MOBILE_NETWORKS = {
  GHANA: ["MTN", "AirtelTigo", "Vodafone"],
  USA: ["Verizon", "AT&T", "Sprint", "T-Mobile", "Virgin Mobile", "MetroPCS"],

  NIGERIA: ["MTN", "GLO", "9mobile", "Airtel", "Etisalat"],
  MAURITIUS: ["MTN", "Orange"],
};

export const COUNTRIES = ["GHANA",  "NIGERIA"];