import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import "../SingleDeal.css";
import Title from "components/title/Title";
import { convertNumberToShortForm, getActiveEnterprise, parseJSON } from "shared/js/utils";
import {
  getCountryInfo,
  isEmpty,
} from "../../../../shared/js/utils";
import DealCharts from "./DealCharts";

class SingleDeal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deal:{},
    };
  }

  componentDidMount() {
    const id = this.props?.match?.params?.id;
    if(id){
      const deal = this.props.deals.find(deal => deal.id === id) || {};
      if(deal?.id){
        this.setState({
          deal,
        });
      }
    }
  }

  numFormatter = (num) => {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K";
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + "M";
    } else if (num < 900) {
      return num;
    }
  };

  convertDate = (date) => {
    return new Date(date).toLocaleString("en-us", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };

  getSharesDistribution = (deal) => {
    if (deal?.deals?.length > 0) {
      let totalShares = 0;
      deal?.deals.forEach((deal) => {
        totalShares += deal.shares;
      });
      return {
        total: deal?.shares,
        available: deal?.shares - totalShares,
        bought: totalShares,
      };
    }

    return {
      total: deal?.shares,
      available: deal?.shares,
      bought: 0,
    };
  };

  showStats = (deal) => {
    let shares = this.getSharesDistribution(deal);
    let { currentEnterprise } = this.props;
    let { currency } = getCountryInfo(currentEnterprise?.currency);

    return (
      <div>
        <div className="divider"></div>
        <div className="divider"></div>
        <div id="stats-container">
          <div id="shares-main-wrapper">
            <center id="item-title">Shares</center>
            <div id="shares-container">
              <div>
                <p>{this.numFormatter(shares?.total)}</p>
                <span>Total</span>
              </div>
              <div>
                <p>{this.numFormatter(shares?.bought)}</p>
                <span>Bought</span>
              </div>
              <div>
                <p>{this.numFormatter(shares?.available)}</p>
                <span>Available</span>
              </div>
            </div>
          </div>

          <div id="investors-main-wrapper">
            <center id="item-title">Fees</center>
            <div id="investors-container">
              <div>
                <p>{deal?.carry || 0.0} %</p>
                <span>Carry</span>
              </div>
              <div>
                <p>
                  {currency?.symbol} {deal?.mgmt_fee || 0.0}{" "}
                </p>
                <span>Mgmt. Fee</span>
              </div>
              <div>
                <p>{deal?.admin_fee || 0} %</p>
                <span>Admin fee</span>
              </div>
            </div>
          </div>

          <div id="price-main-wrapper">
            <center id="item-title">Costs</center>
            <div id="price-container">
              <div>
                <p style={{ color: "green" }}>
                  {currency?.symbol} {convertNumberToShortForm(deal?.setup_cost)}
                </p>
                <span>Setup Cost</span>
              </div>
              <div>
                <p style={{ color: "green" }}>
                  {currency?.symbol} {deal.price_per_share}
                </p>
                <span>Price Per Share</span>
              </div>
            </div>
          </div>
        </div>
        <div className="divider" ></div>
        <div className="divider" ></div>

      </div>
    );
  };

  showDealInfo = (deal) => {
    let title = "about deal".toUpperCase();
    return (
      <div id="other-info-wrapper">
        <div id="about-deal">
          <Title>{title}</Title>
          <div
            className="about-deal-container"
            dangerouslySetInnerHTML={{
              __html: isEmpty(deal?.about)
                ? "No description added"
                : deal?.about,
            }}
          ></div>
        </div>
      </div>
    );
  };

  showTags = (deal) => {
    let tags = parseJSON(isEmpty(deal?.tags) ? [] : deal?.tags);
    return (
      <div style={{ marginTop: "2rem" }}>
        {!isEmpty(tags) && <Title>Tags</Title>}
        {tags?.length > 0 && (
          <div className="tag-show-wrapper">
            {tags?.map((tag) => {
              return (
                <div className="elevate-float deal-tag-item">
                  <p>{tag}</p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  showDealCharts  = (deal) => {
    return (
      <div style={{marginTop:'3rem', marginBottom:'3rem'}}>
        <DealCharts deal={deal} />
      </div>
    );
  }



  render() {
    const { deal } = this.state;
    return (
      <div id="single-inv-wrapper">
        {this.showStats(deal)}
        {this.showDealCharts(deal)}
        {this.showDealInfo(deal)}
        {this.showTags(deal)}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let currentEnterprise = state.enterprises?.find(
    (item) => item?.id === getActiveEnterprise()?.id
  );
  return {
    deals: state.deals,
    currentEnterprise,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SingleDeal)
);
