import React, { Component } from "react";
import "./dashboard.css";
import Title from "components/title/Title";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  convertAmountWithCurrencyRate,
  convertNumberToShortForm,
  getActiveEnterprise,
} from "shared/js/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faMoneyBillAlt,
  faUsers,
  faHandshake,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import Verified from "../../shared/components/verified/Verified";
import { getCountryInfo, capitalizeMe, isEmpty } from "../../shared/js/utils";
import { VERIFICATION_STATUSES } from "shared/js/typeConstants";
import { PARTNER_TYPES } from '../../shared/js/typeConstants';
import COLORS from '../../shared/js/colors';
import {Table, NoItems} from '@kehillahglobal/ui'

class Dashboard extends Component {

  isEnterprisePartner(deal) {
    if (deal?.partner_type === PARTNER_TYPES.ENTERPRISE) {
      return true;
    } else return false;
  }
  prepareTableData = (data) => {
    if (data?.length > 0) {
      
      return data
        ?.map((item) => {
          return [
            <p>
              {this.isEnterprisePartner(item)
                ? item?.enterprise?.name
                : item?.user?.first_name}
            </p>,
            <p>{item?.deal?.name}</p>,
            <p>
              {this.isEnterprisePartner(item)
                ? item?.enterprise?.email
                : item?.user?.email}
            </p>,
            <p>{item?.shares}</p>,
            <p>{(item?.shares/item?.deal?.shares*100)?.toFixed(1)} %</p>,
          ];
        });
    }
    return [];
  };


  showNewCard = () => {
    let {stats, currentEnterprise}  = this.props

    return (
      <div id="figures-main-container">
        <div
          id="figure-container"
          className="elevate-float card-me"
          style={{ backgroundColor: COLORS.DEAL_PRIMARY }}
          onClick={() =>
            this.props.history.push(
              `/enterprise/${currentEnterprise?.id}/deals`
            )
          }
        >
          <div id="avatar" style={{ backgroundColor: COLORS.DEAL_SECONDARY }}>
            <FontAwesomeIcon
              icon={faHandshake}
              style={{ fontSize: 30, color: "white" }}
            />
          </div>
          <div id="figures-details">
            <span>Deals</span>
            <span>{convertNumberToShortForm(stats?.total_deals || 0)}</span>
          </div>
        </div>
        <div
          id="figure-container"
          className="elevate-float card-me"
          style={{ backgroundColor: COLORS.PARTNERS_PRIMARY }}
          onClick={() =>
            this.props.history.push(
              `/enterprise/${currentEnterprise?.id}/partners`
            )
          }
        >
          <div
            id="avatar"
            style={{ backgroundColor: COLORS.PARTNERS_SECONDARY }}
          >
            <FontAwesomeIcon
              icon={faUsers}
              style={{ fontSize: 30, color: "white" }}
            />
          </div>
          <div id="figures-details">
            <span>Partners</span>
            <span>{convertNumberToShortForm(stats?.total_partners)}</span>
          </div>
        </div>
        <div
          id="figure-container"
          className="elevate-float card-me"
          style={{
            backgroundColor: `${
              stats?.total_income < 0
                ? COLORS.DANGER_PRIMARY
                : COLORS.INCOME_PRIMARY
            }`,
          }}
        >
          <div
            id="avatar"
            style={{
              backgroundColor: `${
                stats?.total_income < 0
                  ? COLORS.DANGER_SECONDARY
                  : COLORS.INCOME_SECONDARY
              }`,
            }}
          >
            <FontAwesomeIcon
              icon={faMoneyBillAlt}
              style={{ fontSize: 30, color: "white" }}
            />
          </div>
          <div id="figures-details">
            <span>Total Income</span>
            <span>
              {convertNumberToShortForm(stats?.total_income?.toFixed(2)) ||
                parseFloat(0.0)?.toFixed(2)}
            </span>
          </div>
        </div>
      </div>
    );
  };

  showVerificationNotification = () => {
    let { currentEnterprise } = this.props;
    if (
      currentEnterprise?.compliance_documents?.status ===
      VERIFICATION_STATUSES.VERIFIED || currentEnterprise?.is_verified
    ) {
      return null;
    } else {
      return (
        <div className="elevate-float set-payment-pref-container dashboard-payment-card">
          <div style={{ display: "flex" }}>
            <div id="info-icon-container">
              <FontAwesomeIcon icon={faInfoCircle} />
            </div>

            <div id="notification-body-container">
              <span>Enterprise Verification</span>
              {isEmpty(currentEnterprise?.compliance_documents) ? (
                <p>
                  Dear Admin, your enterprise has not being verified and hence
                  investor will no be able to see your deals. Please{" "}
                  <Link to={`/enterprise/${currentEnterprise?.id}/verification`}> click</Link> to to start
                  the verification process.{" "}
                </p>
              ) : currentEnterprise?.compliance_documents?.status ===
                VERIFICATION_STATUSES.REJECTED ? (
                <p>
                  Dear Admin, your enterprise verification has been rejected.
                  Here is message from our verification team: <br />
                  {
                    currentEnterprise?.compliance_documents?.failure_message
                  }{" "}
                  <br />
                  <br />
                  Please <Link to={`/enterprise/${currentEnterprise?.id}/verification`}> click</Link> to
                  update your verification information. <br />
                  Thank you.
                </p>
              ) : (
                <p>
                  Keep calm, we are verifying your enterprise. Once the process
                  is complete we will notify you. If you wish to edit your
                  verification info,
                  <Link to={`/enterprise/${currentEnterprise?.id}/verification`}> Click here</Link> .{" "}
                  <br />
                  Thank you.
                </p>
              )}
            </div>
          </div>
        </div>
      );
    }
  };

  
  convertMoneyWithCurrency = (amount) => {
    let data = {
      amount: amount,
      code: this.props.pref?.currency || "GHS",
      rates: this.props.exchangeRates,
    };
    let converted = convertAmountWithCurrencyRate(data);
    return converted;
  };




  getTop10Partners = () => {
    let partner = []
    let { deals } = this.props;
    if (!isEmpty(deals)) {
      // eslint-disable-next-line
      deals?.map((deal) => {
        if (!isEmpty(deal?.partnerships)) {
          let prt = deal?.partnerships?.sort(
            (a, b) => a?.shares - b?.shares
          );
          partner.push(...prt);
        }
      });
    }

    return partner?.sort((a, b) => b?.shares - a?.shares).slice(0, 10);
  }

  // =================== END OF CALCULATIONS ==========================

  render() {
    let { currentEnterprise, stats, partners } = this.props;
    let country = getCountryInfo(currentEnterprise?.currency);
    return (
      <>
        <div>
          <div className="title-area">
            <h1 id="greeting-msg-h1">
              <p>
                Welcome, {capitalizeMe(currentEnterprise?.name) || ""}
                {currentEnterprise?.is_verified ? (
                  <Verified bg={"green"} height={"21px"} width={"21px"} />
                ) : null}
              </p>
            </h1>

            <div className="everyday-flex right-of-title-box dashboard-stats-container">
              <h6>Total Revenue</h6>
              <h1>
                {country?.currency?.symbol}{" "}
                {convertNumberToShortForm(stats?.total_revenue?.toFixed(2)) ||
                  parseFloat(0.0)?.toFixed(2)}
                <span>{country?.currency?.code} </span>
              </h1>

              <h6 style={{ color: "maroon" }}>Expenses</h6>
              <h2 style={{ color: "maroon" }}>
                {country?.currency?.symbol}{" "}
                {convertNumberToShortForm(stats?.total_expenses?.toFixed(2)) ||
                  parseFloat(0.0)?.toFixed(2)}
                <span>{country?.currency?.code} </span>
              </h2>
            </div>
          </div>

          {/* ----------------- CARDS AREA ------------- */}
          {this.showVerificationNotification()}
          {this.showNewCard()}

          <div
            className="dashboard-team-box elevate-float"
            style={{ padding: 40, borderRadius: 10 }}
          >
            <Title>Top Partners</Title>
            {isEmpty(
              partners?.length
            ) ? (
              <div>
                <NoItems text="You Do Not Have Partners or Deals " />
              </div>
            ) : (
              <Table
                columns={[
                  "Name",
                  "Deal",
                  "Partner Email",
                  "Shares",
                  "Percentage",
                ]}
                data={this.prepareTableData(this.getTop10Partners())}
                theme={Table.CHECKERED}
                hoverAnimation={false}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const allEnterprises = state.enterprises;
  const enterpriseLoc =getActiveEnterprise();

  const currentEnterprise = allEnterprises?.find(
    (item) => item.id === enterpriseLoc?.id
  );
  return {
    currentEnterprise,
    deals: state.deals,
    pref: currentEnterprise?.preferences,
    exchangeRates: state.exchangeRates,
    stats: state.enterpriseDashboardContents,
    partners: state.enterprisePartners,
  };
};


export default withRouter(
  connect(mapStateToProps, null)(Dashboard)
);
