import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import {TabView} from "@kehillahglobal/ui";
import ListLiveDeals from "./live-deals-list/ListLiveDeals";
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Title from '../../components/title/Title';
import CompletedDealList from './completed-deals-list/CompletedDealList';
import SearchBar from "./SearchBar/SearchBar";
import SearchResults from "./SearchBar/SearchResults";
import ListDraftedDeals from "./drafted-partnerships-list/ListDraftedDeals";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class DealsWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changer: "live-deals",
      results: [],
      isSearching: false,
      key: null,
    };
  }

  updateSearchStatus = (result, text, filter) => {
    if (result?.length > 0) {
      this.setState({ isSearching: true, results: result });
    } else if (filter?.length > 0) {
      this.setState({ isSearching: true, results: [] });
    } else if (text) {
      this.setState({ isSearching: true, results: [] });
    } else {
      this.setState({ results: [], changer: "live-deals" }, () =>
        this.setState({ isSearching: false })
      );
    }
  };

  TAB_DATA = [
    {
      name: "Live ⚡️ Deals",
      id: "live-deals",
      component: (
        <ListLiveDeals
          switchTabs={(changer) => {
            this.state.changer(changer);
          }}
        />
      ),
    },
    {
      name: "Drafted Deals",
      id: "draft-deals",
      component: (
        <ListDraftedDeals
          switchTabs={(changer) => {
            this.state.changer(changer);
          }}
        />
      ),
    },
    {
      name: "Completed Deals",
      id: "completed-deals",
      component: (
        <CompletedDealList
          switchTabs={(changer) => {
            this.state.changer(changer);
          }}
        />
      ),
    },
  ];
  handleToggleAddOrEditModal = async (item = {}) => {
    let entID = this.props?.match?.params?.enterprise
    this.props.history.push(`/enterprise/${entID}/deal/create`);
  };

  render() {
    return (
      <div style={{ paddingTop: 10 }} key={this.state.key}>
        <div style={{ width: "90%", margin: "auto" }}>
          <div>
            <SearchBar
              updateSearchStatus={this.updateSearchStatus}
              withFilters={true}
              data={this.props.deals}
              searchFields={["name", "status", "price_per_share", "shares"]}
              filters={this.props.tags}
            />
          </div>
          {this.state.isSearching ? (
            <SearchResults results={this.state.results} />
          ) : (
            <>
              <div>
                <Title
                  className="text-toggler"
                  onClick={() => this.handleToggleAddOrEditModal()}
                >
                  <FontAwesomeIcon icon={faPlus} /> Add New Deal
                </Title>
              </div>
              <TabView
                data={this.TAB_DATA}
                contentAreaStyle={{ paddingTop: 30 }}
                defaultTab={this.state.changer}
                onMount={(changer) => this.setState({ changer })}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}
 const mapStateToProps = (state) => {
   return {
     tags: state.tags,
     deals: state.deals,
   };
 }

export default withRouter(connect(
  mapStateToProps,
  null,
)(DealsWrapper))