import React from "react";
import "./prefs.css";
import { useSelector } from "react-redux";
import { getCountryInfo } from '../../../shared/js/utils';
import { getActiveEnterprise } from 'shared/js/utils';

export default function EnterprisePreferences() {

  const enterprises =
    useSelector((state) =>
      state.enterprises) || [];

  const enterprise  =enterprises?.find((item) => item.id ===getActiveEnterprise()?.id) || {};

  const {currency} = getCountryInfo(enterprise?.currency);
  const country= getCountryInfo(enterprise?.country);
  return (
    <div>
      <div className=" elevate-float country-and-currency">
        <div className="currency-container">
          <p className="pref-title">Country</p>
          <p>{country?.name}</p>
          <p>{country?.isoAlpha3}</p>
          <p>{}</p>
        </div>
        <div className="currency-container">
          <p className="pref-title">Currency</p>
          <p>{currency?.code}</p>
          <p>{currency?.symbol}</p>
          <p>{currency?.name}</p>
        </div>
      </div>
    </div>
  );
}
