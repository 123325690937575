/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import {
  setEditSetterAction,
  toggleModalAction,
  archiveMutationAction,
} from "store/actions/actions";
import "./listings.css";
import {NoItems} from "@kehillahglobal/ui";
import ApiCallHandler from "../../../shared/js/ApiCallHandler";
import URLS from "../../../shared/js/urls";
import { DEAL_STATUS } from "shared/js/typeConstants";
import { toggleToastAction } from "../../../store/actions/actions";
import DealsCard from "../deals-card/DealsCard";

class ListDraftedDeals extends Component {
  handleDeleteRecord = (item) => {
    let { deals } = this.props;
    if (deals?.length < 1) return;

    this.props.toggleModal({
      show: true,
      props: {
        title: `Delete "${item?.name}"`,
        children: (
          <p style={{ padding: "10px 15px" }}>
            Are you sure you want to delete {item?.name}
          </p>
        ),
        themeColor: "maroon",
        cancel: true,
        okay: {
          text: "Yes",
          function: () => {
            ApiCallHandler.send(URLS.ARCHIVE_DEALS, "POST", {
              id: item?.id,
            }).then((res) => {
              if (res?.success) {
                this.props.archiveRecord(deals, item?.id);
              } else {
                this.props.toggleToast({
                  show: true,
                  message: res?.error,
                  type: "failure",
                });
              }
            });
          },
        },
      },
    });
  };

  handleViewDetails = (item) => {
    this.props.setEditPayload({
      action: "VIEW_SINGLE_DEAL",
      payload: item,
    });
    let enterpriseID = this.props?.match?.params?.enterprise;
    this.props.history.push(
      `/enterprise/${enterpriseID}/deals/drafted/` + item.id
    );
  };

  handleToggleAddOrEditModal = async (item = {}) => {
     let enterpriseID = this.props?.match?.params?.enterprise;
    this.props.history.push(
      `/enterprise/${enterpriseID}/deal/${item?.id}/edit`
    );
  };

  closeModal = () => {
    this.props.toggleModal({ show: false });
  };
  render() {
    let { deals } = this.props;
    return (
      <div className="">
        <div className="user-deal-items-wrapper">
          {deals?.length > 0 ? (
            deals.map((deal, index) => {
              return (
                <DealsCard
                  key={index.toString()}
                  deal={deal}
                  handleViewDetails={this.handleViewDetails}
                  handleEdit={this.handleToggleAddOrEditModal}
                  handleDeleteRecord={this.handleDeleteRecord}
                  close={this.closeModal}
                />
              );
            })
          ) : (
            <div id="no-deals-container">
              <NoItems
                text={`You do not have any drafted deal. Click "Add New Deal" to create one.`}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    deals: state?.deals?.filter((item) => item.status === DEAL_STATUS.DRAFT),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setEditPayload: (val) => dispatch(setEditSetterAction(val)),
      toggleModal: toggleModalAction,
      toggleToast: toggleToastAction,
      archiveRecord: (current_data, data_to_delete_id) =>
        dispatch(
          archiveMutationAction("DEAL", current_data, data_to_delete_id)
        ),
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ListDraftedDeals)
);
