import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "./DistributionsDetail.css";
import Title from "components/title/Title";
import { Table, NoItems } from "@kehillahglobal/ui";
import {
  capitalizeMe,
  convertNumberToShortForm,
  getFileType,
  parseJSON,
} from "shared/js/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import {
  ACCOUNT_TYPES,
  IMAGE_TYPES,
  PARTNER_TYPES,
} from "../../../../../shared/js/typeConstants";
import { toggleImagePreviewAction } from "store/actions/actions";
import { getCountryInfo } from "../../../../../shared/js/utils";
import Processing from "shared/components/processing/Processing";

class SingleDistributionView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      distribution: {},
      prefs:[]
    };
  }

  componentDidMount() {
    let { dealID, payoutID, distributionID } = this.props?.match?.params;
      let deal = this.props.deals.find((deal) => deal.id === dealID);
      let payout = deal?.payouts.find((payout) => payout.id === payoutID);
      let distribution = payout?.distributions.find(item => item.id === distributionID);

      this.setState({
        distribution,
        prefs: distribution?.partner?.user?.payment_election,
      });
  }

  static getDerivedStateFromProps(props) {
    let { dealID, payoutID, distributionID } = props?.match?.params;
    if (dealID) {
      let deal = props.deals.find((deal) => deal.id === dealID);
      let payout = deal?.payouts.find((payout) => payout.id === payoutID);
      let distribution = payout?.distributions.find(
        (item) => item.id === distributionID
      );
      return { distribution, prefs:distribution?.partner?.user?.payment_election, };
    }
    return;
  }

  getRemended = () => {
    let { distribution } = this.state;
    let remended = distribution?.full_amount - distribution?.amount_to_receive;
    return remended.toFixed(2);
  };

  openImage = (image) => {
    window.open(image, "blank");
  };

  showCommentFile = (file) => {
    const fileType = getFileType(file);

    if (IMAGE_TYPES.includes(fileType))
      return (
        <img
          src={file}
          alt="  "
          onClick={() =>
            this.props.showImagePreview({ show: true, image: file })
          }
        />
      );

    return <FontAwesomeIcon icon={faFileAlt} fontSize="3rem" color="maroon" />;
  };

  showComment = (comment) => {
    return (
      <div className="comment-item-container " style={{ position: "relative" }}>
        <div style={{ display: "flex" }}>
          <div id="commenter-img-container">
            <img
              src={comment?.sender?.image || "https://i.pravatar.cc/300"}
              alt="  "
            />
          </div>
          <div id="commenter-info">
            <h4>{comment?.sender?.first_name}</h4>
            <small>4d</small>
          </div>
        </div>
        <div id="comment-body">
          <p>{comment?.body || ""}</p>
          <div id="comment-file-container">
            {comment?.attachment
              ? this.showCommentFile(comment?.attachment)
              : null}
          </div>
        </div>
      </div>
    );
  };

  calculateAmountFromPercentage = (percentage, amount) => {
    let _amount = (percentage / 100) * amount;
    return convertNumberToShortForm(_amount.toFixed(2));
  };
  getPercentage = (pref) => {
    let distribution = parseJSON(pref?.distribution);
    let total = 0;
    let dist;

    if (distribution?.distribution === "PERCENTAGE")
      return distribution?.percentage;

    this.state.prefs?.forEach((election) => {
      dist = parseJSON(election.distribution);
      if (dist.distribution === "PERCENTAGE") {
        total += Number(dist.percentage);
      }
    });

    return (100 - total).toFixed(2);
  };

  prepareTableData = () => {
    let { distribution, prefs } = this.state;
    let tableData = prefs?.map((item) => {
      let { currency } = getCountryInfo(item?.account?.currency);
      return [
        <p>{item?.payment_type}</p>,
        <p>{item?.account?.country}</p>,
        <p>{currency?.code}</p>,
        <p>{item?.account_type}</p>,
        <p>
          {item?.account_type === ACCOUNT_TYPES.MOBILE
            ? item?.account?.registered_name
            : item?.account?.bank_name}
        </p>,
        <p>
          *****{" "}
          {item?.account_type === ACCOUNT_TYPES.MOBILE
            ? item?.account?.phone_number?.substring(5, 10)
            : item?.account?.account_number?.substring(10, 14)}
        </p>,
        <p>{this.getPercentage(item)} %</p>,
        <p className="i-am-money">
          {this.calculateAmountFromPercentage(
            this.getPercentage(item),
            distribution?.amount_to_receive
          )}
        </p>,
      ];
    });
    return tableData;
  };
  render() {
    let { currentEnterprise } = this.props;
    let { distribution, prefs } = this.state;
    let country = getCountryInfo(currentEnterprise?.currency);

    return distribution?.id ?(
      <>
        <div className="distribution-detail-root">
          <div className="top-card">
            <div id="report-card-logo-and-name-container">
              <div id="distribution-company-det">
                <h2>
                  {capitalizeMe(
                    distribution?.partner?.partner_type !==
                      PARTNER_TYPES.ENTERPRISE
                      ? distribution?.partner?.user?.first_name
                      : distribution?.partner?.enterprise?.name
                  )}
                </h2>
                <br />
                <p>{distribution?.deal?.name} </p>
              </div>
            </div>

            <div className="total-received-container">
              <p className="total-received-title">To Receive</p>

              <div className="received-money-container">
                <p>{country?.currency?.symbol}</p>
                <h2>
                  {convertNumberToShortForm(
                    distribution?.amount_to_receive?.toFixed(2)
                  )}
                </h2>
              </div>
            </div>

            <div>
              <p className="total-received-title pending">Remaining</p>

              <div className="received-money-container">
                <p>{country?.currency?.symbol}</p>
                <h2>{convertNumberToShortForm(this.getRemended()) || 0.0}</h2>
              </div>
            </div>
            <div>
              <p className="total-received-title">Charges</p>

              <div className="received-money-container">
                <p>{country?.currency?.symbol}</p>
                <h2 style={{ color: "#EC7063" }}>
                  {convertNumberToShortForm(
                    distribution?.transaction_charges?.toFixed(2)
                  ) || 0.0}
                </h2>
              </div>
            </div>
          </div>

          <div className="payment-distribution-container">
            <Title>Distribution</Title>
            {!prefs ? (
              <NoItems text="Please check add a payment Preference" />
            ) : (
              <>
                <Table
                  columns={[
                    "Payment Type",
                    "Country",
                    "Currency",
                    "Account Type",
                    "Account",
                    "Account Number",
                    "percentage",
                    `Amount (${country?.currency?.symbol})`,
                  ]}
                  data={this.prepareTableData()}
                  hoverAnimation={false}
                  rootClass="pref-table"
                />
              </>
            )}
          </div>

          <div className="distribution-comments-container">
            <div className="comments-list-container">
              <Title className="comments-header"> distribution Updates </Title>
              <div className="divider"></div>
              {!distribution?.comments ? (
                <NoItems text="No comments yet" />
              ) : (
                distribution?.comments?.map((comment) =>
                  this.showComment(comment)
                )
              )}
            </div>
          </div>
        </div>
      </>
    ):(
      <Processing text={"fetching data"} />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    deals: state?.deals,
    currentEnterprise:state?.currentEnterprise,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      showImagePreview: toggleImagePreviewAction,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleDistributionView);
